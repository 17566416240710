import verticalConstants from 'components/verticals/verticalConstants';
import VerticalPhoneNumber from 'components/verticals/verticalPhoneNumbers';
import useProductRecommendations from 'hooks/useProductRecommendations';
import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductRecommendations from '../catalog/recommendations/productRecommendations';
import './notFound.scss';

const NotFound: FunctionComponent = ({ ...rest }) => {
    // const { certonaPageId, recommendations } = useProductRecommendations({
    //     recommendationLists: [
    //         'errornew_rr', 'errorbestsellers_rr',
    //     ],
    // });
    // const { errornew_rr, errorbestsellers_rr } = recommendations;

    return (
        <div id="server-in-not-found-state">
            <meta name="prerender-status-code" content="404"></meta>
            <section>
                <div className="oops-image">
                    <img 
                        width="480.69px" height="250px"
                        src={`/media/${verticalConstants.verticalSuppliesType.lowerCase}/common/backgrounds/Oops-Image.png`}
                        srcSet={`/media/${verticalConstants.verticalSuppliesType.lowerCase}/common/backgrounds/Oops-Image.png,
                                 /media/${verticalConstants.verticalSuppliesType.lowerCase}/common/backgrounds/Oops-Image@2x.png 2x`}
                    />
                </div>
                <h1 className="">
                    We&#39;re sorry, we can&#39;t find the page you&#39;re looking for.
                </h1>
                <p className="">
                    It&#39;s possible this page has moved to a different location. If the problem persists or you can&#39;t find what you&#39;re looking for please contact {verticalConstants.verticalName.firstCharacterUpperCase} at <strong><VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" /></strong> or email <a href={`mailto:support@${verticalConstants.verticalName.lowerCase}.com`}>support@{verticalConstants.verticalName.lowerCase}.com</a>.
                </p>
                <Button variant="secondary" href="/" className="take-me-home btn-pillbox" size="lg">Take me home</Button>
            </section>
            <aside>

                {/* {errornew_rr &&
                    <ProductRecommendations
                        identifier="errornew_rr"
                        title={errornew_rr.title}
                        pageId={certonaPageId}
                        productRecommendations={errornew_rr.recommendations}
                    />
                }
                {errorbestsellers_rr &&
                    <ProductRecommendations
                        identifier="errorbestsellers_rr"
                        title={errorbestsellers_rr.title}
                        pageId={certonaPageId}
                        productRecommendations={errorbestsellers_rr.recommendations}
                    />
                }
                <div className="line" /> */}

            </aside>
        </div>
    )
}

export default NotFound;