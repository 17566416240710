import algoliasearch from "algoliasearch/lite";
import verticalConstants from "components/verticals/verticalConstants";

export const options = [
  { label: "Best Sellers", value: `${verticalConstants.verticalName.lowerCase}_products` },
  { label: "Lowest Price", value: `${verticalConstants.verticalName.lowerCase}_lowest_price` },
  { label: "Highest Rated", value: `${verticalConstants.verticalName.lowerCase}_highest_rated` },
  { label: "Our Picks", value: `${verticalConstants.verticalName.lowerCase}_our_picks` },
  { label: "Percent Savings", value: `${verticalConstants.verticalName.lowerCase}_percent_saving` },
];

export const defaultIndex = `${verticalConstants.verticalName.lowerCase}_products`;
export const querySuggestionsIndex = `${verticalConstants.verticalName.lowerCase}_products_query_suggestions`;

export const searchClient = algoliasearch(
  window.n32?.devMode === true ? 'testing0FOOPD67IZ' : 'FLM6G9MOQQ',
  window.n32?.devMode === true ? '24b93fff317fa39f5c3d781aa070ac24' : '74e7fd62b20321f9a41c36aa355d6f86',
);
