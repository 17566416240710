import Big from "big.js";
import { AnalyticsReportProductV2 } from "./analyticsEventSender";

export default function computeSubtotalOfAnalyticsProduct(products: AnalyticsReportProductV2[]) {
    return products
        .map((p) => new Big(p.price ? p.price : 0).times(p.quantity))
        .reduce(
            (accumulator, currentValue) => accumulator.plus(currentValue),
            new Big(0)
        );
}