
import { AppThunk } from "store/reducer/ec/rootReducer";
import { OrderHistoryState } from "store/reducer/ec/orderHistoryReducer";
import axios from "axios";
import { StatusCodes } from "http-status-codes";

// ====================================================================================================
// Action Types
export const ORDER_HISTORY_ADD_ORDERS_TO_STORE = 'orderHistory:AddOrders';
export const ORDER_HISTORY_SUMMARY_ADD_TO_STORE = 'orderHistory:OrderSummary';
export const SET_BUY_IT_AGAIN_SUGGESTIONS = "orderHistory:Get Buy It Again Suggestions"

export interface OrderHistoryAddOrdersAction {
    type: typeof ORDER_HISTORY_ADD_ORDERS_TO_STORE,
    payload: OrderHistoryState
}

export interface OrderHistorySummaryToAction {
    type: typeof ORDER_HISTORY_SUMMARY_ADD_TO_STORE,
    payload: OrderHistoryState
}
export interface SetBuyItAgainSuggestions {
    type: typeof SET_BUY_IT_AGAIN_SUGGESTIONS,
    payload: BuyItAgainSuggestions[]
}

export type OrderHistoryActions = OrderHistoryAddOrdersAction | OrderHistorySummaryToAction | SetBuyItAgainSuggestions;
// ====================================================================================================
// Actual Actions
export const getOrderHistoryPage = (startNumber: number, lastNumber: number, isComplete: boolean): AppThunk => async dispatch => {
    console.log("Getting Order History");


     const response = await fetch("/rest/order/orderHistory?paymentSystemId=1&startPoint="+ startNumber+"&endPoint=" + lastNumber +"&pendingSw=true&completeSw="+isComplete, {

        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        redirect: "follow", // manual, *follow, error
    });

    if (response.ok === true) {

        const payload =  await response.json() as WindfallRestfulResponse.ResponsePacket<OrderHistoryState>;
        
        if (payload.CallHeader.StatusCode === "SC_ERROR_INVALID_USER") {
            console.log("User not logged in.  Redirecting.")
            window.location.href = '/login?origin=' + encodeURIComponent(window.location.pathname);
        }
        else {
            dispatch(orderHistoryAddOrdersToStoreAction(payload.Payload));
        }

        /**if (payload.CurrentUser) {
            dispatch(
                updateUser(payload.CurrentUser)
            );
        }
        if (payload.TopCategories) {
            dispatch(
                updateTopCategories(payload.TopCategories)
            );
        }
        if (payload.ShoppingCartSummary) {
            dispatch(
                modifyShoppingCartAction(payload.ShoppingCartSummary)
            )
        }**/
    }
    else {
        console.error("getOrderHistoryPage has failed");
    } 
}

export const getOrderHistorySummary = (): AppThunk => async dispatch => {
    console.log("Getting Order History");


     const response = await fetch("/rest/order/orderSummary", {

        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        redirect: "follow", // manual, *follow, error
    })
    if (response.ok === true) {

        const payload =  await response.json() as WindfallRestfulResponse.ResponsePacket<OrderHistoryState>;
        dispatch(orderHistorySummaryAddTostoreAction(payload.Payload))
    }
    else {
        console.error("getOrderHistorySummary has failed");
    } 
}

export function orderHistorySummaryAddTostoreAction(Payload: any): OrderHistorySummaryToAction {
    return {
        type: ORDER_HISTORY_SUMMARY_ADD_TO_STORE,
        payload: Payload
    }
}

export function orderHistoryAddOrdersToStoreAction(Payload: any): OrderHistoryAddOrdersAction{
    return {
        type: ORDER_HISTORY_ADD_ORDERS_TO_STORE,
        payload: Payload
    }
}


export function setBuyItAgainSuggestions(suggestions: BuyItAgainSuggestions[]): SetBuyItAgainSuggestions{
    return {
        type: SET_BUY_IT_AGAIN_SUGGESTIONS,
        payload: suggestions
    }
}


/**
 * Retrieves the last products ordered by the user that are still active from the backend
 * for use in the Buy It Again Suggestions modal attached to the search bar in the header.
 */
export const getBuyItAgainSuggestions =  (): AppThunk => async dispatch => {
	try {
		const { status, data } = await axios.get<BuyItAgainSuggestions[]>("/rest/neo/order/user-last-active-products");
		
        if (status === StatusCodes.OK) {
            if (data.length > 0) {
                dispatch(setBuyItAgainSuggestions(data));
            }
        }
        else {
            console.warn("/rest/neo/order/user-last-active-products returned a \"" + status + "\".", data);
        }
    } 
    catch (error) {
		console.error(error);
        dispatch(setBuyItAgainSuggestions([]));
	}
}

export type BuyItAgainSuggestions = {
    mpId: number;
    title: string;
    url: string;
    mediaPath: string;
    smallPhotoPath: string;
    bestPrice: number;
    retailPrice: number;
    coTime: Date;
}