import { UserState } from "store/reducer/userReducer";
import { AppThunk } from "store/reducer/ec/rootReducer";
import { updateUser } from "store/actions/userActions";
import { updateTopCategories } from "store/actions/ec/categoryActions";
import { USER_LOGOUT } from "store/actions/userActions";
import { Action } from "redux";
import axios, { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import { setServerAsInErrorState, setServerAsOffline, setServerAsOnline } from "../commonAppActions";
import { getShoppingCart } from "./shoppingCartActions";
import verticalConstants from "components/verticals/verticalConstants";
import Cookies from "js-cookie";

export const APPLICATION_INIT = "app:INIT"
export const APPLICATION_RESET = "app:RESET"
export const APPLICATION_ADD_CSS_FILE = "app:Add CSS file";

export interface AddCSSFileAction extends Action {
    type: typeof APPLICATION_ADD_CSS_FILE,
    payload: string
}

/** Adds a CSS file to the list loaded onto the page.  This is for when a CSS file needs to be loaded on a public page that will be consumed by google. */
export function addCSSFile(cssFile: string): AddCSSFileAction {
    return {
        type: APPLICATION_ADD_CSS_FILE,
        payload: cssFile
    }
}


type BasicApplicationStateOptions = {
    updateShoppingCartSummary: boolean,
    updateTopCategories: boolean,
    updateCurrentUser: boolean,
}

export const getBasicApplicationState = (): AppThunk => async dispatch => {
    try {
        const { data } = await axios.get<WindfallRestfulResponse.ResponsePacket>("/rest/user");
        
        dispatch(setServerAsOnline());
        dispatch(
            updateUser(data.CurrentUser ? data.CurrentUser : { IsGuestUser: true }, data.IsAuthenticated)
        );
        if (data.TopCategories && verticalConstants.verticalName.lowerCase === "net32") {
            dispatch(
                updateTopCategories(data.TopCategories)
            );
        }
        else {
            // THe base call is glitched for Med32 and so we need to get them no matter what for Med32.
            const { data: topCategoryData } = await axios.get<WindfallRestfulResponse.ResponsePacket<WindfallRestfulResponse.ResponsePieces.TopCategory[]>>("/rest/category/top-categories");
            if (topCategoryData.Payload) {
                dispatch(
                    updateTopCategories(topCategoryData.Payload)
                );
            }
        }
    } catch (error) {
        if (error.isAxiosError) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                switch (axiosError.response.status) {
                    case StatusCodes.SERVICE_UNAVAILABLE:
                    case StatusCodes.NOT_FOUND:
                        dispatch(setServerAsOffline())
                        break;
                    default:
                        console.error("/rest/userAndCartSummary/get has failed with an unexpected status code: ", axiosError.response.status);
                        dispatch(setServerAsInErrorState());
                        break;
                }
            }
        }
        console.error(error);
    }
}

export const logoutUser = (): AppThunk => async dispatch => {
    console.log("Requesting logout");
    try {
        const response = await fetch("/rest/user/logout", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            credentials: 'include',
            redirect: "follow", // manual, *follow, error
        })
        if (response.ok === true) {
            const payload = await response.json() as WindfallRestfulResponse.ResponsePacket;
            if (payload.CallHeader.StatusCode == "SC_OK")
            {
				console.log("Logout successful");
                sessionStorage.removeItem("n32SavedCartInfo")
                Cookies.remove("n32UserPrimaryCity")
                Cookies.remove("n32UserPrimaryZip")
                Cookies.remove("n32UserPrimaryRegionId")
				window.location.href = '/';
                // dispatch({type: USER_LOGOUT})
                // dispatch(getBasicApplicationState() as any);
            }
        }
        else {
            throw "Logout has failed";
        }
    } catch (error) {
        console.error(error)
    }
    
}