import Cookies from "js-cookie";
import { DeliveryToUser } from "store/actions/userActions";

export async function getUserGeolocation(): Promise<GeolocatedLocation> {
    const defaultGeoLocation = { zipcode: "27513", city: "Cary" } as unknown as never;
    try {
      const apiKey = "3bc77b7f42564c24a7deab1440ff15ad";
      const apiUrlBase = "https://api.ipgeolocation.io/ipgeo";
      const apiParams = `fields=geo&excludes=latitude,longitude&apiKey=${apiKey}`;
      //Form the URL
      let apiCall = apiUrlBase + "?";
      apiCall += apiParams;
  
      // timeout setup
      const options = { timeout: 5000 };
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), options.timeout);
  
      const response = await fetch(apiCall, {
        method: "GET",
        mode: "cors",
        signal: controller.signal,
        ...options,
      })
        .then((res) => res.json())
        .then((res) => {
          if ("message" in res && !("zipcode" in res)) {
            console.error("Error getting user geolocation: ", res);
            return defaultGeoLocation;
          }
          return res as GeolocatedLocation;
        })
        .catch((e) => {
          console.error("Error getting user geolocation: ", e);
          return defaultGeoLocation;
        });
      clearTimeout(id);
      return response;
    } catch (error) {
      console.error(error);
      return defaultGeoLocation;
    }
}

interface GeolocatedLocation {
    ip: string,
    country_code2: string,
    country_code3: string,
    country_name: string,
    state_prov: string,
    district: string,
    city: string,
    zipcode: string
}

export function setAnonymousUserLocationCityZipCookie(zip: string | null, city: string | null) {
    if (zip)
        Cookies.set('n32AnonymousLocationZip', zip, { expires: 1 });
    else
        Cookies.set('n32AnonymousLocationZip', '', { expires: 1 });

    if (city)
        Cookies.set('n32AnonymousLocationCity', city, { expires: 1 });
    else
        Cookies.set('n32AnonymousLocationCity', '', { expires: 1 });
}

export function getAnonymousUserLocationCityZipCookie(): DeliveryToUser {
    return {
        zipCode: Cookies.get('n32AnonymousLocationZip'),
        city: Cookies.get('n32AnonymousLocationCity')
    }
}