import * as React from 'react';
import { Component } from 'react';
import './preloader.scss'

/**
 * Implements a bouncing-ball preloader
 * 
 * @param {string} textUnderAnimation - optional text under the bouncing balls
 * @param {string} textUnderAnimationCSSClasses - optional css casses to add to the text under the bouncing balls
 * @param {string} id - optional compontent/css id
 * @param {string} className - optional css classes
 */
export default class Preloader extends Component {
    props: PreloaderProps;
    constructor(props: PreloaderProps) {
        super(props);
    }
    render() {
        return (
            <div id={this.props.id} className={"preloader-container " + (this.props.className ? this.props.className : "")}>
                <div className="lds-typing">
                    <div className="first-preloader-ball"></div>
                    <div className="second-preloader-ball"></div>
                    <div className="third-preloader-ball"></div>
                    <div className="forth-preloader-ball"></div>
                </div>
                {
                    this.props.textUnderAnimation ?
                        <div className={"preloader-text" + (this.props.textUnderAnimationCSSClasses ? (" " +this.props.textUnderAnimationCSSClasses) : "")}>
                            {this.props.textUnderAnimation}
                        </div>
                    :
                        ""
                }
            </div>
        )
    }
}

export interface PreloaderProps {
    textUnderAnimation?: string
    textUnderAnimationCSSClasses?: string
    id?: string
    className?: string
}