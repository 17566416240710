import verticalConstants from 'components/verticals/verticalConstants';
import React, { FunctionComponent } from 'react';

const AutoOrderDescription: FunctionComponent = ({ ...rest }) => {
    return (
        <>
            <h1 className="tooltip-heading">Do you order regularly? Try Auto Order!</h1>
            <p className="tooltip-paragraph">With Auto Order, you only have to order once and then let us do the work for you.</p>
            
            <h1 className="tooltip-heading">Always the Best Deal on Price</h1>
            <p className="tooltip-paragraph">You don't need to choose the vendor; {verticalConstants.verticalName.firstCharacterUpperCase} will always pick the best price for you.</p>
            
            <h1 className="tooltip-heading">Choose How Often the Order is Placed</h1>
            <p className="tooltip-paragraph">From options: Once every 2 weeks, Once every month, and Once every 2 months.</p>
            
            <h1 className="tooltip-heading">Manage Your Auto Order at Anytime</h1>
            <p className="tooltip-paragraph">We'll send you a reminder before an order is placed.  You will have the option to Skip, Pause, or Cancel the order.</p>
        </>
    )
}

export default AutoOrderDescription;