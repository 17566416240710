import { AppThunk } from "store/reducer/ec/rootReducer";
import { Action } from "redux";
import { AddCSSFileAction } from "./ec/appLevelActions";
import { clearCityAndZipCookies } from "./userActions";
// ====================================================================================================
// Action Types
export const APPLICATION_INIT = "app: Initialization"
export const APPLICATION_RESET = "app: Reset Application State"
export const SERVER_UNKNOWN = "server: Unknown state (App is loading)";
export const SERVER_ONLINE = "server: Online"
export const SERVER_OFFLINE = "server: Offline"
export const SERVER_FIRST_CONTACT_ERROR = "server: First contact returned error";
export const SERVER_ERROR_STATE = "server: In Error State"
export interface SetServerAsOnline extends Action {
    type: typeof SERVER_ONLINE,
}
export interface SetServerAsOffline extends Action {
    type: typeof SERVER_OFFLINE,
}
export interface SetServerFirstContactReturnedError extends Action {
    type: typeof SERVER_FIRST_CONTACT_ERROR,
}
export interface SetServerInErrorState extends Action {
    type: typeof SERVER_ERROR_STATE,
}
export type AppLevelActionTypes = SetServerAsOnline 
                                | SetServerAsOffline 
                                | SetServerFirstContactReturnedError 
                                | SetServerInErrorState
                                | AddCSSFileAction;

// ====================================================================================================
// Actual Actions
export const setServerAsOnline = (): SetServerAsOnline => {
    return {
        type: SERVER_ONLINE
    }
}
export const setServerAsOffline = (): SetServerAsOffline => {
    return {
        type: SERVER_OFFLINE
    }
}
export const setServerFirstContactReturnedError = (): SetServerFirstContactReturnedError => {
    return {
        type: SERVER_FIRST_CONTACT_ERROR
    }
}
export const setServerAsInErrorState = (): SetServerInErrorState => {
    return {
        type: SERVER_ERROR_STATE
    }
}

export const logoutUser = (destination?: string): AppThunk => async dispatch => {
    console.log("Requesting logout");
    try {
        const response = await fetch("/rest/user/logout", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            credentials: 'include',
            redirect: "follow", // manual, *follow, error
        })
        if (response.ok === true) {
            const payload = await response.json() as WindfallRestfulResponse.ResponsePacket;
            if (payload.CallHeader.StatusCode == "SC_OK")
            {
                console.log("Logout successful");
                clearCityAndZipCookies()
                // Application state is reset within the root reducer.
				window.location.href = (destination ? destination : '/');
            }
        }
    } catch (error) {
        console.error(error);
    }
    
}