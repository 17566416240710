import { UserActionTypes, USER_UPDATE, USER_LOAD_FULL_ACCOUNT_INFO, USER_DELETE_CC_EMAIL, USER_BACKEND_ERROR, USER_PAYMENT_UPDATE, USER_PAYMENT_MARK_AS_PRIMARY, USER_PAYMENT_ADD, USER_SHIPPING_UPDATE, USER_SHIPPING_MARK_AS_PRIMARY, USER_SHIPPING_ADD_TO_STORE, USER_NAME_UPDATE, USER_EMAIL_UPDATE, USER_CC_EMAIL_LIST_UPDATE, USER_CC_EMAIL_UPDATE, USER_LICENSE_UPDATE, USER_DELETE_LICENSE, USER_UPDATE_PROFILE, USER_ADMIN_VERIFY } from 'store/actions/userActions';

const initialState: UserState = {
    IsGuestUser: true,
    userHasBeenRetrieved: false,
    myAccountHasBeenRetrieved: false,
	userHasSubscribedToEmail: false,
	isAuthorized: false,
	backendError: false,
	errorMessage: ''
}

export default function(state: UserState = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case USER_UPDATE:
            // @ts-ignore - not sure why typescript is confused about the following, but it appears to work correctly... -Michael 7/14/2020
            return {
                ...state,
                userHasBeenRetrieved: true,
                ...action.payload.CurrentUser,
                isAuthorized: action.payload.IsAuthorized
            }
		case USER_LOAD_FULL_ACCOUNT_INFO:
            return {
				...state,
                myAccountHasBeenRetrieved: true,
                ...action.payload.User,
                DistributionLists: action.payload.DistributionLists,
                NamePrefixes: action.payload.NamePrefixes,
                LicenseOptions: action.payload.LicenseOptions,
				Regions: action.payload.Regions,
				PaymentCreditCards: action.payload.User.PaymentCreditCards ? action.payload.User.PaymentCreditCards : [],
				Addresses: action.payload.User.Addresses ? action.payload.User.Addresses : [],
				BusinessTypeId: action.payload.User.BusinessTypeId,
				BusinessTypeName: action.payload.User.BusinessTypeName,
				BusinessTypeParentId: action.payload.User.BusinessTypeParentId,
				BusinessTypeParentName: action.payload.User.BusinessTypeParentName,
				BusinessOtherText: action.payload.User.BusinessOtherText,
				isMultiLocation: action.payload.MultilocationSw,
			}
        case USER_NAME_UPDATE:
            if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true) {
				const newState = { 
					...state,
					Prefix: action.payload.Prefix,
					FirstName: action.payload.FirstName,
					MiddleName: action.payload.MiddleName,
					LastName: action.payload.LastName,
					Suffix: action.payload.Suffix
				};
                return newState;
			} else {
                return state;
			}
		case USER_EMAIL_UPDATE:
            if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true) {
				return {
					...state,
					EmailAddress: action.payload.EmailAddress
				}
			} else {
                return state;
			}
		case USER_CC_EMAIL_UPDATE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved && state.DistributionLists)
			{
				return {
					...state,
					DistributionLists: (Array.isArray(state.DistributionLists) && state.DistributionLists.length > 0) ?
						state.DistributionLists.map(list => {
							list.emailList.map(ccemail => {
								if (ccemail.dleId.toString() === action.payload.dleId.toString())
									ccemail.emailAddress = action.payload.EmailAddress;
								return ccemail
							})
							return list
						})
						:
						state.DistributionLists
				}
			} else {
				return state;
			}
		case USER_CC_EMAIL_LIST_UPDATE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved)
			{
				return {
					...state,
					DistributionLists: action.payload.DistributionLists
				}
			} else {
				return state;
			}
		case USER_LICENSE_UPDATE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved)
			{
				return {
					...state,
					Licenses: action.payload.Licenses,
				}
			} else {
				return state;
			}
		case USER_DELETE_LICENSE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved && state.Licenses)
			{
				return {
					...state,
					Licenses: (Array.isArray(state.Licenses) && state.Licenses.length > 0) ?
						state.Licenses.filter(license => {
							return license.Id.toString() != action.payload.Id.toString();
						})
						:
						state.Licenses
				}
			} else {
				return state;
			}
		case USER_ADMIN_VERIFY:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved && state.Impersonator)
			{
				const newState = { ...state};
				if (action.payload.IsProfessionalOffice === true || action.payload.IsProfessionalOffice === false)
					newState.IsProfessionalOffice = action.payload.IsProfessionalOffice;
				if (typeof(action.payload.ProfessionVerifier) !== 'undefined' && action.payload.ProfessionVerifier)
				{
					newState.ProfessionVerifier = action.payload.ProfessionVerifier;
					return newState;
				} else {
					const { ProfessionVerifier, ...newStateWithoutVerifier } = newState;
					// newState.ProfessionVerifier = action.payload.ProfessionVerifier;
					return newStateWithoutVerifier;
				}
			} else {
				return state;
			}
        case USER_DELETE_CC_EMAIL:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved && state.DistributionLists)
			{
				return {
					...state,
					DistributionLists: (Array.isArray(state.DistributionLists) && state.DistributionLists.length > 0) ?
						state.DistributionLists.map(list => {
							const updatedList: Account.DistributionLists.DistributionList = list;
							updatedList.emailList = list.emailList.filter(email => {
								return email.dleId.toString() != action.payload.dleId.toString()
							})
							return updatedList;
						})
						:
						state.DistributionLists
				}
			} else {
				return state;
			}
        case USER_PAYMENT_ADD:
            if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true) {
                const newState = { ...state};
                newState.PaymentCreditCards.push(action.payload); 
                return newState;
            }
            else {
                return state;
			}

        case USER_PAYMENT_UPDATE:
            if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true)
            {
                return {
                    ...state,
                    PaymentCreditCards: state.PaymentCreditCards.map((creditCard) => {
                        if (creditCard.CardId == action.payload.CardId)
                            return {
                                ...creditCard,
                                ...action.payload,
                            };
                        else
                            return creditCard
                    })
                }
            }
            else {
                return state;
            }
        case USER_PAYMENT_MARK_AS_PRIMARY:
            if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true) {
                return {
                    ...state,
                    PaymentCreditCards: state.PaymentCreditCards.map((creditCard) => {
                        return {
                            ...creditCard,
                            IsPrimary: creditCard.CardId == action.payload.CardId ? true : false
                        };

                    })
                }
            }
            else {
                return state;
			}
		case USER_SHIPPING_ADD_TO_STORE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true) {
				let newState;
				if (action.payload.IsPrimary === true)
				{
					const noPrimaryAddressList = state.Addresses.map((address) => {
						return {
							...address,
							IsPrimary: false
						};
					});
					newState = {
						...state,
						Addresses: noPrimaryAddressList
					}
				} else {
					newState = { ...state};
				}
				newState.Addresses.unshift(action.payload); // unshift prepends it to the start;
				return newState;
			}
			else {
				return state;
			}
		case USER_SHIPPING_UPDATE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true)
			{
				return {
					...state,
					Addresses: state.Addresses.map((address) => {
						if (address.Id.toString() == action.payload.Id.toString())
							return {
								...address,
								...action.payload,
							};
						else {
							return {
								...address,
								IsPrimary: (action.payload.IsPrimary && action.payload.IsPrimary === true) ? false : address.IsPrimary
							}
						}
					})
				}
			}
			else {
				return state;
			}
		case USER_SHIPPING_MARK_AS_PRIMARY:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved == true) {
				return {
					...state,
					Addresses: state.Addresses.map((address) => {
						return {
							...address,
							IsPrimary: address.Id.toString() == action.payload.Id.toString() ? true : false
						};

					})
				}
			}
			else {
				return state;
			}	
		case USER_UPDATE_PROFILE:
			if (state.IsGuestUser === false && state.myAccountHasBeenRetrieved)
			{
				let newState = { ...state};
				if (action.payload.BusinessTypeId)
				{
					newState.BusinessTypeId = action.payload.BusinessTypeId;
				} else {
					const { BusinessTypeId, ...newStateWithoutBusinessTypeId } = newState;
					newState = newStateWithoutBusinessTypeId;
				}
				if (action.payload.BusinessTypeName)
				{
					newState.BusinessTypeName = action.payload.BusinessTypeName;
				} else {
					const { BusinessTypeName, ...newStateWithoutBusinessType } = newState;
					newState = newStateWithoutBusinessType;
				}
				if (action.payload.BusinessTypeParentId)
				{
					newState.BusinessTypeParentId = action.payload.BusinessTypeParentId;
				} else {
					const { BusinessTypeParentId, ...newStateWithoutBusinessTypeParentId } = newState;
					newState = newStateWithoutBusinessTypeParentId;
				}
				if (action.payload.BusinessTypeParentName)
				{
					newState.BusinessTypeParentName = action.payload.BusinessTypeParentName;
				} else {
					const { BusinessTypeParentName, ...newStateWithoutBusinessTypeParentName } = newState;
					newState = newStateWithoutBusinessTypeParentName;
				}
				if (action.payload.BusinessOtherText)
				{
					newState.BusinessOtherText = action.payload.BusinessOtherText;
				} else {
					const { BusinessOtherText, ...newStateWithoutBusinessOtherText } = newState;
					newState = newStateWithoutBusinessOtherText;
				}
				return newState;
			} else {
				return state;
			}
        case USER_BACKEND_ERROR:
            return {
				...state,
				backendError: action.value,
				errorMessage: action.message
			}
        default:
            return state;
    }
}

// export interface UserState extends WindfallRestfulResponse.ResponsePieces.CurrentUser {
//     userHasBeenRetrieved: boolean;
// }

export type UserState = (GuestUserState | LoggedInUserState) & {
    userHasSubscribedToEmail: boolean
    userHasBeenRetrieved: boolean
    myAccountHasBeenRetrieved: boolean
    isAuthorized: boolean
	backendError: boolean
	errorMessage: string
};
type GuestUserState = {
    IsGuestUser: true,
}
export type LoggedInUserState = WindfallRestfulResponse.ResponsePieces.CurrentUser & ({myAccountHasBeenRetrieved: false} | MyAccountData);

type MyAccountData = {
	myAccountHasBeenRetrieved: true;
    Addresses: Array<AddressState>;
    PaymentCreditCards: Array<PaymentCardState>;
    Licenses: Array<Account.Licenses.Licence>;
	DistributionLists: Array<Account.DistributionLists.DistributionList>;
    NamePrefixes: Array<Account.NamePrefixes>;
    Regions: Array<Account.Addresses.Regions.List>;
    LicenseOptions: Array<Account.Licenses.LicenseOptions>;
	BusinessTypeParentId?: number;
	BusinessTypeParentName?: string;
	BusinessTypeId?: number;
	BusinessTypeName?: string;
	BusinessOtherText?: string;
	isMultiLocation?: boolean
}

export type PaymentCardState = Account.Billing.CreditCard & {
    removalStatus?: "processing" | "removed" | "failed";
    removalFailureReason?: string[];
}

export type AddressState = Account.Addresses.Address & {
    removalStatus?: "idle" | "processing" | "removed" | "failed";
    removalFailureReason?: string[];
}
