import delay from "delay";
import React, { useCallback, useEffect, useState } from "react";

export default function TrustPilotBadge() {
  const [includeScript, setIncludeScript] = useState(false);

  const renderBadge = useCallback(async () => {
    await delay(500);
    setIncludeScript(true);
  }, []);

  useEffect(() => {
    // This keeps the badge from rendering until it hits the client to avoid hydration errors
    if (includeScript === false) {
      renderBadge();
    }
  }, [includeScript, renderBadge]);

  return (
    <>
      <div
        className="trustpilot-badge"
        dangerouslySetInnerHTML={{
          __html: `
        <div
          class="trustpilot-widget"
          suppressHydrationWarning={true}
          data-locale="en-US"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="5a7973e0b43dd90001506fb5"
          data-style-height="150px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a href="https://www.trustpilot.com/review/net32.com" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
    `,
        }}
        suppressHydrationWarning={true}
      />
      {includeScript && (
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      )}
    </>
  );
}

