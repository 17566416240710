import { AppThunk } from "store/reducer/ec/rootReducer";
import { Action } from "redux";
import { ModifyReorderProductListRetrievalStatus } from "store/reducer/ec/reorderReducer";

// ====================================================================================================
// Action Types
export const REORDER_MODIFY_PRODUCT_LIST = 'reorder:Modify Product List';
export const SET_REORDER_MODIFY_PRODUCT_LIST_RETRIEVAL_STATUS = "reorder: Set Modify Product List Retrieval Status";

export interface ModifyReorderProductListAction extends Action {
    type: typeof REORDER_MODIFY_PRODUCT_LIST;
    payload: {
        reorderProducts: Array<ReorderProduct>,
        reorderProductCategories: Array<any>,
        totalReorderProducts: number
    }
}

export interface SetModifyReorderProductListRetrievalStatusAction extends Action {
    type: typeof SET_REORDER_MODIFY_PRODUCT_LIST_RETRIEVAL_STATUS,
    payload: ModifyReorderProductListRetrievalStatus
}

export type CoreReorderActionTypes = ModifyReorderProductListAction;

export type OtherReorderActionTypes = SetModifyReorderProductListRetrievalStatusAction;

export type ReorderActionTypes = CoreReorderActionTypes | OtherReorderActionTypes;


// ====================================================================================================
// Actual Actions
export function modifyReorderProductList(reorderProducts: Array<ReorderProduct>, reorderProductCategories: Array<any>, totalReorderProducts: number): ModifyReorderProductListAction {
    return {
        type: REORDER_MODIFY_PRODUCT_LIST,
        payload: {
            reorderProducts: reorderProducts,
            reorderProductCategories: reorderProductCategories,
            totalReorderProducts: totalReorderProducts
        }
    }
}

export function setModifyReorderProductListRetrievalStatus (retrievalState: ModifyReorderProductListRetrievalStatus): SetModifyReorderProductListRetrievalStatusAction {
    return {
        type: SET_REORDER_MODIFY_PRODUCT_LIST_RETRIEVAL_STATUS,
        payload: retrievalState
    }
}

export const getReorderProductList = (): AppThunk => async dispatch => {
    dispatch(setModifyReorderProductListRetrievalStatus(ModifyReorderProductListRetrievalStatus.RETRIEVING))
    
    try {
        const response = await fetch("/rest/order/quickOrder?sortOpt=purchases&sortDir=false", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
        })
        if (response.ok === true) {
            const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket<ReorderWindfallResponse>;
            
            if (responsePayload.CallHeader.StatusCode == "SC_OK") {
                dispatch(modifyReorderProductList(responsePayload.Payload.ReorderItems, responsePayload.Payload.ReorderProductCategories, responsePayload.Payload.TotalReorderItems));
            }
        }
        else {
            console.error("Error when loading reorder roduct list", response);
        }

        dispatch(setModifyReorderProductListRetrievalStatus(ModifyReorderProductListRetrievalStatus.RETRIEVED))
    } catch (error) {
        console.error("Error when loading reorder roduct list", error);
    }
}

interface ReorderWindfallResponse {
    ReorderItems: Array<ReorderProduct>
    ReorderProductCategories: Array<any>
    TotalReorderItems: number
}
export interface ReorderProduct extends Products.ManufacturerProduct {
    NumberOfPurchases: number
}