import { Render, autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import React, { useRef, useState, useCallback, useEffect, createElement, Fragment } from "react";
import { useSearchBox } from "react-instantsearch";
import { defaultIndex, searchClient, querySuggestionsIndex } from "./constants";
import { render } from "react-dom";
import SuggestedProductItem, { ProductHit } from "./suggestedProductItem";
import { dispatchAnalyticsEvent } from "utilities/analytics/analyticsEventSender";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer/ec/rootReducer";
import BuyItAgainSuggestions from "./buyItagainSuggestions";
import { MaybePromise } from "instantsearch.js/es/lib/utils";
import { useHistory } from "react-router-dom";

function debouncePromise(fn: (...args: any[]) => any, time: number) {
  let timerId: NodeJS.Timeout | undefined = undefined;

  return function debounced(...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    }) as MaybePromise<any>;
  };
}

type AutocompleteProps = /* Partial<AutocompleteOptions<BaseItem>> & */ {
  customSearchURI?: string;
};

export function AlgoliaAutocomplete({ customSearchURI }: AutocompleteProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { query, refine: setQuery } = useSearchBox();
  
  const [queryText, setQueryText] = useState(query);
  const [algoliaPanelIsOpen, setAlgoliaPanelIsOpen] = useState(false);

  const performSearch = useCallback(
    (query: string) => {
        dispatchAnalyticsEvent({
          eventName: "site_search",
          search: {
            query: query,
          },
        });
        const url = new URL(window.location.origin);
        url.searchParams.set(`${defaultIndex}[query]`, query);
        const finalQuery = url.search;
        window.location.href = `${customSearchURI || "/search"}${finalQuery}`;
    },
    [customSearchURI]
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: querySuggestionsIndex,
      getSearchParams() {
        return {
          hitsPerPage: 4,
        };
      },
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            performSearch(item.query);
          },
          templates: {
            ...source.templates,
            header() {
              return (
                <>
                  <span className={`text-muted font-weight-bolder text-uppercase sectionHeader`}>Suggestions</span>
                </>
              );
            },
          },
        };
      },
    });

    const autocompleteInstance = autocomplete({
      container: containerRef.current,
      initialState: { query },
      insights: window.n32?.devMode === true ? false : true,
      detachedMediaQuery: "none",
      plugins: [querySuggestions],
      onReset() {
        setQuery("");
      },
      onSubmit({ state }) {
        performSearch(state.query);
      },
      onStateChange({ prevState, state }) {
        setAlgoliaPanelIsOpen(state.isOpen);
        if (prevState.query !== state.query) {
          setQueryText(state.query);
        }
      },
      // -----------------------------------------------------------------
      // React 17 - Keeping this for later for transferral to old code
      renderer: { createElement, Fragment, render: render as Render },
      panelContainer: panelRef.current as HTMLElement,
      // -----------------------------------------------------------------
      getSources: debouncePromise(
        ({ query }) => [
          {
            sourceId: "products",
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: defaultIndex,
                    query,
                    params: {
                      hitsPerPage: 4,
                      facetFilters: ["availability:Y "],
                    },
                  },
                ],
              });
            },
            templates: {
              header() {
                return (
                  <>
                    <span className={`text-muted font-weight-bolder text-uppercase sectionHeader`}>Products</span>
                  </>
                );
              },
              getSearchParams() {
                return {
                  hitsPerPage: 4,
                };
              },
              item({ item }: any) {
                return <SuggestedProductItem hit={item as ProductHit} />;
              },
              footer() {

                const url = new URL(window.location.origin);
                url.searchParams.set(`${defaultIndex}[query]`, query);
                const finalQuery = url.search;
                return (
                  <div className={`text-center py-2 suggestionPanelFooter`}>
                    <a
                      href={`/search${finalQuery}`}
                      className="fst-italic text-decoration-underline"
                    >
                      {`See more results for "${query}" >`}
                    </a>
                  </div>
                );
              },
            },
            getItemInputValue() {
              return query;
            },
          },
        ],
        500
      ),
    });

    return () => autocompleteInstance.destroy();
  }, [customSearchURI, performSearch, query, setQuery]);

  const orderHistory  = useSelector(
    (state: RootState) => state.orderHistory.buyItAgainSuggestions,
)

  const [inputFocused, setInputFocused] = useState(false);

  // Since we don't have any direct control over the algolia input element
  // We must attach vannila js event listeners to the input by finding it
  // with the help of native dom apis. And then will have to track the focus state of it.
  const input = containerRef.current?.querySelector("input.aa-Input");
  useEffect(() => {
    if (!input || !orderHistory?.length) return;
    const onFocus = () => setInputFocused(true);

    // Delaying the close of the pop up so if any event has triggered inside the popup
    // before the popup closing, then it can be captured properly before the popup close.
    const onBlur = () => setTimeout(() => setInputFocused(false), 500);

    input.addEventListener("focus", onFocus);
    input.addEventListener("blur", onBlur);
    return () => {
      input.removeEventListener("focus", onFocus);
      input.removeEventListener("blur", onBlur);
    };
  }, [input, orderHistory?.length]);

  const showLastPurchased = !algoliaPanelIsOpen && inputFocused;

  return (
    <>
      <div
        ref={containerRef}
        className={`flex-grow-1 auto-complete-input-container ${queryText === "" ? "without-query" : ""}`}
      />
      <div ref={panelRef} id="auto-complete-panel-container" className="auto-complete-panel-container" />
      <div className="buy-it-again-container">
        {showLastPurchased && <BuyItAgainSuggestions buyItAgainSuggestionVisibility={"shown"} searchContainerRef={containerRef} />}
      </div>
    </>
  );
}
