import RetrievalStatus from "enums/retrievalStatus";
import { BuyItAgainSuggestions, OrderHistoryActions, ORDER_HISTORY_ADD_ORDERS_TO_STORE, ORDER_HISTORY_SUMMARY_ADD_TO_STORE, SET_BUY_IT_AGAIN_SUGGESTIONS } from "store/actions/ec/orderHistoryActions";



const initialState: OrderHistoryState = {
    orders: [],
    shippingMethods: [],
    total: 0,
    orderSummaries: [],
    ordersLoaded: false,
    buyItAgainRetrievalState: RetrievalStatus.NOT_RETRIEVED,
    buyItAgainSuggestions: []
}


export default (state: OrderHistoryState = initialState, action: OrderHistoryActions) => {
    switch (action.type) {
        case ORDER_HISTORY_ADD_ORDERS_TO_STORE: {
            const newState = { ...state };
            if (action.payload === undefined || action.payload.orders === undefined) {
                newState.ordersLoaded = true;
                newState.orderSummaries = getReportList();
                return newState;
            }
            else {
                newState.ordersLoaded = true;
                newState.orders = manifestsParser(action.payload.orders);
                newState.total = action.payload.total;
                newState.shippingMethods = action.payload.shippingMethods;
                return newState;
            }
        }
        case ORDER_HISTORY_SUMMARY_ADD_TO_STORE: {
            const newOHSState = { ...state };
            if (action.payload === undefined || action.payload.orderSummaries === undefined) {
                newOHSState.orderSummaries = getReportList();
                return newOHSState;
            }
            else {
                newOHSState.orderSummaries = (action.payload.orderSummaries != undefined) ? sortOrderSummaries(action.payload.orderSummaries) : getReportList();
                return newOHSState;
            }
        }
        case SET_BUY_IT_AGAIN_SUGGESTIONS:
            return {
                ...state,
                buyItAgainRetrievalState: RetrievalStatus.RETRIEVED,
                buyItAgainSuggestions: action.payload
            } as OrderHistoryState
        default:
            return state;
    }
}


export interface OrderHistoryState {
    orders: Array<Orders.OrdersHistory.CustomerOrder>;
    shippingMethods: any;
    total: number;
    orderSummaries: Array<Orders.ReportListType>;
    ordersLoaded: boolean;
    buyItAgainRetrievalState: RetrievalStatus;
    buyItAgainSuggestions: Array<BuyItAgainSuggestions>
}

function  sortOrderSummaries(orderSummaries: Array<Orders.ReportListType>) {
    orderSummaries.sort((a, b) => 
    {
        if(a.ReportType === "YTD" || b.ReportType === "All")
            return -1;
        if(b.ReportType === "YTD" || a.ReportType === "All")
            return 1;
        if(a.ReportType === "Month" && b.ReportType === "Month" && a.DisplayMonth != null &&  b.DisplayMonth != null)
        {            
            return a.DisplayMonth > b.DisplayMonth ? -1 : 1;
        }
        if(a.ReportType === "Year" && b.ReportType === "Year" && a.DisplayYear != null &&  b.DisplayYear != null)
        {
                return a.DisplayYear > b.DisplayYear ? -1 : 1;
        }
        if(a.ReportType === "Month" && b.ReportType === "Year")
            return -1;
        if(a.ReportType === "Year" && b.ReportType === "Month")
            return 1;
        return 0;
    }
    );
    return orderSummaries;
}

function manifestsParser(orders: Array<Orders.OrdersHistory.CustomerOrder>) {
    const orderList: Array<Orders.OrdersHistory.CustomerOrder> = [];
    for (const customerOrder of orders) {
        let statusMessage = "";
        let shippedItemCount = 0;
        let deliveredItemCount = 0;
        let processingItemCount = 0;
        let backorderedItemCount = 0;
        let cancelledItemCount = 0;
        let returnedItemCount = 0;
        // For some strange reason a "for...of" loop sometimes skips over vendor orders when a standard loop doesn't. (seen only in the order history page.) This change was copied to OrderDetailReducer.ts just to be safe + consistant.
        for (let i = 0; i < customerOrder.vendorOrders.length; i++) {
            const vendorOrder = customerOrder.vendorOrders[i];
            let shipmentLineItems = Array<Orders.OrdersHistory.ShippedLineType>();
            const shipmentLineItemData = Array<Orders.OrdersHistory.ShippedLineItem>();

            const shippedLineItems = Array<Orders.OrdersHistory.ShippedLineType>();
            const shippedLineItemData = Array<Orders.OrdersHistory.ShippedLineItem>();

            const deliveredLineItems = Array<Orders.OrdersHistory.ShippedLineType>();
            const deliveredLineItemData = Array<Orders.OrdersHistory.ShippedLineItem>();

            const processingLineItems = Array<Orders.OrdersHistory.ProcessedLineItem>();
            const cancelledLineItems = Array<Orders.OrdersHistory.ProcessedLineItem>();
            const returnedLineItems = Array<Orders.OrdersHistory.ProcessedLineItem>();
            let manifestStatus;
            vendorOrder.lineItems.forEach(function (lineItem: Orders.OrdersHistory.LineItem) {
                if (lineItem.totalPending > 0) {
                    const processingItem = {
                        "noOfItems": lineItem.totalPending,
                        "lineItem": lineItem
                    }
                    processingItemCount = processingItemCount + lineItem.totalPending;
                    processingLineItems.push(processingItem);
                }
                const shipmentManifestList: any[] = [];
                if(lineItem.manifests != undefined){ lineItem.manifests.forEach(function (manifest: Orders.OrdersHistory.Manifests) {
                    if (manifest.type === "shipment") {
                        if(!shipmentManifestList.includes(manifest.id)){
                            shipmentManifestList.push(manifest.id);
                        }
                        const shipmentIndex: number = shipmentManifestList.indexOf(manifest.id);
                        const shippedItem = {
                            "trackingKey": manifest.creationTime.toString() + manifest.shippingMethod + manifest.trackingNumber,
                            "noOfItems": manifest.festQuantity,
                            "lineItem": lineItem,
                            "trackingNumber": manifest.trackingNumber,
                            "shippedDate": manifest.shipTime,
                            "shippedDateInitialized": manifest.shipTime,
                            "deliveryDate": manifest.deliveryTime,
                            "shipmentMethod": manifest.shippingMethod,
                            "creationTime": manifest.creationTime,
                            "creationTimeRaw": manifest.creationTime
                        }
                        shipmentLineItemData.push(shippedItem);
                        if(manifest.deliveryTime) {
                            manifestStatus = 'DELIVERED';
                            deliveredItemCount = deliveredItemCount + manifest.festQuantity;
                            deliveredLineItemData.push(shippedItem);
                        } else {
                            manifestStatus = 'SHIPPED';
                            shippedItemCount = shippedItemCount + manifest.festQuantity;
                            shippedLineItemData.push(shippedItem);
                        }
                    } else if (manifest.type === "cancellation") {
                        const cancelledItem = {
                            "noOfItems": manifest.festQuantity,
                            "lineItem": lineItem,
                            "reason": ((manifest.cancelReasonDesn != undefined && manifest.cancelReasonId != "23") ? manifest.cancelReasonDesn : "Call Customer Loyalty (800) 517-1997")
                        }
                        cancelledItemCount = cancelledItemCount + manifest.festQuantity;
                        cancelledLineItems.push(cancelledItem);
                    } else if (manifest.type === "return") {
                        const returnReason = "Customer Return" + (manifest.isForExchange === true ? " for Exchange - " : " - ") +
                            (manifest.returnReasonDesn != undefined ? manifest.returnReasonDesn : "")
                            + (manifest.isForExchange === true ? " - Awaiting Shipment" : "");
                        const returnedItem = {
                            "noOfItems": manifest.festQuantity,
                            "lineItem": lineItem,
                            "reason": returnReason
                        }
                        returnedItemCount = returnedItemCount + manifest.festQuantity;
                        returnedLineItems.push(returnedItem);
                    } else if (manifest.type === "backorder") {
                        const backOrderedItem = {
                            "noOfItems": manifest.festQuantity,
                            "lineItem": lineItem,
                            "backorderedDate": (manifest.eta != undefined ? manifest.eta : null)
                        }
                        backorderedItemCount = backorderedItemCount + manifest.festQuantity;
                        processingLineItems.unshift(backOrderedItem);
                    }
                })}
            });
            shipmentLineItems = reduceItems(shipmentLineItemData, shipmentLineItems);
            for(const shipment of shipmentLineItems) {
                if(!shipment?.shippedLineItemData[0]?.deliveryDate)
                    shippedLineItems.push(shipment);
                else
                    deliveredLineItems.push(shipment);
            }
            vendorOrder.shipmentLineItems = shipmentLineItems;
            vendorOrder.shippedLineItems = shippedLineItems;
            vendorOrder.deliveredLineItems = deliveredLineItems;
            vendorOrder.processingLineItems = processingLineItems;
            vendorOrder.cancelledLineItems = cancelledLineItems;
            vendorOrder.returnedLineItems = returnedLineItems;
        }
        if (shippedItemCount > 0)
            statusMessage = statusMessage + "Shipped (" + shippedItemCount + "), ";
        if (deliveredItemCount > 0)
            statusMessage = statusMessage + "Delivered (" + deliveredItemCount + "), ";
        if (processingItemCount  > 0)
            statusMessage = statusMessage + "Processing (" + processingItemCount + "), ";
        if (backorderedItemCount > 0)
            statusMessage = statusMessage + "Backordered (" + backorderedItemCount + "), ";
        if (cancelledItemCount > 0)
            statusMessage = statusMessage + "Cancelled (" + cancelledItemCount + "), ";
        if (returnedItemCount > 0)
            statusMessage = statusMessage + "Returned (" + returnedItemCount + "), ";
        
        customerOrder.statusMessage = statusMessage.slice(0, -2);
        orderList.push(customerOrder);
    }
    return orderList;
}


function reduceItems(shippedLineItemData: Array<Orders.OrdersHistory.ShippedLineItem>, shippedLineItems: Array<Orders.OrdersHistory.ShippedLineType>) {
    shippedLineItemData.sort((a: { trackingKey: string; }, b: { trackingKey: string; }) => (a.trackingKey > b.trackingKey) ? -1 : ((b.trackingKey > a.trackingKey) ? 1 : 0));
    let previousTrackingNumber: string | null = null;
    let sortedShippedLineItems = Array<Orders.OrdersHistory.ShippedLineItem>();
    let currentShipmentIndex = 0; // Index to keep track of what shipment we are on
    shippedLineItemData.map((element: Orders.OrdersHistory.ShippedLineItem, i: number) => {
        if (previousTrackingNumber === element.trackingKey) { // Continuing the shipment
            sortedShippedLineItems.push(element);
        } else if (previousTrackingNumber === null) { // First line item
            previousTrackingNumber = element.trackingKey
            sortedShippedLineItems.push(element);
        } else { // Start new Shipment
            const item = { // -> add tracking no and all 
                "trackingKey": previousTrackingNumber.toString(),
                "trackingNumber": sortedShippedLineItems[0].trackingNumber,
                "shippedLineItemData": sortedShippedLineItems,
                "shipmentIndex": currentShipmentIndex++ //Add shipmentIndex for "get help" page link
            } 
            shippedLineItems.push(item);
            sortedShippedLineItems = [];
            previousTrackingNumber = element.trackingKey
            sortedShippedLineItems.push(element);
        }
        if (i === (shippedLineItemData.length - 1)) { // Last lineItem: make sure we add this shipment
            const item = { // -> add tracking no and all 
                "trackingKey": previousTrackingNumber,
                "trackingNumber": sortedShippedLineItems[0].trackingNumber,
                "shippedLineItemData": sortedShippedLineItems,
                "shipmentIndex": currentShipmentIndex++ //Add shipmentIndex for "get help" page link
            }
            shippedLineItems.push(item);
        }
    })
    return shippedLineItems;
}

function getReportList() {
    const list = [{
        ReportType: "YTD",
        DisplayMonth: null,
        DisplayYear: null,
        NumberOfOrders: 0,
        TotalMoney: 0,
        TotalSavingsMoney: 0,
        TotalSavingsPercent: 0,
        totalRetailMoney: 0,
        TotalOrderMoney: 0
    }]
    return list;
}