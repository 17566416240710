import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import React from "react";

import input from "./data/subCategory-options.json";

import "../../../../src/layouts/ec/full-page/headerV2.scss";

export default function SubCategoryMainMenuContainerMobile() {
  const [data, setData] = useState<SubCategoryMainMenuDataItem[]>();
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [dataItem, setDataItem] =
    useState<SubCategoryMainMenuDataItem | null>();

  const handleMainMenuClick = useCallback(
    (name: string) => {
      setShowMainMenu(false);
      setDataItem(data?.find((dataItem) => dataItem?.name === name));
    },
    [data]
  );

  const handleBackBtnClick = useCallback(() => {
    setDataItem(null);
    setShowMainMenu(true);
  }, []);

  const handleNavigation = useCallback(() => {
    setShowMainMenu(true);
  }, []);

  useEffect(() => {
    const loadedData = JSON.stringify(input);
    const json = JSON.parse(loadedData);
    setData(json);
  }, []);

  return (
    <>
      <div className={"mobile-container"}>
        {showMainMenu && (
          <div className={"mobile-main-menu"}>
            {data?.map((dataItem, index) => (
              <div key={index}>
                <p key={index} className={"mobile-link"} onClick={() => handleMainMenuClick(dataItem?.name)}>
                  {dataItem?.name}
                  <span className={"mobile-nav-link"} onClick={() => handleMainMenuClick(dataItem?.name)}></span>
                </p>
              </div>
            ))}
          </div>
        )}
        {dataItem && showMainMenu === false && (
          <div className={"mobile-submenu"}>
            <div className={"mobile-submenu-header"}>
              <div className={"mobile-submenu-head"} onClick={handleBackBtnClick}>
                <svg viewBox={"0 0 14 14"} xmlns={"http://www.w3.org/2000/svg"}>
                  <path
                    d={
                      "M13.9688 7C13.9688 7.4375 13.625 7.78125 13.2188 7.78125H2.59375L6.75 11.7188C7.0625 12 7.0625 12.5 6.78125 12.7812C6.5 13.0938 6.03125 13.0938 5.71875 12.8125L0.21875 7.5625C0.0625 7.40625 0 7.21875 0 7C0 6.8125 0.0625 6.625 0.21875 6.46875L5.71875 1.21875C6.03125 0.9375 6.5 0.9375 6.78125 1.25C7.0625 1.53125 7.0625 2.03125 6.75 2.3125L2.59375 6.25H13.2188C13.6562 6.25 13.9688 6.59375 13.9688 7Z"
                    }
                    fill={"#4385F6"}
                  ></path>
                </svg>
                &nbsp; Back
              </div>
              <h3>{dataItem?.name}</h3>
            </div>
            <div className={"mobile-submenu-links"}>
              {dataItem?.links?.map((subMenuItem, index) => (
                <a key={index} href={subMenuItem?.href} onClick={handleNavigation}>
                  {subMenuItem?.name}
                  <span className={"mobile-submenu-nav-links"}></span>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export interface SubCategoryMainMenuDataItem {
  name: string;
  links: Array<SubCategorySubMenuItem>;
}

export interface SubCategorySubMenuItem {
  name: string;
  href: string;
}
