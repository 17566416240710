import { combineReducers } from "redux";
import userReducer, { UserState } from "store/reducer/userReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import categoriesReducer from "./categoriesReducer";
import orderHistoryReducer from "./orderHistoryReducer";
import orderDetailReducer from "./orderDetailReducer";
import { USER_LOGOUT } from "store/actions/userActions";
import reorderReducer from "./reorderReducer";
import { APPLICATION_RESET } from "store/actions/ec/appLevelActions";
import appLevelReducer from "store/reducer/appLevelReducer";

const appReducer = combineReducers({
    app: appLevelReducer,
    user: userReducer,
    shoppingCart: shoppingCartReducer,
    categories: categoriesReducer,
    orderHistory: orderHistoryReducer,
    orderDetail: orderDetailReducer,
    reorderProducts: reorderReducer,
});

const rootReducer = (state: any, action: Action) => {
    if (action.type == USER_LOGOUT || action.type == APPLICATION_RESET) {
        state = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    null,
    Action<string>
>