import * as React from 'react';
import { Component } from 'react';

/**
 * @deprecated Used verticalConstants instead.
 */
export default class VerticalName extends Component {
    props: VerticalNameProps
    constructor(props: VerticalNameProps) {
        super(props);
    }
    render() {
        let verticalName = 'net32';
        if (this.props.upperCaseFirstChar)
        {
            verticalName = verticalName.charAt(0).toUpperCase() + verticalName.slice(1);
        }
        if (this.props.allUpperCase)
        {
            verticalName = verticalName.toUpperCase();
        }
        return (
            <React.Fragment>
                {verticalName}
            </React.Fragment>
        )
    }
}

export interface VerticalNameProps
{
    upperCaseFirstChar?: boolean;
    allUpperCase?: boolean;
}