import apiKey from 'components/common/apiKey';
import DebouncedLoader from 'components/debouncedLoader';
import SystemError from 'components/ec/error-pages/systemError';
import verticalConstants from 'components/verticals/verticalConstants';
import useAnalyticsManager from 'hooks/useAnalyticsManager';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { RootState } from "store/reducer/ec/rootReducer";
import { ShoppingCartRetrievalStatus, ShoppingCartState } from 'store/reducer/ec/shoppingCartReducer';
import { UserState } from 'store/reducer/userReducer';
import { convertUserStateToUserDataForAnalytics } from 'utilities/analytics/analyticsEventSender';
import FullPageFooter from './footer';
import FullPageHeaderV2 from './headerV2';

export const FullPage: FunctionComponent<FullPageProps> = ({ title, children, className, pageName, disablePageViewReport = false, preventDisplayOfOfflinePage = false }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const { dispatchPageView } = useAnalyticsManager();
    const { app, user, shoppingCart } = useSelector(createSelector(
        (state: RootState) => state.app,
        (state: RootState) => state.user,
        (state: RootState) => state.shoppingCart,
        (app, user, shoppingCart) => ({
            app, user, shoppingCart
        })
    ));
    const location = useLocation();
    try {
        if (executeRecaptcha)
            executeRecaptcha(pageName);
    } catch (error) {
        console.log("Recaptcha error:", error);
    }


    const [hasFiredPageView, setHasFiredPageView] = useState<boolean>(false);
    useEffect(() => {
        if (shouldDispatchPageView({hasFiredPageView, disablePageViewReport, user, shoppingCart })) 
        {
            setHasFiredPageView(true)
            dispatchPageView({
                userState: user,
                shoppingCartState: shoppingCart,
            })
        }
    }, [location.pathname, hasFiredPageView, user.IsGuestUser, user.userHasBeenRetrieved, shoppingCart.shoppingCartRetrievalStatus])

    useEffect(() =>{
        if (user.userHasBeenRetrieved === true) {
            window.n32 = (window.n32 || {});
            if (user.IsGuestUser === true) {
                window.n32.userData = {
                    userId: null,
                    userEmailAddress: null,
                    totalOrders: 0,
                    isDentalCustomer: false
                };
            } else {
                window.n32.userData = convertUserStateToUserDataForAnalytics(user);
            }
        }
    }, [user.userHasBeenRetrieved])

    type ShouldDispatchPageViewProps = {
        hasFiredPageView: boolean
        disablePageViewReport: boolean
        user: UserState
        shoppingCart: ShoppingCartState
    }
    /**
     * Will return a boolean indicating if the page view event should be dispatched.  
     * @param {boolean} hasFiredPageView If this event has already fired.  State variable of component
     * @param {boolean} disablePageViewReport If page view report has been disabled. Check full page props for more info
     * @param {UserState} user The user state needed to determine if it has been retrieved and what their info is
     * @param {ShoppingCartState} shoppingCart the shopping cart state, used to send a summary of the cart
     * @returns {boolean}
     */
    const shouldDispatchPageView = ({hasFiredPageView, disablePageViewReport, user, shoppingCart }: ShouldDispatchPageViewProps): boolean => {
        if (hasFiredPageView === false && disablePageViewReport === false && user.userHasBeenRetrieved === true && shoppingCart.shoppingCartRetrievalStatus === ShoppingCartRetrievalStatus.RETRIEVED)
            return true;
        else
            return false;
    }

    return (
        <div id="full-page" className={className}>
            <Helmet>
                {
                    title ?
                        <title>{title + ' | ' + verticalConstants.verticalSuppliesType.firstCharacterUpperCase + ' Supplies'}</title>
                    :
                        <title>{verticalConstants.verticalSuppliesType.firstCharacterUpperCase} Supplies | {verticalConstants.verticalName.firstCharacterUpperCase}</title>
                }
                {app.cssFiles.map(cssLink => {
                    return (<link key={cssLink} rel="stylesheet" href={cssLink} />);
                })}
                {apiKey.klaviyo !== "null" && <script type="application/javascript" async
                    src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey.klaviyo}`}>
                </script>}
            </Helmet>
            <FullPageHeaderV2 />
            {app.serverState === "Offline" && preventDisplayOfOfflinePage !== true ?
                (<SystemError />)
            :
                children}
                <div id="content-loading-spacer" />
            <FullPageFooter />
        </div>
    )
}

export type FullPageProps = {
    /** 
     * This is best specified in a <Helmet /> tag instead of as a prop, but this can be used as a backup if that doesn't work in a specific use case 
     */
    title?: string;
    className?: string;
    /**
     * A camel-notation version of the page's name, registration, productDetail, etc.
     */
    pageName: string;
    /** 
     * THis will prevent the display of the offline page fallback.  This is useful for pages with
     * lots of static content, such as the home page or about us pages.
     */
    preventDisplayOfOfflinePage?: boolean
    /**
     * Prevents fullPage from automatically using dispatchPageView on it's own.  This is useful for pages
     * like the PDP that will want to report additional info to that function.  However make sure to review
     * the useEffect and replicate it's logic if you disable this!
     */
    disablePageViewReport?: boolean
}
// =========================================================================================================

export const FullPageRoute: FunctionComponent<FullPageRouteProps> = ({ component: Component, pageName, title, className, preventDisplayOfOfflinePage, disablePageViewReport, ...rest }) => {
    return (
        <Route 
            {...rest} 
        >
            <FullPage 
                title={title}
                className={className}
                pageName={pageName}
                preventDisplayOfOfflinePage={preventDisplayOfOfflinePage}
                disablePageViewReport={disablePageViewReport}
            >
                <React.Suspense fallback={<DebouncedLoader />}>
                    {/*
                    // @ts-ignore */}
                    <Component
                        key={location.pathname} // This forces the refresh of pages like the PDP when you click on a link to another PDP.
                    />
                </React.Suspense>
            </FullPage>
        </Route>
    )
}


export type FullPageRouteProps = RouteProps & FullPageProps;