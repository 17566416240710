import React, { useState, useEffect, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { getShoppingCart } from 'store/actions/ec/shoppingCartActions';

/**
 * This component is used to watch for add to cart events that happen outside of the react application, such as
 * via an AB experiment authored by one of our partners.  This will cause the shopping cart to be updated.
 */
const AddToCartViaExperimentWatcher: FunctionComponent = () => {
    const dispatch = useDispatch();
    const [totalEvents, setTotalEvents] = useState<number>(0);
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (typeof window !== "undefined" && typeof window.n32 !== "undefined" && typeof window.n32.addToCartWatcher !== "undefined" && Array.isArray( window.n32.addToCartWatcher )) {
                if (window.n32.addToCartWatcher.length > totalEvents)
                    setTotalEvents(window.n32.addToCartWatcher.length);
            }
        }, 500);
        return () => clearInterval(interval);
    }, [totalEvents]);

    useEffect(() => {
        if (totalEvents > 0) {
            console.log("Something outside the application has added to the cart.  Retrieving cart.")
            dispatch(getShoppingCart())
        }
    }, [totalEvents])

    return (
        <></>
    )
}


export default AddToCartViaExperimentWatcher;