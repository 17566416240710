
import { AppThunk } from "store/reducer/ec/rootReducer";
import { OrderDetailState } from "store/reducer/ec/orderDetailReducer";
import { Action } from "redux";

// ====================================================================================================
// Action Types
export const ORDER_DETAIL_ADD_ORDER_TO_STORE = 'orderHistory:AddOrder';
export interface OrderDetailAddOrderAction extends Action {
    type: typeof ORDER_DETAIL_ADD_ORDER_TO_STORE,
    payload: any
}
export type OrderDetailActions = OrderDetailAddOrderAction;

// ====================================================================================================
// Actual Actions
export const getOrderDetailPage = (coId: number, callback?: (payload: WindfallRestfulResponse.ResponsePacket<OrderDetailState>) => void): AppThunk => async dispatch => {
     const response = await fetch("/rest/order/orderDetail?manifestDigestSw=true&coId=" + coId, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        redirect: "follow", // manual, *follow, error
    });

    if (response.ok === true) {
        const payload = await response.json() as WindfallRestfulResponse.ResponsePacket<OrderDetailState>;

        dispatch(orderDetailAddOrderToStoreAction(payload.Payload));
        
        if (callback) {
            callback(payload);
        }
    }
    else {
        console.error("getOrderDetailPage has failed")
    }
}

export function orderDetailAddOrderToStoreAction(order: any): OrderDetailAddOrderAction{
    return {
        type: ORDER_DETAIL_ADD_ORDER_TO_STORE,
        payload: order
    }
}