/**
 * When the backend sends URIs, it often does not have a / on the front.
 * This function will fix that URI by a leading / if needed.
 *
 * Will return the original uri if it is found to be an absolute uri.
 * @param {string} uri
 * @returns {string} A fixed URI for the image
 */
export default function fixURI(uri: string) {
  if (uri.indexOf("http") === 0) return uri;
  if (uri.indexOf("/") !== 0) uri = "/" + uri;
  return uri;
}
