import React, { useState, useEffect, FunctionComponent } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import Preloader from './common/preloader';

const DebouncedLoader: FunctionComponent = ({ ...rest }) => {
    const [showPreloader, setShowPreloader] = useState(false);

    const debounceShowPreloader = useDebouncedCallback(
        () => { setShowPreloader(true); },
        1000
    );
    useEffect(() => {
        debounceShowPreloader();
    }, []);
    if (showPreloader)
        return (<section id="debounced-loader"><Preloader textUnderAnimation="Loading" /></section>)
    return <section id="debounced-loader"></section>;
}

export default DebouncedLoader;