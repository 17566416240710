import React, { FunctionComponent } from 'react';
import verticalConstants from './verticalConstants';

/** @deprecated Net32 is now the only vertical and so this component just passes on it's children. */
const IsVertical: FunctionComponent<{ verticalName: string }> = ({ verticalName, children, ...rest }) => {
    if (verticalConstants.verticalName.lowerCase === verticalName)
        return (<>{children}</>);
    else
        return null;
}
/** @deprecated Net32 is now the only vertical and so this component just passes on it's children. */
export const IsNet32: FunctionComponent = ({ children, ...rest }) => {
    return (
        <IsVertical verticalName={"net32"}>
            {children}
        </IsVertical>
    )
}
/** @deprecated Med32 is no longer a functional vertical */
export const IsMed32: FunctionComponent = ({ children, ...rest }) => {
    return (
        <IsVertical verticalName={"med32"}>
            {children}
        </IsVertical>
    )
}