const checkAddToCart = (quantity: number, minimumQuantityPurchasable: number, inventory: number): AddToCartStatus => {
    if (quantity < minimumQuantityPurchasable) {
        return AddToCartStatus.MINIMUM_QUANTITY;
    }

    if (quantity > inventory) {
        return AddToCartStatus.INSUFFICIENT_IVENTORY;
    }
    
    return AddToCartStatus.OK;
}

export default checkAddToCart;

export enum AddToCartStatus {
    OK = "OK",
    MINIMUM_QUANTITY = "MINIMUM_QUANTITY",
    INSUFFICIENT_IVENTORY = "INSUFFICIENT_IVENTORY",
}