import React, { useState, useEffect, FunctionComponent } from 'react';

import verticalConstants from 'components/verticals/verticalConstants';
import { IncomingBannerInfo } from 'components/ec/homepage/banners/banners';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from 'store/reducer/ec/rootReducer';

import "./scopedSearch.scss";
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';


const ScopedSearch: FunctionComponent<ScopedSearchProps> = ({ selectedScopedSearch, isScopedSearchOpened, scopedSearchOptions, setSelectedScopedSearchCallBack, setScopedSearchOpenedCallback, setScopedSearchOptionsCallback, ...rest }) => {

    const { topCategories } = useSelector(createSelector(
        (state: RootState) => state.categories.topCategories,
        (topCategories) => ({
            topCategories
        })
    ))
    
    const [homeBannersInfo, setHomeBannersInfo] = useState<IncomingBannerInfo[]>([]);

	const location = useLocation();

    const getHomeBanners = async () => {
        try {
            const cacheBuster = dayjs().format('YYYYMMDDHH');
            const { data } = await axios.get<IncomingBannerInfo[]>(`/media/dental/common/banners/banners.json?cacheBuster=${cacheBuster}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            })

			if (data) {
				setHomeBannersInfo(data);
			}
		} 
		catch (error) {
            console.error("Banners | Could not retrieve banner data.", error);
            console.error("Banners | Possible reasons for above error:");
            console.error("Banners |    1. Is there a trailing comma?");
            console.error("Banners |    2. Was the file renamed slightly?");
            console.error("Banners |    3. Are there comments left from the example file?");
            console.error("Banners | If none of the above fixes the issue, try running the JSON through a json linter and fix any errors it reports.");
        }
    }

    useEffect(() => {
        if (verticalConstants.verticalName.lowerCase === "net32") {
			getHomeBanners();
		}
		else {
			configureScopedSearch();
		}
	}, [location.pathname]);

	useEffect(() => {
        if (verticalConstants.verticalName.lowerCase === "net32") {
			if (homeBannersInfo?.length > 0 && topCategories?.length > 0) {
				configureScopedSearch();
			}
		}
		else {
			configureScopedSearch();
		}
	}, [topCategories, homeBannersInfo]);

    const scopedSearchNameMarkup = "d-none d-sm-inline";

    const getInitialScopedSearchOptions = (): ScopedSearchOption[] => {
        const options: ScopedSearchOption[] = [
			{
				name: "All products", 
				nameWithMarkup: <>All<span className={scopedSearchNameMarkup}> products</span></>,
				url:"/search", 
				type: "fixed"
			},
			{
				name: "Weekly specials", 
				nameWithMarkup: <>Weekly<span className={scopedSearchNameMarkup}> specials</span></>,
				url:"/weekly-specials", 
				type: "fixed"
			}
		];

		return options;
	}

    const configureScopedSearch = (): void => {
		try {
			const options: ScopedSearchOption[] = getInitialScopedSearchOptions();

		const buyGetPath:string | undefined = homeBannersInfo.map(banner => new URL(banner.href, window.location.origin).pathname)
			.find(path => path.includes("/promo/buy-get/"));

		if (buyGetPath) {
			options.push({
				name: "3M Promotions", 
				nameWithMarkup: <>3M<span className={scopedSearchNameMarkup}> Promotions</span></>,
				url: buyGetPath, 
				type: "fixed"
			});
		}

		topCategories.forEach(category => {
			let node: string | React.ReactNode;

			const spaceIndexOf:number = category.CatName.indexOf(" ");

			if (spaceIndexOf >= 0) {
				node = <>{category.CatName.substring(0, spaceIndexOf)}<span className={scopedSearchNameMarkup}>{category.CatName.substring(spaceIndexOf, category.CatName.length)}</span></>;
			}
			else {
				node = category.CatName;
			}
			
			options.push({
				name: category.CatName,
				nameWithMarkup: node,
				url: "/" + category.url, 
				type: "category"
			});
		});

		setScopedSearchOptionsCallback(options);

        const currentPageScopedSearchOption:ScopedSearchOption | undefined | undefined = options.find(path => window.location.pathname.includes(path.url));
		if (currentPageScopedSearchOption) {
			setSelectedScopedSearchCallBack(currentPageScopedSearchOption);
		}
        else {
            setSelectedScopedSearchCallBack(options[0]);
        }
		} catch (error) {
			console.error(error);
		}
        
    }

    const getSelectedScopedSearch = (): ScopedSearchOption => {
        return selectedScopedSearch ? selectedScopedSearch : getInitialScopedSearchOptions()[0];
    }

    return (
        <div id="scoped-search-drop-down" className="scoped-search-drop-down" 
            tabIndex={-1}
            onClick={() => {
                setScopedSearchOpenedCallback(!isScopedSearchOpened); 
            }}>
            <span className="scoped-search-drop-down-selected">{getSelectedScopedSearch().nameWithMarkup}</span>
        </div>
    )
}

export type ScopedSearchProps = {
    selectedScopedSearch: ScopedSearchOption | undefined;
    isScopedSearchOpened: boolean;
    scopedSearchOptions: ScopedSearchOption[]
    setSelectedScopedSearchCallBack: (selectedScopedSearchOption: ScopedSearchOption) => void;
    setScopedSearchOpenedCallback: (isScopedSearchOpened: boolean) => void;
    setScopedSearchOptionsCallback: (scopedSearchOptions: ScopedSearchOption[]) => void;
}

export default ScopedSearch;

export type ScopedSearchOption = {
    name: string;
	nameWithMarkup: string | React.ReactNode
	url: string;
	type: "fixed" | "category";
}