/**
 * When the backend sends images, it often does not have /media on the front and may not even have a /. This
 * function will fix that URI, adding /media and/or a leading /.
 * 
 * Will return the original url if it is found to be an absolute url.
 * @param {string} uri 
 * @returns {string} A fixed URI for the image
 */
export default function fixImageURI(uri: string) {
    if (uri.indexOf('http') === 0)
        return uri;
    if (uri.indexOf('/') !== 0)
        uri = '/' + uri;
    if (uri.indexOf('/media') != 0)
            uri = '/media' + uri;
    return uri;
}