import { ShoppingCartActionTypes, REPORT_ERROR, CLOSE_REPORTED_ERROR, CREATE_SAVE_FOR_LATER_PRODUCT, moveSaveForLaterProductToCartOnServer, SET_SHOPPING_CART, SET_ADDED_TO_AUTO_ORDER, SET_FREE_SHIPPING_SUGGESTION_PRODUCT, DELETE_FREE_SHIPPING_SUGGESTION_PRODUCT, CREATE_REORDER_PRODUCT, UPDATE_REORDER_PRODUCT, REFRESH_REORDER_PRODUCT_QUANTITY_PRICE, GET_OPTIMAL_CART_SAVINGS, SAVE_SHOPPING_CART, SET_CART_RETRIEVAL_STATUS, ADD_SHOPPING_CART_SMART_MESSAGE, RevertProduct, REMOVE_SHOPPING_CART_SMART_MESSAGE, CLOSE_CART_REPLACED_NOT_IN_STOCK_MESSAGE, HIDE_SIDE_SHOPPING_CART, SHOW_SIDE_SHOPPING_CART} from 'store/actions/ec/shoppingCartActions';


import { ShoppingCartVendorOrder, 
    ShoppingCartPayloadFromServer } from './shopping-cart/shoppingCartTypes';
import { FreeShippingProductSuggestion } from './shopping-cart/freeShippingSuggestionTypes';
import { SaveForLaterProduct } from './shopping-cart/saveForLaterProductTypes';
import { FunctionComponent } from 'react';
import moment from 'moment';
import { ShoppingCartReorderProduct } from './shopping-cart/reorderProductTypes';
import dayjs from 'dayjs';
import { ConsolidatedCartSavings } from './shopping-cart/consolidationCartTypes';

// TODO - Convert to RetrievalStatus enum
export enum ShoppingCartRetrievalStatus {
    NOT_RETRIEVED = "NOT_RETRIEVED",
    RETRIEVING = "RETRIEVING",
    RETRIEVED = "RETRIEVED"
}

const initialState: ShoppingCartState = {
    shoppingCartRetrievalStatus: ShoppingCartRetrievalStatus.NOT_RETRIEVED,
    subTotal: 0,
    standardShipping: 0,
    heavyShipping: 0,
    estimatedTax: 0,
    grandTotal: 0,
    retailTotal: 0,
    savingsOverRetail: 0,
    totalQuantity: 0,
    isMerged: false,
    vendorOrders: [],
    saveForLaterProducts: [],
    reorderProducts: [],
    freeShippingSuggestions: [],
    consolidatedCartSavings: null,
    savedShoppingCartProducts: null,
    shoppingCartErrors: [],
    replacedNotInStockWithConsolidation: false,
    shoppingCartHash: "",
    shoppingCartSmartMessages: [],
    hasShoppingCartBeenRetrieved: false,
    showSideShoppingCart: false,
}
export default function (state: ShoppingCartState = initialState, action: ShoppingCartActionTypes): ShoppingCartState {
    switch (action.type) {
        case CREATE_SAVE_FOR_LATER_PRODUCT:
            return updateSaveForLater(state, action.payload);
        case SET_SHOPPING_CART: 
            return { 
                ...setShoppingCart(state, action.payload),
                hasShoppingCartBeenRetrieved: true
            }
        case CLOSE_CART_REPLACED_NOT_IN_STOCK_MESSAGE: 
            return { 
                ...state,
                replacedNotInStockWithConsolidation: false
            }
        case SET_CART_RETRIEVAL_STATUS:
            return {
                ...state,
                shoppingCartRetrievalStatus: action.payload
            }
        case SET_ADDED_TO_AUTO_ORDER:
            return {
                ...state,
                vendorOrders: state.vendorOrders.map((vendorOrder) => {
                    vendorOrder.products.map((product) => {
                        if (product.mpId === action.payload.mpId)
                        {
                            product.isOnAutoOrder = true;
                            product.autoOrderFrequency = action.payload.autoOrderFrequency;
                            const nextAutoOrderDate = moment(action.payload.nextAutoOrderDate).format('MM/DD/YY');
                            product.nextAutoOrderDate = nextAutoOrderDate;
                        }
                        return product;
                    })
                    return vendorOrder;
                })
            };
        case SET_FREE_SHIPPING_SUGGESTION_PRODUCT:
            return setFreeShippingSuggestionProduct(state, action.payload)
        case DELETE_FREE_SHIPPING_SUGGESTION_PRODUCT:
            return deleteFreeShippingSuggestionProduct(state, action.payload);
        case GET_OPTIMAL_CART_SAVINGS:
            return getOptimalCartSavings(state, action.payload);
        case SAVE_SHOPPING_CART:
            return saveShoppingCartForRevert(state, action.payload);
        case CREATE_REORDER_PRODUCT: {
            return {
                ...state,
                reorderProducts: action.payload
            };
        }
        case UPDATE_REORDER_PRODUCT:
            /* const newState = {...state};
            action.payload.forEach(product => {

            })
            return newState; */
            return {
                ...state,
                reorderProducts: state.reorderProducts.map(reorderProduct => {
                    const updatedProduct = action.payload.find(p => reorderProduct.mpId == p.mpId)
                    if (updatedProduct) {
                        return {
                            ...reorderProduct,
                            ...updatedProduct
                        }
                    }
                    return reorderProduct;
                })
            }

        case REFRESH_REORDER_PRODUCT_QUANTITY_PRICE:
            
            return {
                ...state,
                
                reorderProducts: state.reorderProducts.map(reorderProduct => {
                    if (reorderProduct.mpId === action.payload.mpId) {
                        return {
                            ...reorderProduct,
                            vendorProductId: action.payload.vendorProductId,
                            unitPrice: action.payload.unitPrice,
                            inventory: action.payload.inventory,
                            minQty: action.payload.minQty,
                        }
                    }

                    return reorderProduct;
                })
            }
        
        case REPORT_ERROR: {
            const shoppingCartErrors = [...state.shoppingCartErrors];
            shoppingCartErrors.push({
                id: shoppingCartErrors.length + 1,
                errorMessage: action.payload.errorMessage,
                visible: true,
            })
            return {
                ...state,
                shoppingCartErrors: shoppingCartErrors
            }
        }
        case CLOSE_REPORTED_ERROR:
            return {
                ...state,
                shoppingCartErrors: state.shoppingCartErrors.map((error) => {
                    if (error.id == action.payload.id)
                        error.visible = false;
                    return error;
                })
            }
        case ADD_SHOPPING_CART_SMART_MESSAGE:
            return {
                ...state,
                shoppingCartSmartMessages: [...state.shoppingCartSmartMessages, {
                    key: action.payload.identifier,
                    value: action.payload.message
                }]
            }
        case REMOVE_SHOPPING_CART_SMART_MESSAGE:
            return {
                ...state,
                shoppingCartSmartMessages: state.shoppingCartSmartMessages.filter((message) => !(message.key === action.payload.identifier))
            }
        case SHOW_SIDE_SHOPPING_CART:
            return {
                ...state,
                showSideShoppingCart: true
            }
        case HIDE_SIDE_SHOPPING_CART:
            return {
                ...state,
                showSideShoppingCart: false
            }
        default:
            return state;
    }
}

function updateSaveForLater(state: ShoppingCartState, saveForLaterProducts: SaveForLaterProduct[]): ShoppingCartState {
    return {
		...state,
       saveForLaterProducts: saveForLaterProducts
		
    }
}

function setShoppingCart(state: ShoppingCartState, shoppingCart: ShoppingCartPayloadFromServer): ShoppingCartState {
    const shoppingCartProducts = shoppingCart.vendorOrders.flatMap(vo => {
        return vo.products
    });
    return {
		...state,
        subTotal: shoppingCart.subTotal,
        totalQuantity: shoppingCartProducts.filter(p => !p.noLongerForSale).map(p => p.quantity).reduce((previousValue, currentValue) => {return previousValue + currentValue}, 0),
        isMerged: shoppingCart.isMerged,
		standardShipping: shoppingCart.standardShipping,
		heavyShipping: shoppingCart.heavyShipping,
		retailTotal: shoppingCart.retailTotal,
        savingsOverRetail: shoppingCart.savingsOverRetail,
		grandTotal: shoppingCart.subTotal + shoppingCart.standardShipping + shoppingCart.heavyShipping,
        replacedNotInStockWithConsolidation: shoppingCart.replacedNotInStockWithConsolidation === true ? true : state.replacedNotInStockWithConsolidation,

        shoppingCartHash: shoppingCartProducts.map(p => {return p.mpId.toString() + p.vendorProductId.toString() + p.quantity.toString() + p.totalInventory.toString()}).join(''),
        vendorOrders: shoppingCart.vendorOrders.map(vendorOrder => {
            return {
                ...vendorOrder,
                products: vendorOrder.products.map(product => {
                    const returnableProduct = { ...product };
                    if (returnableProduct.nextAutoOrderDate) {
                        returnableProduct.nextAutoOrderDate = dayjs(returnableProduct.nextAutoOrderDate).format("MM/DD/YYYY")
                    }
                    return returnableProduct
                })
            }
        }),
    }
}

function setFreeShippingSuggestionProduct(state: ShoppingCartState, freeShippingSuggestionProducts: FreeShippingProductSuggestion[]): ShoppingCartState {
    return {
		...state,
        freeShippingSuggestions: freeShippingSuggestionProducts
	}
}

function deleteFreeShippingSuggestionProduct(state: ShoppingCartState, freeShippingSuggestionProduct: FreeShippingProductSuggestion): ShoppingCartState {
    const freeShippingSuggestionsCopy  = Object.assign([], state.freeShippingSuggestions.filter(f => f.manufacturerProductId != freeShippingSuggestionProduct.manufacturerProductId));
    return {
        ...state,
        freeShippingSuggestions: freeShippingSuggestionsCopy
    }
}
function getOptimalCartSavings(state: ShoppingCartState, consolidatedCartSavings : ConsolidatedCartSavings | null): ShoppingCartState {
    return {
        ...state,
        consolidatedCartSavings: consolidatedCartSavings
    }
}

function saveShoppingCartForRevert(state: ShoppingCartState, savedShoppingCartProducts : Array<RevertProduct> ): ShoppingCartState {
    return {
        ...state,
        savedShoppingCartProducts: savedShoppingCartProducts
    }
}

export interface ShoppingCartState {
    shoppingCartRetrievalStatus: ShoppingCartRetrievalStatus
    totalQuantity: number
    subTotal: number
    standardShipping: number;
    heavyShipping: number;
    estimatedTax: number;
    grandTotal: number;
    retailTotal: number;
    savingsOverRetail: number;
    isMerged: boolean;
    vendorOrders: Array<ShoppingCartVendorOrder>
    saveForLaterProducts: Array<SaveForLaterProduct>
    reorderProducts: Array<ShoppingCartReorderProduct>
    freeShippingSuggestions: Array<FreeShippingProductSuggestion>
    consolidatedCartSavings: ConsolidatedCartSavings | null
    replacedNotInStockWithConsolidation: boolean
    savedShoppingCartProducts: Array<RevertProduct> | null
    shoppingCartErrors: Array<{
        id: number,
        errorMessage: string | FunctionComponent,
        visible: boolean,
    }>
    /** A hash of all of the mpids + vpids + quantity of items in the cart. */
    shoppingCartHash: string
    shoppingCartSmartMessages: Array<{key: string, value: string}>
    hasShoppingCartBeenRetrieved: boolean;
    showSideShoppingCart: boolean;
}

/* export interface ShoppingCartSummaryProduct {
    VendorName: string
    RetailPrice: number
    VpId: number
    MpId: number
    Quantity: number
    Price: number
    Title: string
    DetailLink: string
    SmallPhotoPath: string
    MinQty: number
    AbsoluteMinQty: number
    DollarsTillFreeShipping: number;
    FreeShippingThreshold: number;
    CategoryName: string;
}
 */