import * as React from 'react';
import { Component } from 'react';

/**
 * @deprecated Used verticalConstants instead.
 */
export default class VerticalSuppliesType extends Component {
    props: VerticalSuppliesProps
    constructor(props: VerticalSuppliesProps) {
        super(props);
    }
    render() {
        let verticalSuppliesType = 'dental';
        if (this.props.upperCaseFirstChar) {
            verticalSuppliesType = verticalSuppliesType.charAt(0).toUpperCase() + verticalSuppliesType.slice(1);
        }
        if (this.props.allUpperCase) {
            verticalSuppliesType = verticalSuppliesType.toUpperCase();
        }
        return (
            <React.Fragment>
                {verticalSuppliesType}
            </React.Fragment>
        )
    }
}

export interface VerticalSuppliesProps {
    upperCaseFirstChar?: boolean;
    allUpperCase?: boolean;
}