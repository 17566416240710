import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "store/reducer/ec/rootReducer";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

const composeEnhancers = composeWithDevTools({});
const allStoreEnhancers = compose(
    composeEnhancers(applyMiddleware(thunk))
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default createStore(rootReducer, allStoreEnhancers);