import axios from 'axios';
import verticalConstants from 'components/verticals/verticalConstants';
import React, { useState, useEffect, FunctionComponent } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import "./chooseShippingAddressModal.scss"


import { useDispatch } from 'react-redux';
import { setLoggedInUserPrimaryShippingCityZipCookie, setShippingAddressAsPrimary } from 'store/actions/userActions';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from 'store/reducer/ec/rootReducer';
import { setAnonymousUserLocationCityZipCookie } from 'components/ec/catalog/product-detail/ipGeolocation';


type Props = {
    showModal: boolean;
    showManualZipCode?: boolean;
    chooseShippingAddressModalCallBack: (refresh: boolean) => void
}

const ChooseShippingAddressModal: FunctionComponent<Props> = ({ showModal, showManualZipCode, chooseShippingAddressModalCallBack, ...rest }) => {
    const dispatch = useDispatch();

    const [shippingAddresses, setShippingAddresses] = useState<Account.Addresses.Address[]>();

    const [zipCodeDetails, setZipCodeDetails] = useState<ShippingPostalCdDetails | null>(null);
    const [zipCode, setZipCode] = useState<string>("");
    const [isValidZipCode, setIsValidZipCode] = useState<boolean>(false);
    const [isZipCodeSubmitted, setIsZipCodeSubmitted] = useState<boolean>(false);

    const { user } = useSelector(createSelector(
        (state: RootState) => state.user,
        (user) => ({
            user
        })
    ));

    const isLoggedUser = user.userHasBeenRetrieved === true && user.IsGuestUser === false;

    const isZipCodeEmpty = zipCode.trim() === "";

    useEffect(() => {
        if (user.userHasBeenRetrieved === true && user.IsGuestUser === false) {
            axios.get<Account.Addresses.Address[]>('/rest/neo/user/getShippingAddressesForUser')
            .then(response => {
                if (response.status === 200) {
                    setShippingAddresses(response.data);
                }
                else {
                    console.error("Error when requesting user shipping addresses " + response.status)
                }
            })
            .catch(error => {
                console.error("Error when requesting user shipping addresses", error);
            });
        }
    }, [user.userHasBeenRetrieved]);
    
    const shippingAddressOnChange = (shippingAddressId: string) => {
        const changedShippingAddresses = shippingAddresses?.map(s => {
            return {
                ...s,
                IsPrimary: s.Id === shippingAddressId ? true : false
            }
        });

        setShippingAddresses(changedShippingAddresses);
    }

    const getZipCodeDetails = () => {
        axios.get<ShippingPostalCdDetails>(`/rest/neo/shipping/${zipCode}/get-postal-code-details`)
        .then(response => {
            if (response.status === 200) {
                setIsZipCodeSubmitted(true);

                if (response.data.isValid) {
                    setIsValidZipCode(true);
                    setZipCodeDetails(response.data);
                    setZipCode(response.data.postalCode);
                }
                else {
                    setIsValidZipCode(false);
                }
            }
            else {
                console.error("Error when getting zip code informations " + response);
            }
        })
        .catch(error => {
            console.error("Error when getting zip code information", error);
        });
    }

    async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        
        if (isZipCodeSubmitted) {
            if (isValidZipCode && zipCodeDetails) {
                if (isLoggedUser) {
                    setLoggedInUserPrimaryShippingCityZipCookie(zipCodeDetails.postalCode, zipCodeDetails.city);
                }
                else {
                    setAnonymousUserLocationCityZipCookie(zipCodeDetails.postalCode, zipCodeDetails.city);
                }

                chooseShippingAddressModalCallBack(true);
            }        
        }
        else {
            if (user.userHasBeenRetrieved === true && user.IsGuestUser === false && user.isAuthorized == true) {
                const primaryShippingAddress = shippingAddresses?.find(s => s.IsPrimary === true);
    
                if (primaryShippingAddress) {
                    await dispatch(
                        setShippingAddressAsPrimary(primaryShippingAddress.Id, primaryShippingAddress.PostalCD, primaryShippingAddress.City)
                    )
    
                    chooseShippingAddressModalCallBack(true);
                }        
            }
            else {
                console.error("Currently logged out!  Redirecting to login");
                window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
            }
        }
    }


    return (
        <Modal
            id="choose-shipping-address-modal"
            show={showModal}
            onHide={() => {
                chooseShippingAddressModalCallBack(false);
            }}
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body>
                
                <section className="choose-delivery-address-header">
                    <div className="choose-delivery-address-title">Choose your delivery address</div>
                    <div className="choose-delivery-address-subtitle">Select your delivery location for more accurate shipping times and price estimates when shopping on Net32.</div>
                </section>

                <Form className="shipping-addresses-form" onSubmit={onSubmit}>
                    {isLoggedUser 
                        ?
                        <>
                            <Form.Group>
                                {shippingAddresses?.map((shippingAddress, index) => {
                                    return (
                                        <Form.Check
                                            key={shippingAddress.Id}
                                            type="radio"
                                            id={`shipping-address=${index}`}
                                            name="shipping-address"
                                            className="pb-2"
                                            checked={shippingAddress.IsPrimary}
                                            onChange={() => shippingAddressOnChange(shippingAddress.Id)}
                                            label={
                                                <div>
                                                    <div className="shipping-adress-name">{shippingAddress.Name} {shippingAddress.IsPrimary === true ? <span className="primary-shipping-address">Primary Address</span> : ""}</div>
                                                    <div className="shipping-adresss-text">{shippingAddress.Streets.map((street, index2) => <span className="shipping-adresss-street" key={street + index2}>{street}</span>)}, {shippingAddress.City}, {shippingAddress.RegionCD} {shippingAddress.PostalCD}</div>
                                                </div>
                                            }
                                        >
                                        </Form.Check>
                                    );
                                })}
                            </Form.Group>

                            <Form.Group>
                                <a className="highlighted-link" href="/account/shipping">Manage address book</a>
                            </Form.Group>
                        </>
                        :
                        <div className="form-sign-in">
                            <Button variant="secondary" size="lg" href={"/login?origin=" + encodeURIComponent(window.location.pathname)}>Sign in to see your addresses</Button>
                        </div> 
                    }

                    {showManualZipCode &&
                        <div className="form-zipcode">
                            <div className="form-zipcode-title">
                                <div className="form-zipcode-title-line" />

                                <span>or enter a US zip code</span>

                                <div className="form-zipcode-title-line" />
                            </div>

                            {(isZipCodeSubmitted && isValidZipCode)
                                ?
                                <div className="form-zipcode-selected">
                                    <span className="form-zipcode-info">Deliver to <b>{zipCode}</b></span>
                                    <Button variant="link" onClick={() => setIsZipCodeSubmitted(false)}>Change</Button>
                                </div>
                                :
                                <div className="form-zipcode-input">
                                    <label className="form-zipcode-input-label" htmlFor="form-zipcode-input-text">Zip code</label>
                                    <div className="form-zipcode-input-text-button">
                                        
                                        <input 
                                            id="choose-shipping-address-zipcode-input" 
                                            name="choose-shipping-address-zipcode-input"
                                            value={zipCode}
                                            type="number"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setZipCode(event.target.value)}
                                            height="48"
                                        />
                                        <Button className="form-zipcode-input-button" variant="outline-secondary" size="lg" disabled={isZipCodeEmpty} onClick={() => getZipCodeDetails()}>Apply</Button>
                                    </div>
                                    {isZipCodeSubmitted && !isValidZipCode &&
                                        <div className="form-zipcode-input-error mt-1">Zip code not found. Please try another zip code.</div>
                                    }
                                </div>
                            }

                        </div>
                    }

                    <div className="form-action">
                        <Button type="submit" variant="secondary" size="lg">
                            Done
                        </Button>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    )
}

export type ShippingPostalCdDetails = {
    postalCode : string;
    city: string;
    shippingSubRegionId: number;
    isValid : boolean;
    state: string;
    subRegion: string;
}

export default ChooseShippingAddressModal;