import React, { useState, useEffect, FunctionComponent } from 'react';
import "./algoliaSearch.scss"
import { InstantSearch } from 'react-instantsearch';
import { searchClient, defaultIndex } from './constants';
import AlgoliaSearchField from './algoliaSearchField';

const AlgoliaSearch: FunctionComponent = () => {
    return (
        
            <AlgoliaSearchField />
    )
}

// export type AlgoliaSearchProps = {
    
// };

export default AlgoliaSearch;