import featuresEnabled from "components/verticals/featuresEnabled";
import { IsMed32, IsNet32 } from "components/verticals/isVertical";
import verticalConstants from "components/verticals/verticalConstants";
import RetrievalStatus from "enums/retrievalStatus";
import Cookies from "js-cookie";
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { createSelector } from "reselect";
import { logoutUser } from "store/actions/ec/appLevelActions";
import { getBuyItAgainSuggestions } from "store/actions/ec/orderHistoryActions";
import { RootState } from "store/reducer/ec/rootReducer";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Swiper as SwiperInterface,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

import "./buytItAgainSuggestions.scss";
import DeliverToShippingAddress from "./deliverToShippingAddress";
import "./headerV2.scss";
import ShoppingCartSummary from "./shoppingCartSummary";
import UserSalutations from "./userSalutation";

import axios from "axios";
import AlgoliaSearch from "./search/algolia/algoliaSearch";
import SubCategoryMainMenuContainer from "components/ec/sub-category-menu/subCategoryMainMenuContainer";
import SubCategoryMainMenuContainerMobile from "components/ec/sub-category-menu/subCategoryMainMenuContainerMobile";

const FullPageHeaderV2: FunctionComponent = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const shoudShowSwiperSideNavigationArrows = (): boolean => {
    if (swiperInstance) {
      return (
        swiperInstance.allowSlidePrev === true ||
        swiperInstance.allowSlideNext === true
      );
    }
    return false;
  };

  const { user, shoppingCart, topCategories, orderHistory } = useSelector(
    createSelector(
      (state: RootState) => state.user,
      (state: RootState) => state.shoppingCart,
      (state: RootState) => state.categories.topCategories,
      (state: RootState) => state.orderHistory,
      (user, shoppingCart, topCategories, orderHistory) => ({
        user,
        shoppingCart,
        topCategories,
        orderHistory,
      })
    )
  );
  const dispatch = useDispatch();
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showMyAccountPreview, setShowMyAccountPreview] = useState(false);
  let showMyAccountTimeout: Timeout;
  const location = useLocation();
  const currentPathForRedirect = encodeURIComponent(location.pathname);
  const loginURL = "/login?origin=" + currentPathForRedirect;
  const createAccountURL = "/create-account?origin=" + currentPathForRedirect;

  // desktop and mobile have different state variables because of the My Account Preview.
  // It was initially showing on mobile even though it shouldn't, so this was the easiest remedy.
  const [showMobileMyAccount, setShowMobileMyAccount] = useState(false);

  const [myAccountPreviewShown, setMyAccountPreviewShown] = useState(false);
  const myAccountRef = useRef(null);

  const [showTopCategories, setShowTopCategories] = useState(false);
  const topCategoriesRef = useRef(null);

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const enableAlgoliaSearch = useMemo(() => {
    if (featuresEnabled.algolia) {
      if (location.pathname === "/old/search") {
        return false;
      } else if (location.pathname.indexOf("promo/buy-get") !== -1) {
        return false;
      }
      return true;
    }
    return false;
  }, [location.pathname]);

  useEffect(() => {
    if (
      user?.IsGuestUser === false &&
      user.UserId &&
      window.aa &&
      window.n32 &&
      window.n32.devMode === false
    ) {
      try {
        window.aa("setUserToken", user.UserId);
      } catch (error) {
        console.error(`window.aa("setUserToken") has failed:`, error);
      }
    }
  }, [user]);

  let myAccountPreviewTimeout: Timeout;

  const [primaryShippingAddress, setPrimaryShippingAddress] = useState<
    Account.Addresses.Address | undefined
  >();

  // When the location changes, we need to force close all the menus in the header.  This is especially important in mobile but makes sense for the rest as well.
  useEffect(() => {
    closeMyAccountMenu();
    closeTopCategoriesPopover();
    setShowHamburgerMenu(false);
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (
      user.IsGuestUser === false &&
      orderHistory.buyItAgainRetrievalState === RetrievalStatus.NOT_RETRIEVED
    ) {
      dispatch(getBuyItAgainSuggestions());
    }
  }, [dispatch, orderHistory.buyItAgainRetrievalState, user.IsGuestUser]);

  useEffect(() => {
    const hasSeenMyAccountPreview =
      Cookies.get("n32HasSeenSigninPopup") === "true" ? true : false;

    if (
      hasSeenMyAccountPreview === false &&
      showMyAccount === false &&
      myAccountPreviewShown === false &&
      user.userHasBeenRetrieved === true &&
      user.IsGuestUser !== false
    ) {
      Cookies.set("n32HasSeenSigninPopup", "true", { expires: 1 });
      myAccountPreviewTimeout = window.setTimeout(() => {
        setShowMyAccountPreview(true);
        setMyAccountPreviewShown(true);
        setShowMyAccount(true);
      }, 1000);
    }

    if (user.userHasBeenRetrieved === true && user.IsGuestUser === false) {
      loadPrimaryShippingAddress();
    }

    return () => {
      clearTimeout(myAccountPreviewTimeout);
    };
  }, [user.userHasBeenRetrieved]);

  const loadPrimaryShippingAddress = async () => {
    try {
      const { status, data } = await axios.get<Account.Addresses.Address>(
        `/rest/neo/user/getPrimaryShippingAddress`
      );
      if (status === 200) {
        setPrimaryShippingAddress(data);
      } else {
        console.error(
          "Error when requesting user primary shipping address " + status
        );
      }
    } catch (error) {
      console.error(
        "Error when requesting user primary shipping address",
        error
      );
    }
  };

  function showMyAccountMenu() {
    clearTimeout(showMyAccountTimeout);
    showMyAccountTimeout = window.setTimeout(() => {
      setShowMyAccount(true);
      setShowMobileMyAccount(true);
      setShowMyAccountPreview(false);
      setShowHamburgerMenu(false);
      clearTimeout(myAccountPreviewTimeout);
    }, 50);
  }
  function closeMyAccountMenu() {
    clearTimeout(showMyAccountTimeout);
    showMyAccountTimeout = window.setTimeout(() => {
      setShowMyAccount(false);
      setShowMobileMyAccount(false);
      setShowMyAccountPreview(false);
    }, 100);
  }

  let topCategoriesTimeout: Timeout;
  function showTopCategoriesPopover() {
    clearTimeout(topCategoriesTimeout);
    topCategoriesTimeout = window.setTimeout(() => {
      setShowTopCategories(true);
    }, 250);
  }
  function closeTopCategoriesPopover() {
    clearTimeout(topCategoriesTimeout);
    topCategoriesTimeout = window.setTimeout(() => {
      setShowTopCategories(false);
    }, 150);
  }
  function handleHamburgerMenuClick() {
    if (showHamburgerMenu === true) {
      setShowHamburgerMenu(false);
    } else {
      setShowHamburgerMenu(true);
      closeMyAccountMenu();
    }
  }

  const myAccountMenu = (
    <React.Fragment>
      {user.IsGuestUser !== false && (
        <React.Fragment>
          <a href={loginURL} className="btn btn-secondary">
            Sign-in
          </a>
          <a href={createAccountURL} className="btn btn-outline-secondary">
            Create Account
          </a>
        </React.Fragment>
      )}
      {showMyAccountPreview === false && (
        <React.Fragment>
          <a href="/dashboard" className="menu-link">
            Dashboard
          </a>
          <a href="/reorder" className="menu-link">
            Reorder
          </a>
          {featuresEnabled.autoOrder && (
            <Link to="/account/orders/auto-order" className="menu-link">
              Auto Orders
            </Link>
          )}
          <a href="/shopping-list" className="menu-link">
            Shopping List
          </a>
          <a href="/account" className="menu-link">
            My Account
          </a>
          <Link to="/account/orders" className="menu-link">
            Orders
          </Link>
          <a href="/review/products" className="menu-link">
            Review Purchased Products
          </a>
          <a href="/review/vendor" className="menu-link">
            Review Vendor
          </a>
          {user.IsGuestUser === false && (
            <a
              href="#"
              className="menu-link"
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                event.preventDefault();
                dispatch(logoutUser());
              }}
            >
              Logout
            </a>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
  const topCategoriesList = topCategories.map((topCategory) => {
    return (
      <Button className="menu-link" key={topCategory.CatId} href={"/" + topCategory.url} variant="link">{topCategory.CatName}</Button>
    )
  })


  return (
    <header
      id="header"
      data-display-account-menu={showMobileMyAccount}
      data-display-hamburger-menu={showHamburgerMenu}
      data-vertical-name={verticalConstants.verticalName.lowerCase}
    >
      <a
        id="header-logo"
        className={`vertical-${verticalConstants.verticalName.lowerCase}`}
        href="/"
      >
        {/* <h1>Net32</h1> */}
      </a>
      <div
        id="categories"
        ref={topCategoriesRef}
        onMouseOver={showTopCategoriesPopover}
        onMouseOut={closeTopCategoriesPopover}
        className={showTopCategories ? "boost-zindex is-hovered" : ""}
      >
        <IsNet32>
          <SubCategoryMainMenuContainer show={showTopCategories} target={topCategoriesRef} />
        </IsNet32>
        <IsMed32>
          <div className="category-list-container">
            Shop by category
          </div>
          {topCategories && topCategories.length > 0 && (
            <Overlay
              show={showTopCategories}
              target={topCategoriesRef.current as any}
              placement="bottom-end"
              container={topCategoriesRef.current as any}
            >
              <Popover id="my-account-menu-popover" className="">
                <Popover.Content>
                  {topCategoriesList}
                </Popover.Content>
              </Popover>
            </Overlay>
          )}
        </IsMed32>
        <CSSTransition in={showTopCategories} timeout={50} unmountOnExit>
          <div className="header-actions-black-background disable-click-events"></div>
        </CSSTransition>
      </div>

      <AlgoliaSearch />

      <div id="my-account">
        <IsNet32>
          <DeliverToShippingAddress
            primaryShippingAddress={primaryShippingAddress}
            refreshPrimaryAddressCallBack={() => loadPrimaryShippingAddress()}
          />
        </IsNet32>

        <div
          className={
            "my-account-salutations" +
            (user.IsGuestUser == false ? " logged-in-salutations" : "")
          }
        >
          {user.IsGuestUser === false ? (
            <span className="sm">
              <UserSalutations
                prefix={user.Prefix}
                firstName={user.FirstName}
                lastName={user.LastName}
              />
            </span>
          ) : (
            <React.Fragment>
              <a href={loginURL}>Sign-in</a>
              <a href={createAccountURL}>Register</a>
            </React.Fragment>
          )}
        </div>

        <div
          id="my-account-menu-container"
          ref={myAccountRef}
          onMouseOver={showMyAccountMenu}
          onMouseOut={closeMyAccountMenu}
        >
          <a href="/account" className="lg">
            My Account
          </a>
          <Overlay
            show={showMyAccount}
            target={myAccountRef.current as any}
            placement="bottom"
            container={myAccountRef.current as any}
            transition={true}
          >
            <Popover id="my-account-menu-popover" className="">
              <Popover.Content>{myAccountMenu}</Popover.Content>
            </Popover>
          </Overlay>
        </div>
      </div>
      <div id="my-account-mobile">
        <IsNet32>
          <DeliverToShippingAddress
            primaryShippingAddress={primaryShippingAddress}
            refreshPrimaryAddressCallBack={() => loadPrimaryShippingAddress()}
          />
        </IsNet32>

        {user.IsGuestUser === false ? (
          <span id="my-account-mobile-menu-link" onClick={showMyAccountMenu}>
            <span className="sm">
              <UserSalutations
                prefix={user.Prefix}
                firstName={user.FirstName}
                lastName={user.LastName}
              />
            </span>
          </span>
        ) : (
          <div className="my-account-salutations">
            <a href={loginURL}>Sign-in</a>
            <a href={createAccountURL}>Register</a>
          </div>
        )}
      </div>
      <div id="my-account-mobile-open">
        <div id="show-mobile-search-bar" onClick={closeMyAccountMenu}></div>
        <div
          id="close-my-account-mobile-menu"
          onClick={closeMyAccountMenu}
        ></div>
      </div>
      <div id="my-account-mobile-menu">{myAccountMenu}</div>
      <ShoppingCartSummary />

      <div id="header-secondary-row">
        <div>
          <IsNet32>
            {shoudShowSwiperSideNavigationArrows() && (
              <div
                className={`swiper-arrow swiper-arrow-style swiper-arrow-prev swiper-arrow-primary`}
                ref={navigationPrevRef}
              ></div>
            )}
            <Swiper
              modules={[FreeMode, Mousewheel, Navigation]}
              spaceBetween={5}
              slidesPerView={"auto"}
              loop={false}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              watchOverflow={true}
              freeMode={{
                enabled: true,
                sticky: true,
              }}
              direction="horizontal"
              mousewheel={false}
            >
              <SwiperSlide>
                <a href="/weekly-specials" className="weekly-specials-link">
                  Weekly Specials
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/top-sellers">Top Sellers</a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/mfr-promotions">Promotions</a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/composites-including-resins-hybrids-cosmetic-l-531-2">
                  Composites including resins &amp; hybrids
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/local-anesthetic-l-536-847">Local anesthetic</a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/cement-liners-adhesives-l-533-829">Cement</a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/sterilization-pouches-infection-control-clinical-l-523-728">
                  Sterilization pouches
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/gloves-infection-control-personal-l-509-569">
                  Gloves
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/bonding-agents-cosmetic-l-531-818">
                  Bonding agents
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/vinyl-polysiloxane-impression-materials-l-524-737">
                  Vinyl polysiloxane
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/temporary-crown-bridge-materials-acrylics-reline-tray-l-538-855">
                  Temporary crown &amp; bridge materials
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/wipes-towelettes-disinfecting-infection-control-clinical-l-523-719">
                  Wipes &amp; towelettes, disinfecting
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/temporary-cement-liners-adhesives-l-533-828">
                  Temporary cement
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/mixing-material-tips-impression-l-524-747">
                  Mixing material tips
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/milling-blocks-cad-cam-l-220810-220811">
                  Milling blocks
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/bone-grafting-material-soft-tissue-surgical-l-519-542-10">
                  Bone grafting material
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/fluorides-preventives-l-515-618">Fluorides</a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/angle-cup-prophy-preventives-l-515-614-56">
                  Angle with cup
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/carbide-burs-diamonds-l-534-836">Carbide burs</a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/ec/masks-infection-control-personal-l-509-567">
                  Masks
                </a>
              </SwiperSlide>
            </Swiper>
            {shoudShowSwiperSideNavigationArrows() && (
              <div
                className={`swiper-arrow swiper-arrow-style swiper-arrow-next swiper-arrow-primary`}
                ref={navigationNextRef}
              ></div>
            )}
          </IsNet32>
          <IsMed32>
            <Swiper
              modules={[FreeMode, Mousewheel]}
              spaceBetween={0}
              slidesPerView={"auto"}
              loop={false}
              onSwiper={(swiper) => console.log("Swiper | ", swiper)}
              freeMode={{
                enabled: true,
                sticky: true,
              }}
              direction="horizontal"
              mousewheel={true}
            >
              <SwiperSlide>
                <a href="/weekly-specials" className="weekly-specials-link">
                  Weekly Specials
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href="/top-sellers">Top Sellers</a>
              </SwiperSlide>
              {topCategories.map((topCategory) => {
                return (
                  <SwiperSlide key={topCategory.CatId}>
                    <a href={"/" + topCategory.url}>{topCategory.CatName}</a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </IsMed32>
        </div>
      </div>
      <IsMed32>
        <div
          id="weekly-specials"
          className="weekly-specials-link mobile-grid-menu-item"
        >
          <a href="/weekly-specials" className="weekly-specials-link">
            Weekly Specials
          </a>
        </div>
        <div id="top-sellers" className=" mobile-grid-menu-item">
          <a href="/top-sellers">Top Sellers</a>
        </div>
      </IsMed32>
      {user.IsGuestUser !== false && (
        <div
          id="get-started"
          title="It's free. No membership."
          className="mobile-grid-menu-item"
        >
          <a href={createAccountURL}>Get started in 30 seconds</a>
        </div>
      )}

      <IsMed32>
        <div
          id="shop-by-category-mobile"
          className="mobile-full-width-menu-item"
        >
          <span>Shop by Category</span>
        </div>
      </IsMed32>

      <div id="hamburger-menu" onClick={handleHamburgerMenuClick}></div>

      <div id="mobile-category-list">
        <SubCategoryMainMenuContainerMobile />

        <div className="mobile-my-account">
          <Button variant="primary" href="/account">
            My Account
          </Button>
        </div>
      </div>
    </header>
  );
};
export default FullPageHeaderV2;
