import { VendorOptionPriceBreakState, VendorOptionFromServer } from "../productDetailPage";

/**
 * Will return the best price break for the provided quantity.  If one is not found, the lowest minQty is provided.  Make sure you account for this.
 * @param {number} quantity 
 * @param {VendorOptionPriceBreakState} priceBreaks 
 * @returns {number} pmId
 */
const getPriceBreakForQuantity = (quantity: number, priceBreaks: VendorOptionPriceBreakState[]): VendorOptionPriceBreakState => {
    // If we only have 1 price break we can just return that.
    if (priceBreaks.length === 1)
        return priceBreaks[0];
    
    // First we'll sort the list by minQty to ensure it is in the correct order.
    priceBreaks = priceBreaks.sort((pb1, pb2) => {
        return pb1.minQty - pb2.minQty;
    })
    
    // Now we'll go through each and find which price break fits our quantity the best.
    let bestPriceBreak: VendorOptionPriceBreakState = priceBreaks[0];
    
    for (const priceBreak of priceBreaks) {
        if (quantity >= priceBreak.minQty )
            bestPriceBreak = priceBreak;
    }

    return bestPriceBreak;
}

export default getPriceBreakForQuantity;