import React, { useState, useEffect, FunctionComponent, Component, } from 'react';
import { RootState } from 'store/reducer/ec/rootReducer';
const UserSalutations: FunctionComponent<Props> = ({ prefix, firstName, lastName }) => {
    let userSalutationsString = '';
    if (prefix)
    {
        userSalutationsString = `${prefix} ${lastName}`;
    }
    else 
    {
        userSalutationsString = firstName as string; // this will always exist if you get here
    }
    return (
        <>Hello, {userSalutationsString}</> 
    );
}
export default UserSalutations;

interface Props {
    prefix?: string
    firstName: string
    lastName: string
}