import { FullPage } from 'layouts/ec/full-page/fullPage';
import React, { useMemo, FunctionComponent, Component } from 'react';
import { RouteProps, useLocation, useParams } from 'react-router-dom';
import DebouncedLoader from './debouncedLoader';
import verticalConstants from './verticals/verticalConstants';



const ProductDetailPage = React.lazy(() => import('components/ec/catalog/product-detail/productDetailPage'))
const BuyGetPage = React.lazy(() => import('components/ec/catalog/buy-get/buyGetPage'))



/**
 * This component deals with urls that start with /ec/.  Product detail pages, category list, category detail,
 * and any others that might appear.
 * @returns resulting page
 */
const SpecializedBuyGetURLRouter: FunctionComponent = () => {
    
    const { manufacturerParam, url } = useParams<{ manufacturerParam: string,  url: string }>();

    const location = useLocation();

    const targetPage = useMemo((): SpecializedBuyGetURLRouteInfo => {
        if (url) {
            const productDetailPageInfo = new RegExp(/(-d-)(\d+)$/).exec(url);

            if (productDetailPageInfo !== null) {
                return {
                    type: SpecializedBuyGetUrlPageType.productDetailPage,
                    identifier: parseInt(productDetailPageInfo[productDetailPageInfo.length - 1])
                }
            }
            else {
                return {
                    type: SpecializedBuyGetUrlPageType.unknown,
                }
            }
        }
        else {
            return {
                type: SpecializedBuyGetUrlPageType.buyGetPage,
                manufacturerName: manufacturerParam
            }
        }
    }, [url]);

    if (targetPage.type === SpecializedBuyGetUrlPageType.buyGetPage) {
        return (
            <FullPage
                pageName="buyGetPage" 
                disablePageViewReport={true}
                // pageCSSLink={"/static/dist/"+ verticalConstants.verticalName.lowerCase +"/css/buyGetPage.css"}
            >
                <React.Suspense fallback={<DebouncedLoader />}>
                    <BuyGetPage
                        key={location.pathname}
                        manufacturerName={targetPage.manufacturerName}
                    />
                </React.Suspense>
            </FullPage>
        );
    }
    else if (targetPage.type === SpecializedBuyGetUrlPageType.productDetailPage) {
        return (
            <FullPage
                pageName="productDetail" 
                disablePageViewReport={true}
                // pageCSSLink={"/static/dist/"+ verticalConstants.verticalName.lowerCase +"/css/productDetailPage.css"}
            >
                <React.Suspense fallback={<DebouncedLoader />}>
                    <ProductDetailPage
                        key={location.pathname}
                        mpId={targetPage.identifier}
                        view="authorized-vendors"
                    />
                </React.Suspense>
            </FullPage>
        );
    }
    
    return (
        <FullPage pageName="unknownPage">
            <DebouncedLoader />
        </FullPage>
    );
}


export enum SpecializedBuyGetUrlPageType {
    unknown,
    buyGetPage,
    productDetailPage
}

export type SpecializedBuyGetURLRouteInfo = {
    type: SpecializedBuyGetUrlPageType.buyGetPage,
    manufacturerName: string

} | {
    type: SpecializedBuyGetUrlPageType.productDetailPage,
    identifier: number
} | {
    type: SpecializedBuyGetUrlPageType.unknown
}

export default SpecializedBuyGetURLRouter;