import CurrencyDisplay from "components/common/currencyDisplay";
import ProductImage from "components/common/productImage";
import { Hit } from "instantsearch.js/es/types";
import React from "react";
import fixURI from "utilities/fixURI";

type SuggestedProductItemProps = {
  hit: ProductHit;
};

const SuggestedProductItem = ({ hit }: SuggestedProductItemProps) => {
  return (
    <a
      key={hit.mp_id}
      href={`${fixURI(hit.link)}${hit.__autocomplete_queryID ? `?queryID=${hit.__autocomplete_queryID}` : ""}`}
      className={`suggestedProduct d-flex text-decoration-none mb-3`}
    >
      <div className={`image pr-2`}>
        <ProductImage src={hit.image_link} alt={hit.title} width={80} height={80} />
      </div>
      <div className="px-2">
        <div className={"title"}>{hit.title}</div>
        <div className={`price`}>
          <CurrencyDisplay value={hit.price} />
        </div>
      </div>
    </a>
  );
};

export default SuggestedProductItem;

/**
 * TODO Trim this to what we actaully send now
 */
export interface ProductHit extends Hit {
  description_short: string;
  title: string;
  mp_id: number;
  image_link: string;
  brand: string;
  attributes: { packaging: string };
  retail_price: number;
  price: number;
  manufacturer: string;
  general_rating?: number | undefined;
  number_of_general_ratings?: number;
  category: { [key: string]: string };
  link: string;
  inventory_quantity: number;
}