import verticalConstants from 'components/verticals/verticalConstants';
import React, { FunctionComponent } from 'react';

const SystemError: FunctionComponent = ({ ...rest }) => {
    return (
        <div id="server-offline-or-in-error-state">
            <div>
                <h1>We'll be right back</h1>
                <span>
                    {verticalConstants.verticalName.firstCharacterUpperCase} is busy updating <br />
                    all {verticalConstants.verticalSuppliesType.lowerCase} products for you<br />
                    and will be back soon.<br />
                    Thank you for your patience.
                </span>
            </div>
        </div>
    )
}

export default SystemError;