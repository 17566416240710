import React from "react";
import { useEffect, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

import input from "./data/subCategory-options.json";

import "./subCategoryMenu.scss"

export default function SubCategoryMainMenuContainer({
  show,
  target,
}: {
  show: boolean;
  target: React.MutableRefObject<null>;
}) {
  const [data, setData] = useState<SubCategoryMainMenuDataItem[]>();
  useEffect(() => {
    const loadedData = JSON.stringify(input);
    const json = JSON.parse(loadedData);
    setData(json);
  }, []);

  return (
    <>
      <button className={"menu-button"}>Shop by category </button>
      <Overlay show={show} target={target} placement="bottom-end">
        <Popover id="category-menu-popover">
          <div className={"menu-wrapper"}>
            <div className={"menu-content"}>
              {data?.map((category, index) => {
                const categoryName = category.name;
                return (
                  <div key={index} className={"main-link"}>
                    <div className={"main-link-title"}>{categoryName}</div>
                    <div className={"submenu"}>
                      <h3>{categoryName}</h3>
                      <div className={"submenu-content"}>
                        {category?.links?.map((subCategory, index) => {
                          return (
                            <a key={index} href={subCategory?.href}>
                              {subCategory?.name}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Popover>
      </Overlay>
    </>
  );
}

export interface SubCategoryMainMenuDataItem {
  name: string;
  links: Array<SubCategorySubMenuItem>;
}

export interface SubCategorySubMenuItem {
  name: string;
  href: string;
}
