import { FullPage } from 'layouts/ec/full-page/fullPage';
import React, { useMemo, FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DebouncedLoader from './debouncedLoader';

const ProductDetailPage = React.lazy(() => import('components/ec/catalog/product-detail/productDetailPage'))
const CategoryListPage = React.lazy(() => import('components/ec/catalog/categories/categoryListPage'))
const CategoryDetailPage = React.lazy(() => import('components/ec/catalog/categories/detail/categoryDetailPage'))

/**
 * This component deals with urls that start with /ec/.  Product detail pages, category list, category detail,
 * and any others that might appear.
 * @returns resulting page
 */
const SpecializedURLRouter: FunctionComponent = () => {
    const { url } = useParams<{ url: string }>();
    const location = useLocation();

    const targetPage = useMemo((): SpecializedURLRouteInfo => {
        if (location.pathname.includes("/product-detail")) {
            return {
                type: SpecializedUrlPageType.productDetailPage,
                identifier: parseInt(url)
            }
        }

        // SCC: forcing any react-router links to a full refresh so that the proxy gets hit and we redirect to Next
        if (!location.pathname.includes('/old')) {
            const url = new URL(window.location.href, window.location.origin);
            window.location.href = url.toString();
        }

        const productDetailPageInfo = new RegExp(/(-d-)(\d+)$/).exec(url);

        if (productDetailPageInfo !== null) {
            return {
                type: SpecializedUrlPageType.productDetailPage,
                identifier: parseInt(productDetailPageInfo[productDetailPageInfo.length - 1])
            }
        }

        const categoryDetailInfo = new RegExp(/(-l-|-t-)(\d+-)*?(\d+)$/).exec(url);

        if (categoryDetailInfo !== null) {
            return {
                type: SpecializedUrlPageType.categoryDetailPage,
                identifier: parseInt(categoryDetailInfo[categoryDetailInfo.length - 1])
            }
        }
        const categoryListInfo = new RegExp(/^(dental|medical)-supplies$/).exec(url)

        if (categoryListInfo !== null) {
            return {
                type: SpecializedUrlPageType.categoryListPage
            }
        }
        return {
            type: SpecializedUrlPageType.unknown,
        }
    }, [url])

    if (targetPage.type === SpecializedUrlPageType.productDetailPage) {
        return (
            <FullPage
                pageName="productDetail"
                disablePageViewReport={true}
            >
                <React.Suspense fallback={<DebouncedLoader />}>
                    <ProductDetailPage
                        mpId={targetPage.identifier}
                    />
                </React.Suspense>
            </FullPage>
        )
    }
    else if (targetPage.type === SpecializedUrlPageType.categoryDetailPage) {
        return (
            <FullPage
                pageName="categoryDetail"
            // disablePageViewReport={true}
            >
                <React.Suspense fallback={<DebouncedLoader />}>
                    <CategoryDetailPage
                        catId={targetPage.identifier}
                    />
                </React.Suspense>
            </FullPage>
        )
    }
    else if (targetPage.type === SpecializedUrlPageType.categoryListPage) {
        return (
            <FullPage
                pageName="categoryList"
            >
                <React.Suspense fallback={<DebouncedLoader />}>
                    <CategoryListPage
                    />
                </React.Suspense>
            </FullPage>
        )
    }
    return (
        <FullPage pageName="unknownPage">
            <DebouncedLoader />
        </FullPage>
    );
}


export enum SpecializedUrlPageType {
    unknown,
    productDetailPage,
    categoryDetailPage,
    categoryListPage
}

export type SpecializedURLRouteInfo = {
    type: SpecializedUrlPageType.productDetailPage | SpecializedUrlPageType.categoryDetailPage,
    identifier: number
} | {
    type: SpecializedUrlPageType.unknown | SpecializedUrlPageType.categoryListPage,
    identifier?: undefined
}

export default SpecializedURLRouter;