import React, { useState, useEffect, FunctionComponent } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import AutoOrderDescription from './autoOrderDescription';
// import "./autoOrderPopover.scss"

type AutoOrderPopoverProps = {
    forceShow?: boolean
    setForceShow?: (a: boolean) => void
}

const AutoOrderPopover: FunctionComponent<AutoOrderPopoverProps> = ({ forceShow, setForceShow, ...rest }) => {
    const [show, setShow] = useState<boolean>(false);
    return (
        <OverlayTrigger
            show={show || forceShow}
            onToggle={(nextShow) => {
                setShow(nextShow);
                if (setForceShow)
                    setForceShow(nextShow);
            }}
            overlay={
                <Popover id={`auto-order-detail`}>
                    <Popover.Content >
                        <AutoOrderDescription />
                    </Popover.Content>
                </Popover>
            }
        >
            <Button variant="link" className="m-0 p-0 align-baseline" size="sm">Auto Order</Button>
        </OverlayTrigger>
    )
}

export default AutoOrderPopover;