import React, { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';
import BigDecimal from 'big.js';

/**
 * A utility component that warps around NumberFormat and provides default options in order to help display currency as text.  Returns a $ on the front as well.
 * @prop value A string or number that will be formatted into currencyt
 */
export const CurrencyDisplay: FunctionComponent<{ value: number | string | BigDecimal }> = ({ value }) => {
    if (value instanceof BigDecimal)
        value = value.toFixed(2);
    return (
        <React.Fragment>$<NumberFormat value={value} displayType='text' thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></React.Fragment>
    )
}
export default CurrencyDisplay