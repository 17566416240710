import React, { useState, FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

const VerticalPhoneNumber: FunctionComponent<VerticalPhoneNumberProps> = ({ format, type }) => {
    const chosenNumber = phoneNumbers[type];
    return (
        <NumberFormat displayType="text" value={chosenNumber} format={format} />
    )
}
export default VerticalPhoneNumber;

const phoneNumbers = {
    oneEightHundred: "8005171997",
    local: "9194681177",
}

export interface VerticalPhoneNumberProps {
    format: "+1 ##########" | "1-###-###-####" | "(###) ###-####" | "###-###-####"
    type: "oneEightHundred" | "local"
}