import { AppLevelActionTypes, SERVER_ONLINE, SERVER_OFFLINE, SERVER_FIRST_CONTACT_ERROR, SERVER_ERROR_STATE } from "store/actions/commonAppActions";
import { APPLICATION_ADD_CSS_FILE } from "store/actions/ec/appLevelActions";

const initialState: AppState = {
    serverState: "Unknown",
    cssFiles: []
}

export default function(state = initialState, action: AppLevelActionTypes): AppState {
    switch (action.type) {
        case SERVER_ONLINE:
            return {
                ...state,
                serverState: "Online"
            }
        case SERVER_OFFLINE:
            return {
                ...state,
                serverState: "Offline"
            }
        case SERVER_FIRST_CONTACT_ERROR:
            return {
                ...state,
                serverState: "First_Contact_Error"
            }
        case SERVER_ERROR_STATE:
            return {
                ...state,
                serverState: "Error"
            }
        case APPLICATION_ADD_CSS_FILE:
            /*
            The reason this exists is so that we can load CSS files separately from our bootstrap theme
            or a CSS file included via import for pages that are "public", as prerender.io does not
            consume the import css files well

            Using redux to manage and store these CSS files is necessary for the user experience of flipping between
            these pages.  It will keep the CSS files loaded in memory as you switch between pages, which emulates
            the behavior on the import method.  This way the css doesn't have to be loaded again when moving back to
            a page.
            */
            if (state.cssFiles.findIndex(css => css === action.payload) === -1) {
                return {
                    ...state,
                    cssFiles: [
                        ...state.cssFiles,
                        action.payload
                    ]
                }
            }
            else
                return state;
        default:
            return state;
    }
}

export type ServerState = "Unknown" | "Online" | "Offline" | "First_Contact_Error" | "Error"
type AppState = {
    serverState: ServerState
    cssFiles: string[]
}