import { AnalyticsReportProductV2 } from "./analyticsEventSender";
import axios from "axios";
import Big from "big.js";
import verticalConstants from "components/verticals/verticalConstants";
import { Hit } from "instantsearch.js";
import fixURI from "utilities/fixURI";
import getCategoryLeaf from "./getCategoryLeaf";
import getCategoryLeafFromString from "./getCategoryLeafFromString";

/**
 * Will examine the products provided and request more details if needed.
 * @param products 
 * @returns 
 */
export default async function getProductDetailsForReport(products: AnalyticsReportProductV2[]): Promise<AnalyticsReportProductV2[]> {
    const productsThatNeedMoreDetails: string[] = [];
    products.forEach((product) => {
        if (!product.item_name || !product.item_category || !product.item_brand || !product.price) {
            productsThatNeedMoreDetails.push(product.item_id);
        }
    })
    if (productsThatNeedMoreDetails.length > 0) {
        console.log(`GTM | More info required for the following mpIds: ${productsThatNeedMoreDetails.join(", ")}`);
        // const searchResult = await performSearch({
        //     filters: productsThatNeedMoreDetails.map((mpId): SearchRequestFilter => {
        //         return {
        //             field: "mp_id",
        //             value: mpId.toString()
        //         }
        //     }),
        //     resultsPerPage: 500,
        // }, 0);
		
		const finalSearchQuery = {
			filters: "objectID:" + productsThatNeedMoreDetails.join(" OR objectID:"),
			hitsPerPage: 1000,
        }
        const { data: searchResult } = await axios.post<AlgoliaSearchResponse>(`https://FLM6G9MOQQ-dsn.algolia.net/1/indexes/net32_products/query`,
            finalSearchQuery, 
            {
                headers: {
                    "X-Algolia-Application-Id": "FLM6G9MOQQ",
                    "X-Algolia-API-Key": "74e7fd62b20321f9a41c36aa355d6f86"
                }
            }
        )
        if (searchResult) {
            products = products.map((p): AnalyticsReportProductV2 => {
                const prodDetails = searchResult.hits?.find((pd) => pd.mp_id.toString() === p.item_id);
                if (!prodDetails) { // this should not occur except in extraordinary circumstances
                    return p;
                }
                  const productReport: AnalyticsReportProductV2 = {
                    ...p,
                    item_name: prodDetails.title,
                    item_brand: prodDetails.brand,
                    price: p.price ? p.price : prodDetails.price,
                    item_category: prodDetails.category.lvl1 ? getCategoryLeafFromString(prodDetails.category.lvl1) : undefined,
                    item_category2: prodDetails.category.lvl2 ? getCategoryLeafFromString(prodDetails.category.lvl2) : undefined,
                    item_category3: prodDetails.category.lvl3 ? getCategoryLeafFromString(prodDetails.category.lvl3) : undefined,
                    item_category4: prodDetails.category.lvl4 ? getCategoryLeafFromString(prodDetails.category.lvl4) : undefined,
                    item_specific_category: getCategoryLeaf(prodDetails),
                    item_absolute_url: `https://www.${verticalConstants.verticalName.lowerCase}.com${fixURI(prodDetails.link)}`,
                    item_absolute_image: `https://www.${verticalConstants.verticalName.lowerCase}.com${fixURI(prodDetails.image_link)}`,
                    item_subtotal: new Big(p.price ? p.price : prodDetails.price).times(p.quantity).toNumber(),
                };

                return productReport;
            })
        }
    }
    // The following auto-cleans any undefined attributes
    products = products.map((p) => {
        (Object.keys(p) as Array<keyof AnalyticsReportProductV2>).forEach(key => typeof p[key] === "undefined" && delete p[key])
        return p;
    })
    return products
}


type AlgoliaSearchResponse = {
    hits: ProductHit[]
}

export interface ProductHit extends Hit {
  description_short: string;
  title: string;
  mp_id: number;
  image_link: string;
  brand: string;
  attributes: { packaging: string };
  retail_price: number;
  price: number;
  manufacturer: string;
  general_rating?: number | undefined;
  number_of_general_ratings?: number;
  category: { [key: string]: string };
  link: string;
  inventory_quantity: number;
}