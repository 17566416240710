import React, { useState, FunctionComponent } from 'react';

import { ScopedSearchOption } from './scopedSearch';
import Overlay from 'react-bootstrap/Overlay';


const ScopedSearchOptionsOverlay: FunctionComponent<ScopedSearchOptionsOverlayProps> = ({ isScopedSearchOpened, scopedSearchOptions, formTarget, setScopedSearchOpenedCallback, changeScopedSearchCallback, ...rest }) => {

	const [scopedSearchTerm, setScopedSearchTerm] = useState("");

	const getScopedSearchOptionsByType = (type: string): ScopedSearchOption[] => {
        return scopedSearchOptions.filter(s => s.type === type && s.name.toLowerCase().includes(scopedSearchTerm.toLowerCase()));
	}

	const changeScopedSearch = (scopedSearchOption: ScopedSearchOption): void => {
        setScopedSearchTerm("");
		changeScopedSearchCallback(scopedSearchOption);
	}

	return (
		<Overlay
			show={isScopedSearchOpened}
			target={formTarget.current}
			placement="bottom-start"
			container={formTarget.current} rootClose onHide={() => setScopedSearchOpenedCallback(false)}>
			<div id="scoped-search-container">
				<div className="scoped-search-filter">
					<input type="text" className="scoped-search-filter-input" placeholder="Type to search..."
						value={scopedSearchTerm} onChange={(event) => {setScopedSearchTerm(event.target.value)}} />
					<div className="scoped-search-filter-icon"></div>
				</div>					

				<div className="scoped-search-sections">
					<ul className="scoped-search-sections-list">
						{getScopedSearchOptionsByType("fixed").map((option, index) => (
							<li key={"fixed-scope" + index} onClick={() => changeScopedSearch(option)}>{option.name}</li>
						))}
					</ul>

					{getScopedSearchOptionsByType("category").length > 0 &&
						<div className="scoped-search-sections-dynamic-list">
							<span className="scoped-search-sections-dynamic-list-title">Search by:</span>
							
							<ul className="scoped-search-sections-list">
								{getScopedSearchOptionsByType("category").map((option, index) => (
									<li key={"category-scope" + index} onClick={() => changeScopedSearch(option)}>{option.name}</li>
								))}
							</ul>
						</div>
					}
				</div>
			</div>
		</Overlay>
	);

}

export type ScopedSearchOptionsOverlayProps = {
    isScopedSearchOpened: boolean;
	scopedSearchOptions: ScopedSearchOption[]
	formTarget: React.RefObject<HTMLFormElement>;
    setScopedSearchOpenedCallback: (isScopedSearchOpened: boolean) => void;
	changeScopedSearchCallback: (scopedSearchOption: ScopedSearchOption) => void;
}

export default ScopedSearchOptionsOverlay;

