import { OrderDetailActions, ORDER_DETAIL_ADD_ORDER_TO_STORE } from "store/actions/ec/orderDetailActions";
import { any } from "prop-types";


const initialState: OrderDetailState = {
   order: null,
   shippingMethods: []
}

export default (state: OrderDetailState = initialState, action: OrderDetailActions) => {
    switch (action.type) {
        case ORDER_DETAIL_ADD_ORDER_TO_STORE: {
            const newState = {...state};
            newState.order = (action.payload != null && action.payload != undefined) ? manifestsParser(action.payload): action.payload;
            newState.shippingMethods = (action.payload != null && action.payload != undefined && action.payload.shippingMethods != undefined) ? action.payload.shippingMethods : [];
            //console.log("###"+JSON.stringify(newState));
            return newState;
        }
        default:
            return state;
    }
}
export interface OrderDetailState {
    order: Orders.OrdersHistory.CustomerOrder | null
    shippingMethods: any;
}

function manifestsParser(customerOrder: Orders.OrdersHistory.CustomerOrder) {
    let statusMessage = "";
    let shippedItemCount = 0;
    let deliveredItemCount = 0;
    let processingItemCount = 0;
    let backorderedItemCount = 0;
    let cancelledItemCount = 0;
    let returnedItemCount = 0;
    // For some strange reason a "for...of" loop sometimes skips over vendor orders when a standard loop doesn't. (seen only in the order history page.) This change was copied from OrderHistoryReducer.ts to here just to be safe + consistant.
    for (let i = 0; i < customerOrder.vendorOrders.length; i++) { 
        const vendorOrder = customerOrder.vendorOrders[i];
        let shipmentLineItems = Array<Orders.OrdersHistory.ShippedLineType>();
        const shipmentLineItemData = Array<Orders.OrdersHistory.ShippedLineItem>();

        const shippedLineItems = Array<Orders.OrdersHistory.ShippedLineType>();
        const shippedLineItemData = Array<Orders.OrdersHistory.ShippedLineItem>();

        const deliveredLineItems = Array<Orders.OrdersHistory.ShippedLineType>();
        const deliveredLineItemData = Array<Orders.OrdersHistory.ShippedLineItem>();

        const processingLineItems = Array<Orders.OrdersHistory.ProcessedLineItem>();
        const cancelledLineItems = Array<Orders.OrdersHistory.ProcessedLineItem>();
        const returnedLineItems = Array<Orders.OrdersHistory.ProcessedLineItem>();

        let manifestStatus;
        const shipmentManifestList: any[] = [];
        vendorOrder.lineItems.forEach(function (lineItem: Orders.OrdersHistory.LineItem) {
            if (lineItem.totalPending > 0) {
                const processingItem = {
                    "noOfItems": lineItem.totalPending,
                    "lineItem": lineItem
                }
                processingItemCount = processingItemCount + lineItem.totalPending;
                processingLineItems.push(processingItem);
            }
            if(lineItem.manifests != undefined){ lineItem.manifests.forEach(function (manifest: Orders.OrdersHistory.Manifests) {
                if (manifest.type === "shipment") {
                    if(!shipmentManifestList.includes(manifest.id)){
                        shipmentManifestList.push(manifest.id);
                    }
                    const shippedItem = {
                        "trackingKey": manifest.creationTime.toString() + manifest.shippingMethod + manifest.trackingNumber,
                        "noOfItems": manifest.festQuantity,
                        "lineItem": lineItem,
                        "trackingNumber": manifest.trackingNumber,
                        "shippedDate": manifest.shipTime,
                        "shippedDateInitialized": manifest.shipTime,
                        "deliveryDate": manifest.deliveryTime,
                        "shipmentMethod": manifest.shippingMethod,
                        "creationTime": manifest.creationTime,
                        "creationTimeRaw": manifest.creationTime
                    }
                    shipmentLineItemData.push(shippedItem);
                    if(manifest.deliveryTime) {
                        manifestStatus = 'DELIVERED';
                        deliveredItemCount = deliveredItemCount + manifest.festQuantity;
                        deliveredLineItemData.push(shippedItem);
                    } else {
                        manifestStatus = 'SHIPPED';
                        shippedItemCount = shippedItemCount + manifest.festQuantity;
                        shippedLineItemData.push(shippedItem);
                    }
                } else if (manifest.type === "cancellation") {
                    const cancelledItem = {
                        "noOfItems": manifest.festQuantity,
                        "lineItem": lineItem,
                        "reason": ((manifest.cancelReasonDesn != undefined && manifest.cancelReasonId != "23") ? manifest.cancelReasonDesn : "Call Customer Loyalty (800) 517-1997")
                    }
                    cancelledItemCount = cancelledItemCount + manifest.festQuantity;
                    cancelledLineItems.push(cancelledItem);
                } else if (manifest.type === "return") {
                    const returnReason = "Customer Return" + (manifest.isForExchange === true ? " for Exchange - " : " - ") +
                        (manifest.returnReasonDesn != undefined ? manifest.returnReasonDesn : "")
                        + (manifest.isForExchange === true ? " - Awaiting Shipment" : "");
                    const returnedItem = {
                        "noOfItems": manifest.festQuantity,
                        "lineItem": lineItem,
                        "reason": returnReason
                    }
                    returnedItemCount = returnedItemCount + manifest.festQuantity;
                    returnedLineItems.push(returnedItem);
                } else if (manifest.type === "backorder") {
                    const backOrderedItem = {
                        "noOfItems": manifest.festQuantity,
                        "lineItem": lineItem,
                        "backorderedDate": (manifest.eta != undefined ? manifest.eta : null)
                    }
                    backorderedItemCount = backorderedItemCount + manifest.festQuantity;
                    processingLineItems.unshift(backOrderedItem);
                }
            })}
        });
        shipmentLineItems = reduceItems(shipmentLineItemData, shipmentLineItems);
        for(const shipment of shipmentLineItems) {
            if(!shipment?.shippedLineItemData[0]?.deliveryDate)
                shippedLineItems.push(shipment);
            else
                deliveredLineItems.push(shipment);
        }
        vendorOrder.shipmentLineItems = shipmentLineItems;
        vendorOrder.shippedLineItems = shippedLineItems;
        vendorOrder.deliveredLineItems = deliveredLineItems;
        vendorOrder.processingLineItems = processingLineItems;
        vendorOrder.cancelledLineItems = cancelledLineItems;
        vendorOrder.returnedLineItems = returnedLineItems;
    }
    if (shippedItemCount > 0)
        statusMessage = statusMessage + "Shipped (" + shippedItemCount + "), ";
    if (deliveredItemCount > 0)
        statusMessage = statusMessage + "Delivered (" + deliveredItemCount + "), ";
    if (processingItemCount > 0)
        statusMessage = statusMessage + "Processing (" + processingItemCount + "), ";
    if (backorderedItemCount > 0)
        statusMessage = statusMessage + "Backordered (" + backorderedItemCount + "), ";
    if (cancelledItemCount > 0)
        statusMessage = statusMessage + "Cancelled (" + cancelledItemCount + "), ";
    if (returnedItemCount > 0)
        statusMessage = statusMessage + "Returned (" + returnedItemCount + "), ";

    customerOrder.statusMessage = statusMessage.slice(0, -2);

    return customerOrder;
}


function reduceItems(shippedLineItemData: Array<Orders.OrdersHistory.ShippedLineItem>, shippedLineItems: Array<Orders.OrdersHistory.ShippedLineType>) {
    shippedLineItemData.sort((a: { trackingKey: string; }, b: { trackingKey: string; }) => (a.trackingKey > b.trackingKey) ? -1 : ((b.trackingKey > a.trackingKey) ? 1 : 0));
    let previousTrackingNumber: string | null = null;
    let sortedShippedLineItems = Array<Orders.OrdersHistory.ShippedLineItem>();
    let currentShipmentIndex = 0; // Index to keep track of what shipment we are on
    shippedLineItemData.map((element: Orders.OrdersHistory.ShippedLineItem, i: number) => {
        if (previousTrackingNumber === element.trackingKey) { // Continuing the shipment
            sortedShippedLineItems.push(element);
        } else if (previousTrackingNumber === null) { // First line item
            previousTrackingNumber = element.trackingKey
            sortedShippedLineItems.push(element);
        } else { // Start new Shipment
            const item = { // -> add tracking no and all 
                "trackingKey": previousTrackingNumber.toString(),
                "trackingNumber": sortedShippedLineItems[0].trackingNumber,
                "shippedLineItemData": sortedShippedLineItems,
                "shipmentIndex": currentShipmentIndex++ //Add shipmentIndex for "get help" page link
            } 
            shippedLineItems.push(item);
            sortedShippedLineItems = [];
            previousTrackingNumber = element.trackingKey
            sortedShippedLineItems.push(element);
        }
        if (i === (shippedLineItemData.length - 1)) { // Last lineItem: make sure we add this shipment
            const item = { // -> add tracking no and all 
                "trackingKey": previousTrackingNumber,
                "trackingNumber": sortedShippedLineItems[0].trackingNumber,
                "shippedLineItemData": sortedShippedLineItems,
                "shipmentIndex": currentShipmentIndex++ //Add shipmentIndex for "get help" page link
            }
            shippedLineItems.push(item);
        }
    })
    return shippedLineItems;
}