import { AppThunk } from "store/reducer/ec/rootReducer";
import axios, { AxiosError } from 'axios'
import { ModifyShoppingCartPartialSuccessResponse, ModifyShoppingCartResponse, ShoppingCartPayloadFromServer, ShoppingCartVendorOrderProduct } from "store/reducer/ec/shopping-cart/shoppingCartTypes";
import { Action } from "redux";
import { ShoppingCartRetrievalStatus } from "store/reducer/ec/shoppingCartReducer";
import { FreeShippingProductSuggestion } from "store/reducer/ec/shopping-cart/freeShippingSuggestionTypes";
import { ShoppingCartReorderProduct } from "store/reducer/ec/shopping-cart/reorderProductTypes";
import { SaveForLaterProduct, SaveForLaterProductUpdate } from "store/reducer/ec/shopping-cart/saveForLaterProductTypes";
import {
    StatusCodes,
} from 'http-status-codes';
import { FunctionComponent } from "react";
import { QuickItemAddInventoryErrorPayload } from "components/ec/shopping-cart/quick-item-entry/quickItemEntry";
import { ConsolidatedCartSavings } from "store/reducer/ec/shopping-cart/consolidationCartTypes";
import { AnalyticsSource } from "hooks/useAnalyticsManager";
import { IncomingAnalyticsProduct, modifyCartAnalyticsEvent } from "utilities/analytics/analyticsEventSender";
import { UserState } from "store/reducer/userReducer";
import { VendorOptionFromServer , VendorOptionPriceBreakState} from 'components/ec/catalog/product-detail/productDetailPage';
import getPriceBreakForQuantity from '../../../components/ec/catalog/product-detail/vendor-options/getPriceBreakForQuantity';
import BigDecimal from "big.js";
import Cookies from "js-cookie";
// ====================================================================================================
// Action Types
// ----------------------------------------------------------------------------------------------------
// Core Cart Actions
export const SET_SHOPPING_CART = "cart: Set shopping cart";

export interface SetShoppingCartAction extends Action {
    type: typeof SET_SHOPPING_CART;
    payload: ShoppingCartPayloadFromServer;
}
export const ADD_SHOPPING_CART_SMART_MESSAGE = "ADD_SHOPPING_CART_SMART_MESSAGE";
export interface AddShoppingCartSmartMessage extends Action {
    type: typeof ADD_SHOPPING_CART_SMART_MESSAGE
    payload: {
        identifier: string,
        message: string,
    }
}
export const REMOVE_SHOPPING_CART_SMART_MESSAGE = "REMOVE_SHOPPING_CART_SMART_MESSAGE";
export interface RemoveShoppingCartSmartMessage extends Action {
    type: typeof REMOVE_SHOPPING_CART_SMART_MESSAGE
    payload: {
        identifier: string
    }
}

export const CLOSE_CART_REPLACED_NOT_IN_STOCK_MESSAGE = "car: close cart replaced not in stock message";
export interface CloseCartReplacedNotInStockMessage extends Action {
    type: typeof CLOSE_CART_REPLACED_NOT_IN_STOCK_MESSAGE
}

export const SHOW_SIDE_SHOPPING_CART = "SHOW_SIDE_SHOPPING_CART";
export const HIDE_SIDE_SHOPPING_CART = "HIDE_SIDE_SHOPPING_CART";

export interface ShowSideShoppingCartAction extends Action {
    type: typeof SHOW_SIDE_SHOPPING_CART
}
export interface HideSideShoppingCartAction extends Action {
    type: typeof HIDE_SIDE_SHOPPING_CART
}

export type CoreShoppingCartActionTypes = SetShoppingCartAction | AddShoppingCartSmartMessage | RemoveShoppingCartSmartMessage | CloseCartReplacedNotInStockMessage | ShowSideShoppingCartAction | HideSideShoppingCartAction;
// ----------------------------------------------------------------------------------------------------
// Save for Later Actions
export const CREATE_SAVE_FOR_LATER_PRODUCT = "cart: Create Save for Later Product(s)";
export const UPDATE_SAVE_FOR_LATER_PRODUCT = "cart: Update Save for Later Product";
export const DELETE_SAVE_FOR_LATER_PRODUCT = "cart: Delete Save for Later Product";

export interface CreateSaveForLaterProductAction extends Action {
    type: typeof CREATE_SAVE_FOR_LATER_PRODUCT;
    payload: SaveForLaterProduct[];
}
export interface UpdateSaveForLaterProductAction extends Action {
    type: typeof UPDATE_SAVE_FOR_LATER_PRODUCT;
    payload: SaveForLaterProductUpdate;
}
export interface DeleteSaveForLaterProductAction extends Action {
    type: typeof DELETE_SAVE_FOR_LATER_PRODUCT;
    payload: {
        vendorProductId: number
    };
}
type SaveForLaterActionTypes = CreateSaveForLaterProductAction | UpdateSaveForLaterProductAction | DeleteSaveForLaterProductAction;
// ----------------------------------------------------------------------------------------------------
// Reorder Actions
export const CREATE_REORDER_PRODUCT = "cart: Create Reorder Product(s)";
export const UPDATE_REORDER_PRODUCT = "cart: Update Reorder Product(s)";
export const DELETE_REORDER_PRODUCT = "cart: Delete Reorder Product";
export const REFRESH_REORDER_PRODUCT_QUANTITY_PRICE = "cart: Refresh Reorder Product Quantity Price";

export type UpdateShoppingCartReorderProduct = Pick<ShoppingCartReorderProduct, "mpId"> & Partial<ShoppingCartReorderProduct>;
export interface CreateReorderProductAction extends Action {
    type: typeof CREATE_REORDER_PRODUCT;
    payload: ShoppingCartReorderProduct[];
}
export interface UpdateReorderProductAction extends Action {
    type: typeof UPDATE_REORDER_PRODUCT;
    payload: UpdateShoppingCartReorderProduct[];
}
export interface DeleteReorderProductAction extends Action {
    type: typeof DELETE_REORDER_PRODUCT;
    payload: {
        mpId: number
    };
}

export interface RefreshReorderMpIdQuantityPriceAction extends Action {
    type: typeof REFRESH_REORDER_PRODUCT_QUANTITY_PRICE;
    payload: {
        mpId: number,
        vendorProductId: number,
        minQty: number,
        unitPrice: number,
        inventory: number
    };
}

export type ReorderShoppingCartActionTypes = CreateReorderProductAction | DeleteReorderProductAction | UpdateReorderProductAction | RefreshReorderMpIdQuantityPriceAction;

// ----------------------------------------------------------------------------------------------------
// Free Shipping Suggestion Actions
export const SET_FREE_SHIPPING_SUGGESTION_PRODUCT = "cart: Set Free Shipping Suggestion Product(s)";
export const DELETE_FREE_SHIPPING_SUGGESTION_PRODUCT = "cart: Delete Free Shipping Suggestion Product";

export interface SetFreeShippingSuggestionProductAction extends Action {
    type: typeof SET_FREE_SHIPPING_SUGGESTION_PRODUCT;
    payload: FreeShippingProductSuggestion[];
}

export interface DeleteFreeShippingSuggestionProductAction extends Action {
    type: typeof DELETE_FREE_SHIPPING_SUGGESTION_PRODUCT;
    payload: FreeShippingProductSuggestion;
}
type FreeShippingSuggestionProductActionTypes = SetFreeShippingSuggestionProductAction | DeleteFreeShippingSuggestionProductAction;
// ----------------------------------------------------------------------------------------------------
// Cart Consolidatiom Actions
export const GET_OPTIMAL_CART_SAVINGS = "cart: Get optimal consolidated cart saving(s)";
export const SAVE_SHOPPING_CART = "cart: save Shopping cart summary for revert";

export interface GetOptimalCartSavingsProductAction extends Action {
    type: typeof GET_OPTIMAL_CART_SAVINGS;
    payload: ConsolidatedCartSavings | null;
}

export interface SaveShoppingCartProductsAction extends Action {
    type: typeof SAVE_SHOPPING_CART;
    payload: Array<RevertProduct> ;
}

type ConsolidationActionTypes = GetOptimalCartSavingsProductAction | SaveShoppingCartProductsAction;


// ----------------------------------------------------------------------------------------------------
// Other
export const REPORT_ERROR = "cart: Report error";
export const CLOSE_REPORTED_ERROR = "cart: Close reported error";
export const SET_ADDED_TO_AUTO_ORDER = "cart: Set Product Added to Auto Order";
export const SET_CART_RETRIEVAL_STATUS = "cart: Set Retrieval Status";
export const CLOSE_CART_CHANGED_CONSOLIDATION_MESSAGE = "cart: Close cart changed & consolidated cart message"
export interface ReportErrorAction extends Action {
    type: typeof REPORT_ERROR;
    payload: {
        errorMessage: ErrorMessage
    };
}
export interface CloseReportedErrorAction extends Action {
    type: typeof CLOSE_REPORTED_ERROR;
    payload: {
        id: number
    };
}
export interface IsOnAutoOrderAction extends Action {
    type: typeof SET_ADDED_TO_AUTO_ORDER;
    payload: {
        mpId: number,
        autoOrderFrequency: string,
        nextAutoOrderDate: import('moment').Moment
    };
}
type ErrorMessage = string | FunctionComponent;

export interface SetCartRetrievalStatusAction extends Action {
    type: typeof SET_CART_RETRIEVAL_STATUS,
    payload: ShoppingCartRetrievalStatus
}

export interface CloseCartChangedAndConsolidatedMessageAction extends Action {
    type: typeof CLOSE_CART_CHANGED_CONSOLIDATION_MESSAGE,
}

export type OtherShoppingCartActions = ReportErrorAction | CloseReportedErrorAction | IsOnAutoOrderAction | SetCartRetrievalStatusAction | CloseCartChangedAndConsolidatedMessageAction;

// ----------------------------------------------------------------------------------------------------
export type ShoppingCartActionTypes = CoreShoppingCartActionTypes | SaveForLaterActionTypes | FreeShippingSuggestionProductActionTypes | ConsolidationActionTypes | ReorderShoppingCartActionTypes | OtherShoppingCartActions;
// ====================================================================================================
// Core Cart Actions
export function setShoppingCart(shoppngCartProducts: ShoppingCartPayloadFromServer): SetShoppingCartAction {
    return {
        type: SET_SHOPPING_CART,
        payload: shoppngCartProducts
    }
}
export function addShoppingCartSmartMessage(identifier: string, message: string):AddShoppingCartSmartMessage {
    return {
        type: ADD_SHOPPING_CART_SMART_MESSAGE,
        payload: {
            identifier: identifier,
            message: message,
        }
    }
}
export function removeShoppingCartSmartMessage(identifier: string ):RemoveShoppingCartSmartMessage {
    return {
        type: REMOVE_SHOPPING_CART_SMART_MESSAGE,
        payload: {
            identifier: identifier,
        }
    }
}

export function showSideShoppingCart(): ShowSideShoppingCartAction {
    return {
        type: SHOW_SIDE_SHOPPING_CART
    }
}
export function hideSideShoppingCart(): HideSideShoppingCartAction {
    return {
        type: HIDE_SIDE_SHOPPING_CART
    }
}

// ----------------------------------------------------------------------------------------------------
// Save for Later Actions
export function createSaveForLaterProduct(saveForLaterProducts: SaveForLaterProduct[]): CreateSaveForLaterProductAction {
    return {
        type: CREATE_SAVE_FOR_LATER_PRODUCT,
        payload: saveForLaterProducts
    }
}
export function updateSaveForLaterProduct(saveForLaterProduct: SaveForLaterProductUpdate): UpdateSaveForLaterProductAction {
    return {
        type: UPDATE_SAVE_FOR_LATER_PRODUCT,
        payload: saveForLaterProduct,
    }
}
export function deleteSaveForLaterProduct(vendorProductId: number): DeleteSaveForLaterProductAction {
    return {
        type: DELETE_SAVE_FOR_LATER_PRODUCT,
        payload: {
            vendorProductId: vendorProductId
        }
    }
}
// ----------------------------------------------------------------------------------------------------
// Reorder Actions
export function createReorderProduct(reorderProducts: ShoppingCartReorderProduct[]): CreateReorderProductAction {
    return {
        type: CREATE_REORDER_PRODUCT,
        payload: reorderProducts
    }
}

export function updateReorderProduct(reorderProducts: UpdateShoppingCartReorderProduct[]): UpdateReorderProductAction {
    return {
        type: UPDATE_REORDER_PRODUCT,
        payload: reorderProducts
    }
}
export function deleteReorderProduct(mpId: number): DeleteReorderProductAction {
    return {
        type: DELETE_REORDER_PRODUCT,
        payload: {
            mpId: mpId
        }
    }
}

export function refreshReorderMpIdQuantityPrice(mpId: number, vendorProductId:number, minQty: number, unitPrice: number, inventory: number): RefreshReorderMpIdQuantityPriceAction {
    return {
        type: REFRESH_REORDER_PRODUCT_QUANTITY_PRICE,
        payload: {
            mpId: mpId,
            vendorProductId: vendorProductId,
            minQty: minQty,
            unitPrice: unitPrice,
            inventory: inventory
        }
    }
}

// ----------------------------------------------------------------------------------------------------
// Free Shipping Suggestion Actions

export function setFreeShippingSuggestionProduct(freeShippingSuggestionProducts: FreeShippingProductSuggestion[]): SetFreeShippingSuggestionProductAction {
    return {
        type: SET_FREE_SHIPPING_SUGGESTION_PRODUCT,
        payload: freeShippingSuggestionProducts
    }
}

export function deleteFreeShippingSuggestionProduct(freeShippingSuggestionProduct: FreeShippingProductSuggestion): DeleteFreeShippingSuggestionProductAction {
    return {
        type: DELETE_FREE_SHIPPING_SUGGESTION_PRODUCT,
        payload: freeShippingSuggestionProduct
    }
}

// ----------------------------------------------------------------------------------------------------
// Consolidation Cart Savings

export function setOptimalCartSavings(consolidatedCartSavings: ConsolidatedCartSavings | null): GetOptimalCartSavingsProductAction {
    return {
        type: GET_OPTIMAL_CART_SAVINGS,
        payload: consolidatedCartSavings
    }
}

// Save Shopping cart products for revert 

export function setShoppingCartProductForRevert(shoppingCartSummaryProducts: Array<RevertProduct> ): SaveShoppingCartProductsAction {
    return {
        type: SAVE_SHOPPING_CART,
        payload: shoppingCartSummaryProducts
    }
}
// ----------------------------------------------------------------------------------------------------
// Other
export function reportError(errorMessage: string | FunctionComponent): ReportErrorAction {
    return {
        type: REPORT_ERROR,
        payload: {
            errorMessage: errorMessage
        }
    }
}
export function closeReportedError(id: number): CloseReportedErrorAction {
    return {
        type: CLOSE_REPORTED_ERROR,
        payload: {
            id: id
        }
    }
}
export function setIsOnAutoOrder(mpId: number, autoOrderFrequency: string, nextAutoOrderDate: import('moment').Moment): IsOnAutoOrderAction {
    return {
        type: SET_ADDED_TO_AUTO_ORDER,
        payload: {
            mpId: mpId,
            autoOrderFrequency: autoOrderFrequency,
            nextAutoOrderDate: nextAutoOrderDate
        }
    }
}
export function setCartRetrievalStatus (retrievalState: ShoppingCartRetrievalStatus): SetCartRetrievalStatusAction {
    return {
        type: SET_CART_RETRIEVAL_STATUS,
        payload: retrievalState
    }
}

export function closeCartChangedAndConsolidatedMessage(): CloseCartChangedAndConsolidatedMessageAction {
    return {
        type: CLOSE_CART_CHANGED_CONSOLIDATION_MESSAGE
    }
}
export function closeCartReplacedNotInStockMessage():CloseCartReplacedNotInStockMessage {
    return {
        type: CLOSE_CART_REPLACED_NOT_IN_STOCK_MESSAGE
    }
}

// ====================================================================================================
// Thunk Actions
/**
 * @deprecated
 * Modifies the shopping cart for use with the Shopping Cart Sidebar on various pages.  In the future,
 * that component should not rely on the s
 * @param productsToModify 
 */
export const modifyShoppingCart = (productsToModify: Array<{ mpId: number, vpId: number, minQty: number, qty: number }>): AppThunk => async dispatch => {
    console.log("Modifiying Shopping Cart");
    let requestParams = '';
    for (const product of productsToModify) {
        requestParams = requestParams + 'mpId=' + product.mpId + '&vpId=' + product.vpId + '&minQty=' + product.minQty + '&qty=' + product.qty + '&';
    }
     const response = await fetch("/rest/shoppingCart/modify", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "Accept": "application/json"
        },
        body: requestParams,
        redirect: "follow", // manual, *follow, error
    })
    if (response.ok === true) {
        const payload =  await response.json()  as WindfallRestfulResponse.ResponsePacket;

        dispatch(getShoppingCart());
    }
    else {
        // TOOD - Error dispatch
    } 
}
// ----------------------------------------------------------------------------------------------------
// Core Cart Thunk Actions
export const getShoppingCart = (): AppThunk => async dispatch => {
    dispatch(setCartRetrievalStatus(ShoppingCartRetrievalStatus.RETRIEVING))
	console.log("Getting shopping cart vendor orders and products.");
     const response = await fetch("/rest/neo/shopping-cart/get", {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Accept": "application/json"
        },
        redirect: "follow", // manual, *follow, error
    })
    if (response.ok === true) {
		const responseBody =  await response.json() as Melatonin.BasicRestfulResponse<ShoppingCartPayloadFromServer>;
		console.log("Shopping Cart: ", responseBody);
		if (responseBody.payload)
		{
            dispatch(
                setShoppingCart(responseBody.payload)
            );
            dispatch(setCartRetrievalStatus(ShoppingCartRetrievalStatus.RETRIEVED))
        } else {
			console.error("Error retrieving shopping cart from server");
		}
    }
    else {
        // TOOD - Error dispatch
    } 
}

export type ModifyCartProduct = Pick<ShoppingCartVendorOrderProduct, "mpId" | "vendorProductId" | "minimumQuantity" | "quantity" | "categoryName"> & Partial<Pick<ShoppingCartVendorOrderProduct, "totalInventory">> & {
    originalQuantity: number
};
export type ModifyProductResultCallbackProps = { 
    status: StatusCodes.OK, 
    message: string, 
    /**
     * @deprecated This is just a repeat of what was handed to the function.  Ideally this should be changed to modifiedProduct below, 
     *             but to avoid potential issues I'm leaving this untouched.
     */
    productsAdded: Array<ModifyCartProduct>
    productsModified: {
        addedOrUpdated: Array<ModifyCartProduct>,
        removed: Array<ModifyCartProduct>
    } 
}
 | {
    status: StatusCodes.PARTIAL_CONTENT,
    message: string,
    payload: {
        productsNotModified: ModifyShoppingCartPartialSuccessResponse['payload']['productsNotModified'],//
        shoppingCart: ShoppingCartPayloadFromServer,
    }
 }
 | {
    status: StatusCodes.INTERNAL_SERVER_ERROR | StatusCodes.UNAUTHORIZED, 
    message: string,
}

/**
 * 
 * @param productsToModify 
 * @param userState 
 * @param source 
 * @param callback 
 * @param preventErrorReport 
 * @returns 
 */

type CreateOrUpdateShoppingCartProductOnServerProps = {
    productsToModify: Array<ModifyCartProduct>,
    userState: UserState, 
    source: AnalyticsSource, 
    reportErrorsToReduxStore?: boolean, 
    callback?: (a: ModifyProductResultCallbackProps) => void, 
    incrementQuantity?: boolean, 
    doNotReportToAnalytics?: boolean
}
export const createOrUpdateShoppingCartProductOnServer = ({
    productsToModify,
    userState,
    source,
    reportErrorsToReduxStore = false,
    callback,
    incrementQuantity = false,
    doNotReportToAnalytics = false,
}: CreateOrUpdateShoppingCartProductOnServerProps): AppThunk => async (dispatch, getState) => {
    console.log("Creating or Updating shopping cart product on server");
    console.log("Products to modify: ", productsToModify);

    try {
        let shoppingCartURL = "/rest/neo/shopping-cart/modify";
        if (incrementQuantity === true) {
            shoppingCartURL += "?incrementQuantity=true"
        }
        // We need to exclude originalQuantity, the backend doesn't like it but we need it for reporting
        const productsToPost = productsToModify.map(p => {
            return {
                categoryName: p.categoryName,
                minimumQuantity: p.minimumQuantity,
                mpId: p.mpId,
                quantity: p.quantity,
                vendorProductId: p.vendorProductId,
            }
        })

        const { status, data } = await axios.post<ModifyShoppingCartResponse>(shoppingCartURL, productsToPost);

        if (data.status === StatusCodes.OK)
        {
            dispatch(
                setShoppingCart(data.payload)
            );

            const productsInCart = data.payload.vendorOrders.flatMap((vo) => vo.products);

            if (doNotReportToAnalytics !== true) {
                const productsToReport: IncomingAnalyticsProduct[] = productsToModify.map(p => {
                    const productInCart = productsInCart.find(pic => pic.vendorProductId == p.vendorProductId);
                    const returnProduct: IncomingAnalyticsProduct = {
                        mpId: p.mpId,
                        quantity: p.quantity,
                        unitPrice: productInCart ? productInCart.unitPrice : undefined,
                        originalQuantity: p.originalQuantity
                    }
                    return returnProduct;
                })
                modifyCartAnalyticsEvent(productsToReport, userState, source, incrementQuantity, getState().shoppingCart);
            }
            dispatch(setOptimalCartSavings(null))
            dispatch(getOptimalSavings());
            dispatch(setShoppingCartProductForRevert([]));

            const productsAdded: ModifyCartProduct[] = productsInCart.filter(p => productsToModify.some(pta => pta.vendorProductId === pta.vendorProductId)).map(prod => {
                const originalQuantity = productsToModify.find(ptm => ptm.vendorProductId === prod.vendorProductId)?.originalQuantity;
                const convertedProducts: ModifyCartProduct = {
                    mpId: prod.mpId,
                    vendorProductId: prod.vendorProductId,
                    minimumQuantity: prod.minimumQuantity,
                    quantity: prod.quantity,
                    categoryName: prod.categoryName,
                    totalInventory: prod.totalInventory,
                    originalQuantity: originalQuantity ? originalQuantity : 0, // This should in reality always be the actually provided number
                }
                return convertedProducts;
            });
            const productsRemoved: ModifyCartProduct[] = productsToModify.filter(pta => productsInCart.some(p => p.vendorProductId == pta.vendorProductId) === false)

            if (callback) {
                callback({
                    status: StatusCodes.OK,
                    message: "Added to cart",
                    productsAdded: productsToModify,
                    productsModified: {
                        addedOrUpdated: productsAdded,
                        removed: productsRemoved,
                    }

                })
            }
        }
        else if (data.status === StatusCodes.PARTIAL_CONTENT) {
            // TODO, do something about the unmodified product;
            const productsInStore: ShoppingCartVendorOrderProduct[] =  getState().shoppingCart.vendorOrders.flatMap(vo => vo.products);
            data.payload.productsNotModified.forEach(productNotModified => {
                const product = productsInStore.find(p => p.vendorProductId == productNotModified.vendorProductId);
                if (product && reportErrorsToReduxStore === true) {
                    dispatch(
                        reportError(`The product "${product.productTitle}" could not be updated to the quantity of ${productNotModified.quantity}.  No offer was found.`)
                    )
                }
                else if (reportErrorsToReduxStore === true) {
                    dispatch(
                        reportError("The product you attempted to add to the cart could not be added.  Please try again.")
                    );
                }
            })
            if (callback) {
                callback({
                    status: StatusCodes.PARTIAL_CONTENT,
                    message: data.message ? data.message : 'Not all products were updated',
                    payload: {
                        productsNotModified: data.payload.productsNotModified,
                        shoppingCart: data.payload.shoppingCart
                    }
                })
            }
            dispatch(
                setShoppingCart(data.payload.shoppingCart)
            );
        }
    } catch (error) {
        console.error("createOrUpdateShoppingCartProductOnServer Error | ", error);
        if (reportErrorsToReduxStore === true)
        {
            dispatch(
                reportError("The product(s) you attempted to add to the cart could not be added.  Please try again.")
            );
        }
        if (callback) {
            callback({
                status: StatusCodes.INTERNAL_SERVER_ERROR,
                message: "An internal server error occurred.",
                // productsAdded: productsToAdd
            })
        }
    }

    
}

export const deleteShoppingCartProductFromServer = (mpId: number, vpId: number, minQty: number ): AppThunk => async dispatch => {
    console.log("Deleting shopping cart product on server");
     // dispatch(deleteShoppingCartProduct(/* todo, params  */))
}


// ----------------------------------------------------------------------------------------------------
// Save for Later Thunk Actions
export const getSaveForLaterProducts = (): AppThunk => async dispatch => {
    try{
        console.log("Getting save for later items.");
        const {data} = await axios.get('/rest/shoppingCart/getSaveForLater')

        dispatch({
            type: CREATE_SAVE_FOR_LATER_PRODUCT,
            payload: data.payload.ShoppingCartSummary.saveForLaterVendorOrders
        })
        //if (data.payload.ShoppingCartSummary && data.payload.ShoppingCartSummary.saveForLaterVendorOrders)
           // dispatch(createSaveForLaterProduct(data.payload.ShoppingCartSummary.saveForLaterVendorOrders))
    }catch (error){
        console.log("Error while Getting save for later products.");
    }
}
export const moveSaveForLaterProductToCartOnServer = (product: SaveForLaterProduct, userState: UserState): AppThunk => async (dispatch, getState)  => {
    console.log("Moving save for later product to the cart.");
    try {
        const { status, data } = await axios.get<ModifyShoppingCartResponse>('/rest/shoppingCart/saveForLaterToCart/rev2?vpId='+ product.vendorProductId);
        if (data.status === StatusCodes.OK)
        {
            await dispatch (
                getSaveForLaterProducts()
            )
            dispatch(
                setShoppingCart(data.payload)
            );

            const productsInCart = data.payload.vendorOrders.flatMap(vo => vo.products);
            const productAdded = productsInCart.find(p => p.vendorProductId == product.vendorProductId);
            if (productAdded) {
                modifyCartAnalyticsEvent([{...productAdded, originalQuantity: 0 }], userState, "Shopping Cart - Save For Later", false, getState().shoppingCart);
            }
        }
        else if (data.status === 206) {
           dispatch(
                reportError(`The product "${product.productTitle}" could not be updated to the quantity of ${product.quantity}.  No offer was found.`)
            )
            await dispatch (
                getSaveForLaterProducts()
            )
            dispatch(
                setShoppingCart(data.payload.shoppingCart)
            );
        }
    } catch (error) {
        dispatch(
            reportError(`We could not move "${product.productTitle}" to your cart.  Your session may have expired. Please login again.`)
        )
    }
}

export const deleteSaveForLaterProductFromServer = (vpId: number): AppThunk => async dispatch => {
     try{
        console.log("Deleting save for later Item vpId-" + vpId);
        await axios.post('/rest/neo/save-for-later/delete/'+vpId)
		await dispatch (getSaveForLaterProducts());

    }catch (error){
        console.log("Error while Getting save for later products.");
    }
}
export const moveProductToSaveForLaterListOnServer = (product: ShoppingCartVendorOrderProduct, userState: UserState): AppThunk => async (dispatch, getState) => {
    console.log("Moving cart product to save for later list on server.");
    try {
        const { status, data } = await axios.post<Melatonin.BasicRestfulResponse<ShoppingCartPayloadFromServer>>(
            '/rest/shoppingCart/saveForLater/rev2', 
            ('vpId=' + product.vendorProductId),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' } }
        );
        if (status === StatusCodes.OK)
        {
            // We run and await getSaveForLaterProducts() so the product doesn't disappear momentarily.
            await dispatch(
                getSaveForLaterProducts()
            )
            dispatch(
                setShoppingCart(data.payload)
            );
             modifyCartAnalyticsEvent([{...product, originalQuantity: product.quantity, quantity: 0}], userState, "Shopping Cart - Save For Later", false, getState().shoppingCart);
        }
    } catch (error) {
        dispatch(
            reportError(`We could not move "${product.productTitle}" to your save for later list.  Your session may have expired. Please login again.`)
        )
    } 
}
// ----------------------------------------------------------------------------------------------------
// Reorder Thunk Actions
export const getReorderProductsForCart = (postalCode: string, applyAuthorizedBoosting?: string, buyBoxFactorId?: string): AppThunk => async dispatch => {
    console.log("Getting shopping cart reorder products.");
    try {
        const { data } = await axios.get<ShoppingCartReorderProduct[]>('/rest/neo/shopping-cart/reorder-suggestions',{
            params: {
                postalCode,
                applyAuthorizedBoosting,
                buyBoxFactorId
            }
        });
        
        dispatch(createReorderProduct(data));
    } catch (exception) {
        console.error("Error calling reorder suggestions", exception);
    }
}  

export const addReorderItemToCart = (productsToModify: Array<ModifyCartProduct>, userState: UserState): AppThunk => async dispatch => {
    try {
        await dispatch(
            createOrUpdateShoppingCartProductOnServer({
                productsToModify, 
                userState: userState,
                source: "Shopping Cart - Replenishment",
                reportErrorsToReduxStore: true
            })
        );

    } 
    catch (error) {
        console.error("Errror adding a product to user cart via reorder (time to restock)", error );

        if (error.isAxiosError) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                const errorPayload = (axiosError.response.data as Melatonin.BasicRestfulResponse<QuickItemAddInventoryErrorPayload>).payload;
                switch (axiosError.response.status) {
                    // This means a product with acceptable inventory could not be found.
                    case StatusCodes.NOT_ACCEPTABLE:
                        productsToModify.forEach(product => {
                            const errorProduct = errorPayload[product.mpId];
                            const update = {
                                mpId: errorProduct.mpId,
                                maxInventory: errorProduct.totalInventory,
                                submittedAndFailureReturned: true,
                            }
                            if (errorProduct) {
                                dispatch(updateReorderProduct([update]))
                            }
                        })
                        break;
                    default:
                        dispatch(reportError("We could not add your product to the cart at this time.  Please try again shortly."));
                        break;
                }
            }
            else {
                dispatch(reportError("We could not add your product to the cart at this time.  Please try again shortly."));

            }
        }
    }
    
}

// ----------------------------------------------------------------------------------------------------
export const getReorderMpIdQuantityPrice = (mpId: number, quantity: number, postalCode: string): AppThunk => async dispatch => {
    try {
        const buyBoxFactorId = Cookies.get("buyBoxFactorIdExperiment")
        let url = `/rest/neo/pdp/${mpId}/get-best-deal-by-user-and-quantity-and-cart?quantity=${quantity}&postalCode=${postalCode}`;
        if (buyBoxFactorId) {
            url += `&buyBoxFactorId=${buyBoxFactorId}`
        }
        const { data } = await axios.get<VendorOptionFromServer>(url);
        const configuredPriceBreaks: VendorOptionPriceBreakState[] = data.priceBreaks.map((pb, index) => {
            return {
                ...pb,
                unitPrice: new BigDecimal(pb.unitPrice),
            }
        })
        
        const chosenPriceBreak = getPriceBreakForQuantity(quantity, configuredPriceBreaks);
        await dispatch(
            refreshReorderMpIdQuantityPrice(mpId, data.vendorProductId, chosenPriceBreak.minQty , chosenPriceBreak.unitPrice.toNumber(), data.inventory)
        );

    } 
    catch (error) {
        console.error("Errror refreshing reorder quantity price (time to restock)", error );
    }
}

// ----------------------------------------------------------------------------------------------------


// Free Shipping Suggestion Thunk Actions
export const getFreeShippingSuggestions = (): AppThunk => async dispatch => {
    try{
        const {status, data} = await axios.get('/rest/shoppingCart/getFreeShippingSuggestions');

        if (status === StatusCodes.OK) {
            dispatch(setFreeShippingSuggestionProduct(data.payload));
        }
        else {
            console.log("Free shipping suggestions returned a different status", status);
        }
    }
    catch (error){
        console.log("Error while getting free shipping suggestions");
    }
}

// ----------------------------------------------------------------------------------------------------
// this.modify(shoppingCart, ugrId, product.getQuantity(), product.getVpId(), product.getAbsoluteMinQty(), verId, culture,product.getMpId());
export type RevertProduct = Pick<Products.ShoppingCartSummaryProduct, "Quantity" | "VpId" | "AbsoluteMinQty" | "MpId">;
function convertCartProductToSummary(products: ShoppingCartVendorOrderProduct[]): RevertProduct[] {
    const summaryProducts: RevertProduct[] = [];

    products.forEach((product) => {
        summaryProducts.push({
            Quantity: product.quantity,
            MpId:  product.mpId,
            VpId: product.vendorProductId,
            AbsoluteMinQty: product.minimumQuantity,
        });
    });
    return summaryProducts;
}

// Consolidation cart Thunk Actions
export const getAppliedConsolidatedSavings = (shoopingCartSummaryProducts: Array<ShoppingCartVendorOrderProduct> ): AppThunk => async dispatch => {
    try{
        const {status, data} = await axios.get('/rest/shoppingCart/consolidateCart/rev2');
        if (status === StatusCodes.OK) {

            dispatch(setShoppingCartProductForRevert(convertCartProductToSummary(shoopingCartSummaryProducts)));
            console.log("Consolidated optimal savings successfully applied", status);
        }
        else {
            console.log("Consolidated cart operation sent a different status", status);
        }
    }
    catch (error){
        console.log("Error while applying optimal savings");
    }
}

// Consolidated cart undo Thunk Actions
export const applyUndoCart = (revertProducts: Array<RevertProduct> | null): AppThunk => async dispatch => {
    try{
        const { status, data } = await axios.post<ModifyShoppingCartResponse>('/rest/shoppingCart/revertCart/rev2', revertProducts);

        if (status === StatusCodes.OK) {
            console.log("Consolidated optimal savings successfully reverted", status);
        }
        else {
            console.log("Consolidated optimal revert operation sent a different status", status);
        }
    }
    catch (error){
        console.log("Error while reverting optimal savings");
    }
}


// Consolidated optimal savings Thunk Actions
export const getOptimalSavings = (): AppThunk => async dispatch => {
    try{
        const {status, data} = await axios.get('/rest/shoppingCart/optimalSavings');

        if (status === StatusCodes.OK) {
            dispatch(setOptimalCartSavings(data.payload));
        }
        else {
            console.log("Consolidated optimal savings a different status", status);
        }
    }
    catch (error){
        console.log("Error while getting optimal savings");
    }
}


export interface VendorProductDetail  {
    vendorProductId: number;
    manufacturerProductId: number;
    minQty: number;
    unitPrice: number;
    inventory: number;
}