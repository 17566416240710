import { ShoppingCartState } from "store/reducer/ec/shoppingCartReducer"
import { AnalyticsReportProductV2 } from "./analyticsEventSender"

export default function convertShoppingCartForAnalyticsReport(shoppingCart: ShoppingCartState): ReturnValue {
    const allShoppingCartItems: AnalyticsReportProductV2[] = shoppingCart.vendorOrders.flatMap(vo => {
        return vo.products.filter(p => !p.noLongerForSale).map((p): AnalyticsReportProductV2 => {
            return {
                item_id: p.mpId.toString(),
                price: p.unitPrice,
                quantity: p.quantity,
                item_name: p.productTitle
            }
        })
    });
    return {
        subtotal: shoppingCart.subTotal,
        cartItems: allShoppingCartItems
    }
}

type ReturnValue = {
    subtotal: number,
    cartItems: AnalyticsReportProductV2[]
}