import ProductImage from 'components/common/productImage';
import React, { useState, useEffect, FunctionComponent, useRef, useMemo } from 'react';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { showSideShoppingCart } from 'store/actions/ec/shoppingCartActions';
import { RootState } from 'store/reducer/ec/rootReducer';
import { ShoppingCartVendorOrderProduct } from 'store/reducer/ec/shopping-cart/shoppingCartTypes';
// import "./shoppingCartSummary.scss"

const ShoppingCartSummary: FunctionComponent = () => {
    const { user, shoppingCart } = useSelector(createSelector(
        (state: RootState) => state.user,
        (state: RootState) => state.shoppingCart,
        (user, shoppingCart) => ({
            user, shoppingCart
        })
    ))

	const dispatch = useDispatch()
    const location = useLocation();
	const { url } = useParams<{ url: string }>();
    const [showShoppingCartSummary, setShowShoppingCartSummary] = useState(false);
	const [isSideShoppingCartExperimentActive, setIsSideShoppingCartExperimentActive] = useState(false);
    const shoppingCartSummaryRef = useRef(null);
    let showShoppingCartTimeout: Timeout;

	// Temporarily, only while we are on the PDP page, we want to show the new side cart instead of the older summary cart.
	const isPdpPage =  new RegExp(/(-d-)(\d+)$/).test(url);

    const shoppingCartProductsToShow = useMemo<ShoppingCartVendorOrderProduct[]>(() => {
        const products = shoppingCart.vendorOrders.flatMap((vo) => {
            return vo.products
        }).filter(p => !p.noLongerForSale);
        products.sort((a, b) => {
            if (a.cartLineNumber < b.cartLineNumber)
                return 1;
            else if (a.cartLineNumber > b.cartLineNumber)
                return -1
            return 0;
        })
        return products.slice(0, 5);
    }, [shoppingCart.shoppingCartHash])

    useEffect(() => {
		closeShoppingCartSummaryMenu();
	}, [location])

    function showShoppingCartSummaryMenu() {
        clearTimeout(showShoppingCartTimeout);
        showShoppingCartTimeout = window.setTimeout(() => {
            setShowShoppingCartSummary(true);
        }, 50);
    }
    function closeShoppingCartSummaryMenu() {
        clearTimeout(showShoppingCartTimeout);
        showShoppingCartTimeout = window.setTimeout(() => {
            setShowShoppingCartSummary(false);
        }, 150);
    }

	useEffect(() => {
        const sideShoppingCatExperimentInterval = setInterval(() => {
            if (window.n32.isSideShoppingCartExperimentActive && window.n32.isSideShoppingCartExperimentActive === true && isSideShoppingCartExperimentActive === false) {
                setIsSideShoppingCartExperimentActive(true);
                clearInterval(sideShoppingCatExperimentInterval);
            }
        }, 200);

        return () => {
            clearInterval(sideShoppingCatExperimentInterval);
        }
    }, [isPdpPage]);

	const showSideShoppingCartWrapper = () => {
		if(isSideShoppingCartExperimentActive) {
			dispatch(showSideShoppingCart());
		}
	}
    
	const shoppingCartCounter = (<div className={`shopping-cart-summary-counter ${shoppingCart.totalQuantity === 0 ? 'd-none' : ''}`} data-greater-than-99={shoppingCart.totalQuantity > 99}>{shoppingCart.totalQuantity}</div>);


    return (
        <React.Fragment>
            <div 
				id="shopping-cart-summary" 
				ref={shoppingCartSummaryRef}
				onMouseOver={(isPdpPage && isSideShoppingCartExperimentActive )? showSideShoppingCartWrapper : showShoppingCartSummaryMenu}
				onMouseOut={closeShoppingCartSummaryMenu}
			>
				{
					shoppingCart.totalQuantity > 0 &&
						<React.Fragment>
							{shoppingCartCounter}
							<Overlay
								show={showShoppingCartSummary}
								target={shoppingCartSummaryRef.current as any}
								placement="bottom"
								container={shoppingCartSummaryRef.current as any}
								transition={true}
							>
								<Popover id="shopping-cart-summary-popover" className="">
									<Popover.Content>
									<div id="shopping-cart-summary-list">
											{shoppingCartProductsToShow.map((shoppingCartProduct) => {
												return (
													<div key={"shopping-cart-summary-"+shoppingCartProduct.mpId+"-"+shoppingCartProduct.vendorProductId+"-"+shoppingCartProduct.quantity} className="shopping-cart-summary-product">
														<div className="prod-image"><ProductImage src={"/media" + shoppingCartProduct.smallImagePath} /></div>
														<div className="prod-desc">
															<Link to={shoppingCartProduct.productLink}>{shoppingCartProduct.productTitle}</Link>
															<span>Quantity: {shoppingCartProduct.quantity}</span>
														</div>
													</div>
												)
											})}
									</div>
									<Button 
										as={Link}
										variant="secondary" 
										to="/shopping-cart"
									>
										View Cart ({shoppingCart.totalQuantity})
									</Button>
									</Popover.Content>
								</Popover>
							</Overlay>
						</React.Fragment>
				}
				<Link className="shopping-cart-summary-link" to="/shopping-cart">Shopping Cart</Link>
			</div>
			<div id="shopping-cart-summary-mobile">
				{shoppingCartCounter}
				<Link className="shopping-cart-summary-link" to="/shopping-cart">
					Shopping Cart
				</Link>
			</div>
        </React.Fragment>
    )
}

export default ShoppingCartSummary;