import verticalConstants from 'components/verticals/verticalConstants';
import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { AutoOrderType, getSelectedAutoOrderType } from '../callToAction';
import { SubscriptionFutureDatesPayloadFromServer } from '../productDetailPage';
import AutoOrderDescription from './autoOrderDescription';
import AutoOrderPopover from './autoOrderPopover';
import { useWindowWidth } from '@react-hook/window-size'

const AutoOrderMessageBox: FunctionComponent<AutoOrderMessageBoxProps> = ({ autoOrderPopover, selectedAutoOrder, subscriptionFutureDates, ...rest }) => {

    const target = useRef(null);
    
    const [mobileDetailsVisible, setMobileDetailsVisible] = useState(false);
    const [mobileFrequecyDetailsVisible, setMobileFrequecyDetailsVisible] = useState(false);
    const screenWidth = useWindowWidth();

    useEffect(() => {
        window.addEventListener('resize', onResize);
        
        // The following cleans up the resize attachment
        return () => {
            window.removeEventListener('resize', onResize);
        }
    });

    const onResize = () => {
        if (mobileDetailsVisible == true) {
            setMobileDetailsVisible(false);
        }
         
        if (mobileFrequecyDetailsVisible == true) {
            setMobileFrequecyDetailsVisible(false);
        }
    }

    const dateDetailMessage = (() => {
        return (
            <>
                {selectedAutoOrder && 
                    <>
                        <section>
                            <h1 className="tooltip-heading">Your next auto orders will be on</h1>
                            <ul className="auto-order-date-list">
                                {subscriptionFutureDates[getSelectedAutoOrderType(selectedAutoOrder).indexServer].FutureOrderDates.map((date, index) => {
                                    return (
                                        <li key={index}><span className="fast-shipping-icon"></span>{date}</li>
                                    );
                                })}
                            </ul>
                        </section>
                        <section>
                            <p className="tooltip-paragraph">This auto order will be placed every {getSelectedAutoOrderType(selectedAutoOrder).description} {getSelectedAutoOrderType(selectedAutoOrder).frequencyText} of the month.</p>
                        </section>
                    </>
                }
            </>
        );
    })();

    const nextPurchaseDateMessageTooltip = (() => {
        return (
            <>
                {selectedAutoOrder &&
                    <OverlayTrigger overlay={
                        <Popover id={`date-detail`}>
                            <Popover.Content>
                                {dateDetailMessage}
                            </Popover.Content>
                        </Popover>
                    }>
                        <a>{subscriptionFutureDates[getSelectedAutoOrderType(selectedAutoOrder).indexServer].NextOrderDate}</a>
                    </OverlayTrigger>
                }  
            </>
        );
    })();

    const autoOrderDetailsModal = (() => {
        return (
            <Modal show={mobileDetailsVisible} centered size="sm" onHide={() => setMobileDetailsVisible(false)} className="auto-order-info-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <AutoOrderDescription />
                </Modal.Body>
            </Modal>
        );
    })();

    const frequentPurchaseMessageWithModal = (() => {
        return (
            <>
                {selectedAutoOrder &&
                    <>
                        <span>Your first <a onClick={() => setMobileDetailsVisible(true)}>Auto Order</a> will be placed on <a onClick={() => setMobileFrequecyDetailsVisible(true)}>{subscriptionFutureDates[getSelectedAutoOrderType(selectedAutoOrder).indexServer].NextOrderDate}</a>. We'll send you a reminder before we process your order.</span>
                        {autoOrderDetailsModal}
                        
                        <Modal show={mobileFrequecyDetailsVisible} centered size="sm" onHide={() => setMobileFrequecyDetailsVisible(false)} className="auto-order-date-info-modal">
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                {dateDetailMessage}
                            </Modal.Body>
                        </Modal>
                    </>
                }
            </>
        );
    })();
    /**
     * This function will determine if the page is using a desktop layout.  As of writing this is using
     * bootstrap's default "XL" size to determine this, as well as accounts for a slight differnce in layout
     * referenced in productDetailPage.scss dealing with .add-to-cart-cta (at line 505 as of writing).
     * 
     * If the values here are changed, they need to be changed in that file as well.
     * @param width The width of the current browser window
     * @returns boolean
     */
    const isCTAUsingDesktopLayout = (width: number): boolean => {
        if (width >= 1200 && width <= 1400) {
            return false
        }
        else if (width > 1200) {
            return true;
        }
        else {
            return false;
        }
    }
    /** 
     * Returns either a version of <AutoOrderPopover /> controlled by the parent if the desktop layout
     * is in use, or one that is controlled by itself if not.
     */
    const chosenAutoOrderPopover = isCTAUsingDesktopLayout(screenWidth) === true ? autoOrderPopover : <AutoOrderPopover />;

    return (
        <>
            <div className="auto-order-message-box-info mouse-visible">
                {selectedAutoOrder === AutoOrderType.ONE_TIME.index && <span>
                    Do you order regularly? Try {chosenAutoOrderPopover} and let {verticalConstants.verticalName.firstCharacterUpperCase} place orders for you!
                </span>}
                {selectedAutoOrder !== AutoOrderType.ONE_TIME.index && <span>
                    Your first {chosenAutoOrderPopover} will be placed on {nextPurchaseDateMessageTooltip}. We'll send you a reminder before we process your order.
                </span>}
            </div>
            <div className="auto-order-message-box-info touch-visible">
                {selectedAutoOrder === AutoOrderType.ONE_TIME.index && <>
                    <span>Do you order regularly? Try <a onClick={() => setMobileDetailsVisible(true)}>Auto Order</a> and let {verticalConstants.verticalName.firstCharacterUpperCase} place orders for you!</span>
                    {autoOrderDetailsModal}
                </>}
                {selectedAutoOrder !== AutoOrderType.ONE_TIME.index && <>{frequentPurchaseMessageWithModal}</>}
            </div>
        </>
    );

}

type AutoOrderMessageBoxProps = {
    selectedAutoOrder: number;
    subscriptionFutureDates: SubscriptionFutureDatesPayloadFromServer[]
    autoOrderPopover: React.ReactNode
}

export default AutoOrderMessageBox;