import { CategoriesState } from "store/reducer/ec/categoriesReducer";
import { Action } from "redux";

// ====================================================================================================
// Action Types
export const CATEGORIES_TOP_CAT_UPDATE = "categories:Update Top Categories";
export interface TopCategoriesUpdateAction extends Action {
    type: typeof CATEGORIES_TOP_CAT_UPDATE,
    payload: CategoriesState['topCategories']
}
export type CategoryActionTypes = TopCategoriesUpdateAction;


// ====================================================================================================
// Actual Actions
export function updateTopCategories(topCategories: CategoriesState['topCategories']): CategoryActionTypes {
    return {
        type: CATEGORIES_TOP_CAT_UPDATE,
        payload: topCategories
    }
}
