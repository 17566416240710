import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import store from 'store/ecStore';
import Preloader from 'components/common/preloader';
import { FullPageRoute, FullPage } from 'layouts/ec/full-page/fullPage';
import { getBasicApplicationState } from 'store/actions/ec/appLevelActions';
import TagManager from 'react-gtm-module'
import apiKey from 'components/common/apiKey';
import NotFound from 'components/ec/error-pages/notFound';
import { SqueezePageRoute } from 'layouts/ec/squeezePage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import DebouncedLoader from 'components/debouncedLoader';
import SpecializedURLRouter from 'components/specializedURLRouter';
import SpecializedBuyGetURLRouter from 'components/specializedBuyGetURLRouter';


import '../theme/ec/custom.scss';
import { getShoppingCart } from 'store/actions/ec/shoppingCartActions';
import AddToCartViaExperimentWatcher from 'components/ec/addToCartViaExperimentWatcher';
import { InstantSearch } from 'react-instantsearch';
import { searchClient, defaultIndex } from 'layouts/ec/full-page/search/algolia/constants';

// %%% > This line is for the converter script 
const AutoOrderPage = React.lazy(() => import('components/ec/account/orders/auto-order/autoOrderWrapper'));
const PopularAutoOrderProducts = React.lazy(() => import('components/ec/account/orders/auto-order/popularAutoOrderProducts'));
const AutoOrderChangeShippingPage = React.lazy(() => import('components/ec/account/shipping/autoOrderChangeShippingPage'));
const AutoOrderEditShippingAddressPage = React.lazy(() => import('components/ec/account/shipping/autoOrderEditShippingAddressPage'));
const AutoOrderChangeBillingWrappe = React.lazy(() => import('components/ec/account/billing/autoOrderChangeBillingWrapper'));
const OrderHistoryPage = React.lazy(() => import('components/ec/account/orders/history/orderHistory'))
const OrderDetailPage = React.lazy(() => import('components/ec/account/orders/detail/orderDetail'))
const ShoppingCartPage = React.lazy(() => import('components/ec/shopping-cart/shoppingCartPage'));

const ShoppingCartAcknowledgementPage = React.lazy(() => import('components/ec/shopping-cart/shoppingCartAcknowledgement'))

const SSOLoginPage = React.lazy(() => import('components/ec/account/login/ssoLoginPage'))
const Homepage = React.lazy(() => import('components/ec/homepage/homepage'))
const HomepageV2 = React.lazy(() => import('components/ec/homepage/homepageV2'))
const VendorDetailPage = React.lazy(() => import('components/ec/vendors/vendorDetailPage'));

const SearchPage = React.lazy(() => import('components/ec/catalog/search/searchPage'));
const WeeklySpecialsPage = React.lazy(() => import('components/ec/weekly-specials/weeklySpecialsPage'));
const ECExamples = React.lazy(() => import('components/ec/ecExamples'))
// < %%% - This line is for the converter script 

// This will check with the server if we have a current user
store.dispatch(getBasicApplicationState() as any);
store.dispatch(getShoppingCart() as any);

const target = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={apiKey.googleRecatpchaV3}>
            <React.Suspense fallback={<DebouncedLoader />}>
                <AddToCartViaExperimentWatcher />
                <InstantSearch searchClient={searchClient} indexName={defaultIndex} insights={true}>
                <Router>
                    <Switch>
                        <Route
                            exact
                            path={["/ec/:url", "/ec/product-detail/:url", "/new/ec/:url", "/old/ec/:url"]}
                        >
                            <SpecializedURLRouter />
                        </Route>

                        <FullPageRoute
                            exact
                            // /search is still supported for ?tag= urls.
                            path={["/old/search", '/search']}
                            pageName="searchPage"
                            component={SearchPage}
                        />e

                        <FullPageRoute
                            exact
                            path="/old/weekly-specials"
                            pageName="weeklySpecialsPage"
                            component={WeeklySpecialsPage}
                        // pageCSSLink={"/static/dist/"+ verticalConstants.verticalName.lowerCase +"/css/search.css"}
                        />

                        <FullPageRoute path="/account/orders" pageName="orderHistory" exact component={OrderHistoryPage} />
                        <Route path="/account/orders/details/:coId" component={OrderDetailPage} />
                        <FullPageRoute path="/shopping-cart" pageName="shoppingCart" component={ShoppingCartPage} />
                        <FullPageRoute path="/old/shopping-cart-acknowledgement/:mpId/:vpId" pageName="shoppingCartAcknowledgement" component={ShoppingCartAcknowledgementPage} />
                        <FullPageRoute path="/account/orders/auto-order" pageName="autoOrder" exact component={AutoOrderPage} />
                        <Route path="/account/orders/auto-order/change/shipping/:subscriptionId/:addressId?/edit/:addressIdToEdit" component={AutoOrderEditShippingAddressPage} />
                        <Route path="/account/orders/auto-order/change/shipping/:subscriptionId/:addressId?" component={AutoOrderChangeShippingPage} />
                        <Route path="/account/orders/auto-order/change/billing/:subscriptionId" component={AutoOrderChangeBillingWrappe} />

                        <FullPageRoute path="/account/orders/auto-order/suggestions/popular" pageName="autoOrderPopularSuggestions" component={PopularAutoOrderProducts} />
                        <Route path="/m" render={(props) => {
                            window.location.href = props.location.pathname;
                            return (
                                <FullPage pageName="">
                                    <Preloader textUnderAnimation="One moment..."></Preloader>
                                </FullPage>
                            )
                        }} />

                        <FullPageRoute
                            pageName='vendorDetailPage'
                            path={[
                                '/old/vendor/:vendorId',
                                '/old/vendor/:vendorName/:vendorId'
                            ]}
                            exact
                            component={VendorDetailPage}
                        />

                        {/* <VerticalDependentRouter 
                            path={["/create-account", "/checkout/create-account"]}
                            net32Component={<SqueezePage pageName="createAccount" showSecureTransactionInFooter={false}>
                                <React.Suspense fallback={
                                    <section><Preloader textUnderAnimation="Loading" /></section>
                                }>
                                    <CreateAccountPage />
                                </React.Suspense>
                            </SqueezePage>}
                            med32Component={<SqueezePage pageName="createAccount" showSecureTransactionInFooter={false}>
                                <React.Suspense fallback={
                                    <section><Preloader textUnderAnimation="Loading" /></section>
                                }>
                                    <OldCreateAccountPage />
                                </React.Suspense>
                            </SqueezePage>}
                         /> */}
                        
                        <SqueezePageRoute path="/login/sso/:type" pageName="ssoLogin" showSecureTransactionInFooter={false} component={SSOLoginPage} />
                        <FullPageRoute path="/new/ec/examples" exact pageName="ecExamples" component={ECExamples} />
                        <FullPageRoute
                            path={'/new/homepage'}
                            exact
                            pageName="homepageV2"
                            component={HomepageV2}
                            preventDisplayOfOfflinePage={true}
                        />
                        <FullPageRoute
                            path={['/old/home-page']}
                            exact
                            pageName="homepage"
                            component={Homepage}
                            preventDisplayOfOfflinePage={true}
                        />

                        <Route
                            exact
                            path={[
                                // "/promo/buy-get/:manufacturerParam/:url",
                                // "/promo/buy-get/:manufacturerParam",
                                "/old/promo/buy-get/:manufacturerParam/:url",
                                "/old/promo/buy-get/:manufacturerParam",
                            ]}
                        >
                            <SpecializedBuyGetURLRouter />
                        </Route>

                        <FullPageRoute component={NotFound} disablePageViewReport={true} pageName="notFoundPage" />
                    </Switch>
                </Router>
                </InstantSearch>
            </React.Suspense>
        </GoogleReCaptchaProvider>
    </Provider>
    , target
);
document.body.setAttribute('data-finished-loading', "true");

const underSiteSpeedTest = location.search.indexOf('siteSpeedTest') >= 0;
try {
    if (underSiteSpeedTest === false) {
        if (apiKey.googleTagManager) {
            TagManager.initialize({
                gtmId: apiKey.googleTagManager,
                auth: apiKey.googleTagManagerAuth,
                preview: apiKey.googleTagManagerEnv
            })
        }
    }
    else {
        console.log("GTM disabled as this page is under a site speed test.");
    }
} catch (error) {
    console.error("Could not start GTM: ", error);
}
