import React, { useState, useEffect, FunctionComponent, Component, useMemo, ImgHTMLAttributes } from 'react';
import { useImage } from 'react-image'
import verticalConstants from 'components/verticals/verticalConstants';
import fixImageURI from './fixImageURI';
import { ErrorBoundary } from "react-error-boundary";

export const productImageNotFoundImagePath = "/media/" + verticalConstants.verticalSuppliesType.lowerCase + "/common/misc/not-found.png";
/**
 * Handles product images including fixing urls from the backend and providing a backup image for not-found images.
 * 
 * Note that there is a wrapper around this component that is pre-supplied that provides an error boundry and will temporarily replace the 
 * intended image with a white gif 1px wide/tall.  This typically only shows up at the first render.  
 */
const ProductImage: FunctionComponent<ImageProps> = (props) => {

    return (
        <ErrorBoundary fallback={<img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" className={props.className} id={props.id} />}>
            <React.Suspense
                fallback={<img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" className={props.className} id={props.id} />}
            >
                <ActualProductImage
                    {...props}
                />
            </React.Suspense>
        </ErrorBoundary>
    )
}

const ActualProductImage: FunctionComponent<ImageProps> = ({ src: incomingSRC = "", alt, title, ...rest }) => {
    const imageList = useMemo<string[]>(() => {
        let list: Array<string> = [];
        if (Array.isArray(incomingSRC)) {
            list = list.concat(incomingSRC);
        }
        else if (incomingSRC) {
            list.push(incomingSRC);
        }
        list.push(productImageNotFoundImagePath);
        list = list.map(uri => {
            return fixImageURI(uri);
        })
        return list
    }, [Array.isArray(incomingSRC) ? JSON.stringify(incomingSRC) : incomingSRC]) 

    const { src } = useImage({
        srcList: imageList,
    })
    
    return (
        <img
            src={src}
            data-original-image={JSON.stringify(incomingSRC)}
            alt={alt}
            title={title ? title : alt}
            {...rest}
        />
    )
}

export default ProductImage;

interface ImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
    src: string | string[] | undefined
}