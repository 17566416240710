/* 
Why the "as unknown as boolean"? 
This is because these strings are being replaced by true/false values via webpack.  This syntax tells the typscript type checker that these are (or will be when used) booleans instead of strings
*/

const featuresEnabled = {
    autoOrder: true as unknown as boolean,
    takeDownRequest: "%takedownRequestEnabled%" as unknown as boolean,
    socialMediaLinks: true as unknown as boolean,
    apim: true as unknown as boolean,
    algolia: true,
}

export default featuresEnabled;