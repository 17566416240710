import { UserState, PaymentCardState, AddressState } from "store/reducer/userReducer";
import { AppThunk } from "store/reducer/ec/rootReducer";
import { Action } from "redux";

import Cookies from "js-cookie";

import axios from "axios";
import { StatusCodes } from "http-status-codes";

// ====================================================================================================
// Action Types
export const USER_LOGIN = "user:Login";
export const USER_LOGOUT = "user:Logout";
export const USER_UPDATE = "user:Update";
export const USER_SUBSCRIBE = "user:SubscribeoEmail"
export const USER_DELETE_CC_EMAIL = "user:DeleteCCEmail";
export const USER_LOAD_FULL_ACCOUNT_INFO = "user:Loading full account info"
export const USER_PAYMENT_ADD = "user: Add Payemnt Card";
export const USER_PAYMENT_UPDATE = "user: Update payment method";
export const USER_PAYMENT_MARK_FOR_REMOVAL = "user: Mark payment method for removal";
export const USER_PAYMENT_MARK_AS_PRIMARY = "user: Mark payment method as primary";
export const USER_SHIPPING_ADD = "user: Add Shipping Address";
export const USER_SHIPPING_ADD_TO_STORE = "user: Add Shipping Address to store";
export const USER_SHIPPING_UPDATE = "user: Update shipping address";
export const USER_SHIPPING_MARK_FOR_REMOVAL = "user: Mark shipping address for removal";
export const USER_SHIPPING_MARK_AS_PRIMARY = "user: Mark shipping address as primary";
export const USER_NAME_UPDATE = "user: Update Name";
export const USER_EMAIL_UPDATE = "user: Update Email";
export const USER_CC_EMAIL_UPDATE = "user: Update CC Email";
export const USER_CC_EMAIL_LIST_UPDATE = "user: Update CC Email List";
export const USER_LICENSE_UPDATE = "user: Update License";
export const USER_DELETE_LICENSE = "user:Delete License";
export const USER_UPDATE_PROFILE = "user:Update Profile";
export const USER_ADMIN_VERIFY = "user:Set Professsional and Verified statuses"
export const USER_BACKEND_ERROR = "user:Error"
enum FieldMaxLengths {
	NAME_LENGTH = 50,
	ADDRESS_LENGTH = 35
}

export interface UserUpdateAction extends Action {
    type: typeof USER_UPDATE,
    payload: {
        CurrentUser: WindfallRestfulResponse.ResponsePieces.CurrentUser | { IsGuestUser: true },
        IsAuthorized: boolean,
    }
}
export interface UserLogoutAction extends Action {
    type: typeof USER_LOGOUT
}
export interface UserMyAccountAction extends Action {
    type: typeof USER_LOAD_FULL_ACCOUNT_INFO,
    payload: MyAccountInfoUserPayload
}
export interface UserDeleteCCEmailAction extends Action {
	type: typeof USER_DELETE_CC_EMAIL,
	payload: {
		dleId: string|number
	}
}
export interface UserAddPaymentAction extends Action {
	type: typeof USER_PAYMENT_ADD,
	payload: Account.Billing.CreditCard
}
export interface UserUpdatePaymentMethodAction extends Action {
	type: typeof USER_PAYMENT_UPDATE,
	payload: PaymentCardUpdatePayload
}
type PaymentCardUpdatePayload = Partial<PaymentCardState> & Pick<PaymentCardState, "CardId">
export interface UserMarkPaymentMethodAsPrimary extends Action {
	type: typeof USER_PAYMENT_MARK_AS_PRIMARY,
	payload: {
		CardId: string,
	}
}
export interface UserBackendErrorAction extends Action {
	type: typeof USER_BACKEND_ERROR
	value: boolean,
	message: string
}
export interface UserAddShippingAction extends Action {
	type: typeof USER_SHIPPING_ADD,
	payload: AddressState
}
export interface UserAddShippingAddressToStore extends Action {
	type: typeof USER_SHIPPING_ADD_TO_STORE,
	payload: Account.Addresses.Address
}
export interface UserUpdateShippingAddressAction extends Action {
	type: typeof USER_SHIPPING_UPDATE,
	payload: ShippingAddressUpdatePayload
}
type ShippingAddressUpdatePayload = Partial<AddressState> & Pick<AddressState, "Id">
export interface UserMarkShippingAddressAsPrimary extends Action {
	type: typeof USER_SHIPPING_MARK_AS_PRIMARY,
	payload: {
		Id: string,
	}
}
export interface UserUpdateNameAction extends Action {
	type: typeof USER_NAME_UPDATE,
	payload: {
		Prefix: Account.NamePrefixes,
		FirstName: string,
		MiddleName: string,
		LastName: string,
		Suffix: string
	}
}
export interface UserUpdateEmailAction extends Action {
	type: typeof USER_EMAIL_UPDATE,
	payload: {
		EmailAddress: string
	}
}
export interface UserUpdateCCEmailAction extends Action {
	type: typeof USER_CC_EMAIL_UPDATE,
	payload: {
		dleId: string|number
		EmailAddress: string
	}
}
export interface UserUpdateCCEmailListAction extends Action {
	type: typeof USER_CC_EMAIL_LIST_UPDATE,
	payload: {
		DistributionLists: Array<DistributionListFromAddCCEmailPayload>
	}
}
export interface UserUpdateLicenseAction extends Action {
	type: typeof USER_LICENSE_UPDATE,
	payload: {
		Licenses: Account.Licenses.List
	}
}
export interface UserDeleteLicenseAction extends Action {
	type: typeof USER_DELETE_LICENSE,
	payload: {
		Id: string|number
	}
}
export interface UserUpdateProfileAction extends Action {
	type: typeof USER_UPDATE_PROFILE,
	payload: {
		BusinessTypeId?: number,
		BusinessTypeName?: string,
		BusinessTypeParentId?: number,
		BusinessTypeParentName?: string,
		BusinessOtherText?: string
	}
}
export interface UserAdminVerifyAction extends Action {
	type: typeof USER_ADMIN_VERIFY,
	payload: {
		IsProfessionalOffice: boolean,
		ProfessionVerifier: number|null
	}
}

export type UserActionTypes = UserUpdateAction | UserLogoutAction | UserMyAccountAction | UserDeleteCCEmailAction | 
								UserAddPaymentAction | UserUpdatePaymentMethodAction | UserMarkPaymentMethodAsPrimary | 
								UserAddShippingAction | UserAddShippingAddressToStore | UserUpdateShippingAddressAction | UserMarkShippingAddressAsPrimary | 
								UserUpdateNameAction | UserUpdateEmailAction | UserUpdateCCEmailAction | UserUpdateCCEmailListAction | UserUpdateLicenseAction | UserDeleteLicenseAction | UserUpdateProfileAction |
								UserAdminVerifyAction | UserBackendErrorAction

// ====================================================================================================
// Actual Actions

export function updateUser(user: WindfallRestfulResponse.ResponsePieces.CurrentUser | { IsGuestUser: true }, isAuthorized?:boolean): UserUpdateAction {
    return {
        type: USER_UPDATE,
        payload: {
            CurrentUser: user,
            IsAuthorized: isAuthorized ? isAuthorized: false,
		}
    }
}

export const getMyAccountPayload = (): AppThunk => async dispatch => {
	const response = await fetch("/rest/user/myAccountInfo?dltId=1", {
		method: "GET", // *GET, POST, PUT, DELETE, etc.
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json"
		},
		redirect: "follow", // manual, *follow, error
	})
   if (response.ok === true) {
		const payload = await response.json() as WindfallRestfulResponse.ResponsePacket<MyAccountInfoUserPayload>;
		if (payload.Payload)
			dispatch(myAccountAddToStoreAction(payload.Payload));
   }
   else {
		console.log("### FAILED: ", response);
   }
}

export function myAccountAddToStoreAction(user: MyAccountInfoUserPayload): UserMyAccountAction{
    return {
        type: USER_LOAD_FULL_ACCOUNT_INFO,
        payload: user
    }
}

export const modifyName = (prefix: Account.NamePrefixes | string, firstName: string, middleName: string, lastName: string, suffix: string, successCallback: () => void): AppThunk => async dispatch => {
	const formData = new URLSearchParams();
	formData.append('prefix', prefix.toString());
	formData.append('firstName', firstName.trim());
	formData.append('middleName', middleName.trim());
	formData.append('lastName', lastName.trim());
	formData.append('suffix', suffix.trim());
	try {
		const response = await fetch('/rest/user/modifyName', {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json"
			},
			body: formData.toString()
		})
		if (response.ok === true) {
			const responsePacket = await response.json() as WindfallRestfulResponse.ResponsePacket;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("Name change successful!");
					dispatch(myAccountUpdateName(prefix.toString(), firstName.trim(), middleName.trim(), lastName.trim(), suffix.trim()));
					successCallback();
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("Name change failed: ", error);
	}
}

export const changePassword = (oldPassword: string, newPassword: string, successCallback: () => void): AppThunk => async dispatch => {
	const formData = new URLSearchParams();
	formData.append('oldPassword', oldPassword);
	formData.append('newPassword', newPassword);
	try {
		const response = await fetch('/rest/user/modifyPassword', {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json"
			},
			body: formData.toString()
		})
		if (response.ok === true) {
			const responsePacket = await response.json() as WindfallRestfulResponse.ResponsePacket;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("Password changed successfully!");
					successCallback();
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				case 'SC_ERROR_PASSWORD_LENGTH_EXCEEDED':
					console.log("Password length exceeded!");
					dispatch(myAccountBackendError(true, "Maximum password length of 30 characters exceeded. Please try again."))
					break;
				case 'SC_ERROR_OLD_PASSWORD_IS_WRONG':
					console.log("Old password is incorrect!");
					dispatch(myAccountBackendError(true, "Your current password was incorrect. Please try again."))
					break;
				case 'SC_ERROR_PASSWORD_USED':
					console.log("New password has already been used!");
					dispatch(myAccountBackendError(true, "A previous password may not be reused."))
					break;
				case 'SC_ERROR_SAME_PASSWORD':
					console.log("Old and new passwords are the same!");
					dispatch(myAccountBackendError(true, "New password is same as current password."))
					break;
				case 'SC_ERROR_WEAK_PASSWORD':
					console.log("Password too weak!");
					dispatch(myAccountBackendError(true, "New password is too weak."))
					break;
				default:
					dispatch(myAccountBackendError(true, "An unknown error has occurred"))
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("Password change failed: ", error);
	}
}

export const modifyEmail = (newEmail: string, successCallback: () => void): AppThunk => async dispatch => {
	let emailRegistered = true; // Assume email is already registered (safer scenario)
	const formData = new URLSearchParams();
	formData.append('newEmail', newEmail.trim());
	try {
		const response1 = await fetch('/rest/user/verifyEmailAddress/' + newEmail.trim(), {
			method: "GET", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json"
			}
		})
		if (response1.ok === true) {
			const responsePacket = await response1.json() as WindfallRestfulResponse.ResponsePacket;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("Email already in use!");
					dispatch(myAccountBackendError(true, "This email is already registered."))
					break;
				case 'SC_ERROR_EMAIL_ALREADY_EXISTS':
					console.log("Email exists as ugr name for different user");
					dispatch(myAccountBackendError(true, "This email is already registered."))
					break;
				case 'SC_ERROR_EMAIL_ADDRESS_NOT_FOUND':
					emailRegistered = false;
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred validating your email address";
		}
		if (! emailRegistered)
		{
			const response2 = await fetch('/rest/user/modifyEmail', {
				method: "POST", // *GET, POST, PUT, DELETE, etc.
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					"Accept": "application/json"
				},
				body: formData.toString()
			})
			if (response2.ok === true) {
				const responsePacket = await response2.json() as WindfallRestfulResponse.ResponsePacket;
				switch (responsePacket.CallHeader.StatusCode) {
					case 'SC_OK':
						console.log("Email changed successfully!");
						dispatch(myAccountUpdateEmail(newEmail.trim()));
						successCallback();
						break;
					case 'SC_ERROR_INVALID_USER':
						console.log("Currently logged out!  Redirecting to login");
						window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
						break;
					case 'SC_ERROR_EMAIL_ALREADY_EXISTS':
						console.log("Email already exists!");
						dispatch(myAccountBackendError(true, "This email is already registered."))
						break;
					default:
						throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
				}
			} else {
				throw "An unknown error has occurred modifying your email address";
			}
		}
	} catch (error) {
		console.error("Email change failed: ", error);
	}
}

export const modifyCCEmail = (newEmail: string, dleId: number|string, dltId: number|string, successCallback: () => void): AppThunk => async dispatch => {
	console.log("NEW EMAIL: ", newEmail);
	const formData = new URLSearchParams();
	formData.append('newEmail', newEmail.trim());
	formData.append('dleId', dleId.toString());
	formData.append('dltId', dltId.toString());
	try {
		const response = await fetch('/rest/user/modifyCCEmail', {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json"
			},
			body: formData.toString()
		})
		if (response.ok === true) {
			const responsePacket = await response.json() as WindfallRestfulResponse.ResponsePacket;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("CC Email updated!");
					dispatch(myAccountUpdateCCEmail(dleId, newEmail.trim()));
					successCallback();
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				case 'SC_ERROR_EMAIL_ALREADY_EXISTS':
					console.log("Email already on CC list!");
					dispatch(myAccountBackendError(true, "The email(s) entered already receiving order emails."))
					break;
				case 'SC_ERROR_INVALID_DLEID':
					console.log("Invalid distribution list id!");
					dispatch(myAccountBackendError(true, "You are not authorized to modify this email id"))
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("CC email update failed: ", error);
	}
}

export const createNewCCEmail = (newEmail: string, dltId: number|string, successCallback: () => void): AppThunk => async dispatch => {
	const formData = new URLSearchParams();
	formData.append('email', newEmail.trim());
	formData.append('dltId', dltId.toString());
	try {
		const response = await fetch('/rest/user/createNewCCEmail', {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json"
			},
			body: formData.toString()
		})
		if (response.ok === true) {
			const responsePacket = await response.json();
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("CC Email added!");
					dispatch(myAccountUpdateCCEmailList(responsePacket.Payload.DistributionLists));
					successCallback();
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				case 'SC_ERROR_EMAIL_ALREADY_EXISTS':
					console.log("Email already on CC list!");
					dispatch(myAccountBackendError(true, "The email(s) entered already receiving order emails."))
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("CC email addition failed: ", error);
	}
}

export const deleteCCEmail = (dleId: number|string, dltId: number|string): AppThunk => async dispatch => {
	const formData = new URLSearchParams();
	formData.append('dleId', dleId.toString());
	formData.append('dltId', dltId.toString());
	try {
		const response = await fetch('/rest/user/deleteCCEmail', {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json"
			},
			body: formData.toString()
		})
		if (response.ok === true) {
			const responsePacket = await response.json() as WindfallRestfulResponse.ResponsePacket;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("CC Email deleted!");
					dispatch(myAccountDeleteCCEmail(dleId));
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				case 'SC_ERROR_INVALID_DLEID':
					console.log("Invalid distribution list id!");
					dispatch(myAccountBackendError(true, "You are not authorized to modify this email id"))
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("CC email addition failed: ", error);
	}
}

export function myAccountUpdateName(prefix: Account.NamePrefixes | string, firstName: string, middleName: string, lastName: string, suffix: string): UserUpdateNameAction{
	return {
		type: USER_NAME_UPDATE,
		payload: {
			Prefix: prefix,
			FirstName: firstName, 
			MiddleName: middleName, 
			LastName: lastName, 
			Suffix: suffix
		}
	}
}
export function myAccountUpdateEmail(email: string): UserUpdateEmailAction{
	return {
		type: USER_EMAIL_UPDATE,
		payload: {
			EmailAddress: email
		}
	}
}
export function myAccountUpdateCCEmail(dleId: string|number, email: string): UserUpdateCCEmailAction{
	return {
		type: USER_CC_EMAIL_UPDATE,
		payload: {
			dleId: dleId,
			EmailAddress: email
		}
	}
}
export function myAccountUpdateCCEmailList(distributionList: Array<DistributionListFromAddCCEmailPayload>): UserUpdateCCEmailListAction{
	return {
		type: USER_CC_EMAIL_LIST_UPDATE,
		payload: {
			DistributionLists: distributionList
		}
	}
}
export function myAccountDeleteCCEmail(dleId: string|number): UserDeleteCCEmailAction{
    return {
		type: USER_DELETE_CC_EMAIL,
		payload: {
			dleId: dleId
		}
    }
} 



export const updateMultilocationSwitch = (userId: number | string, checkboxState: boolean): AppThunk => async dispatch => {
	const {status} = await axios.post<MultiLocationPostBody>(`/rest/neo/registration/multilocation`, {
		userId: userId,
		status: checkboxState,
	});
	if (status === StatusCodes.OK){
		return StatusCodes.OK;
	}
	else if (status === StatusCodes.UNAUTHORIZED) {
		return StatusCodes.UNAUTHORIZED;
	}
	else {
		return StatusCodes.INTERNAL_SERVER_ERROR
	}
}
type MultiLocationPostBody = {
    userId: number,
    status: boolean
}
export const licenseUpdate = (licenses: Account.Licenses.List, successCallback: () => void): AppThunk => async dispatch => {
	try {
		const response = await fetch("/rest/user/modifyLicense", {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			redirect: "follow", // manual, *follow, error
			body: JSON.stringify(licenses)

		})
		if (response.ok === true) {
			const responsePacket = await response.json() as WindfallRestfulResponse.ResponsePacket<Account.Licenses.List>;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("License Information updated successfully!");
					dispatch(myAccountUpdateLicense(responsePacket.Payload))
					successCallback();
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
					window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				case 'SC_ERROR_SYSTEM_ERROR':
					console.log("System error!");
					dispatch(myAccountBackendError(true, "A system error has occurred during the license update"))
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		}
		else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("License update failed: ", error);
	}
}
export function myAccountUpdateLicense(licenses: Account.Licenses.List): UserUpdateLicenseAction{
	return {
		type: USER_LICENSE_UPDATE,
		payload: {
			Licenses: licenses
		}
	}
}

export const deleteLicense = (licenseId: string): AppThunk => async dispatch => {
	const formData = new URLSearchParams();
	formData.append('id', licenseId);
	try {
		const response = await fetch('/rest/user/deleteLicense', {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json"
			},
			body: formData.toString()
		})
		if (response.ok === true) {
			const responsePacket = await response.json() as WindfallRestfulResponse.ResponsePacket;
			switch (responsePacket.CallHeader.StatusCode) {
				case 'SC_OK':
					console.log("License deleted!");
					dispatch(myAccountDeleteLicense(licenseId));
					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
					window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				case 'SC_ERROR_SYSTEM_ERROR':
					console.log("System erro!");
					dispatch(myAccountBackendError(true, "A system error has occurred"));
					break;
				default:
					throw "An unhandled error has occurred: " + responsePacket.CallHeader.StatusCode;
			}
		} else {
			throw "An unknown error has occurred";
		}
	} catch (error) {
		console.error("license delete failed: ", error);
	}
}
export function myAccountDeleteLicense(id: string|number): UserDeleteLicenseAction{
	return {
		type: USER_DELETE_LICENSE,
		payload: {
			Id: id
		}
	}
}

export function myAccountUpdateProfile(btId: number|undefined, btName: string|undefined, btpId: number|undefined, btpName: string|undefined, other: string|undefined): UserUpdateProfileAction{
	return {
		type: USER_UPDATE_PROFILE,
		payload: {
			BusinessTypeId: btId,
			BusinessTypeName: btName,
			BusinessTypeParentId: btpId,
			BusinessTypeParentName: btpName,
			BusinessOtherText: other
		}
	}
}

export const subscribeUser = ({emailAddress, subscriptionType, source}: subscribeUserProps): AppThunk => async dispatch => {
    const response = await fetch("/rest/subscriptionService/subscribe", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        redirect: "follow", // manual, *follow, error
        body: JSON.stringify({
            emailAddress: emailAddress,
            subscriptionType: subscriptionType,
            source: source
        })
    })
    if (response.ok === true) {
        const payload = await response.json() as WindfallRestfulResponse.ResponsePacket;

    }
}
export function myAccountBackendError(value: boolean, message: string): UserBackendErrorAction{
    return {
		type: USER_BACKEND_ERROR,
		value: value,
		message: message
    }
}
export const updatePaymentMethod = (creditCard: PaymentCardUpdatePayload): UserUpdatePaymentMethodAction => {
	return {
		type: USER_PAYMENT_UPDATE,
		payload: creditCard
	}
}
export const addPaymentMethod = (creditCard: Account.Billing.CreditCard): UserAddPaymentAction => {
	return {
		type: USER_PAYMENT_ADD,
		payload: creditCard,
	}
}
export const removePaymentMethod = (cardId: string, isPrimary = false): AppThunk => async dispatch => {
	dispatch(updatePaymentMethod({
		CardId: cardId,
		removalStatus: "processing"
	}))
	const response = await fetch("/rest/user/deleteCreditCard", {
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/x-www-form-urlencoded",
		},
		redirect: "follow", // manual, *follow, error
		body: ('cardId='+cardId) , // body data type must match "Content-Type" header
	})
	if (response.ok === true) {
		const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket & {Errors?: Array<string>};
		if (responsePayload.CallHeader.StatusCode == "SC_OK") {
			dispatch(updatePaymentMethod({
				CardId: cardId,
				removalStatus: "removed"
			}));
			if (isPrimary === true)
			{
				// This means we need to renew the data from the backend, as we can't easily determine
				// which card was marked as the primary card.
				dispatch(getMyAccountPayload());
			}
		}
		else if (responsePayload.Errors) {
			
			dispatch(updatePaymentMethod({
				CardId: cardId,
				removalStatus: "failed",
				removalFailureReason: responsePayload.Errors
			}))
		}
		
	}
	else {
		dispatch(updatePaymentMethod({
			CardId: cardId,
			removalStatus: "failed",
			removalFailureReason: ["SC_ERROR_UNKNOWN_ERROR"]
		}))
	}
}

export const markPaymentMethodAsPrimary = (cardId: string): UserMarkPaymentMethodAsPrimary => {
	return {
		type: USER_PAYMENT_MARK_AS_PRIMARY,
		payload: {
			CardId: cardId
		}
	}
}

export const setPaymentMethodAsPrimary = (cardId: string): AppThunk => async dispatch => {
	dispatch(markPaymentMethodAsPrimary(cardId));
	const response = await fetch("/rest/user/makeCreditCardPrimary", {
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/x-www-form-urlencoded",
		},
		redirect: "follow", // manual, *follow, error
		body: ('cardId=' + cardId), // body data type must match "Content-Type" header
	})
	if (response.ok === true) {
		const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket;
		// return payload;
		switch (responsePayload.CallHeader.StatusCode) {
			case "SC_OK":
				// Do nothing, as the store has already been updated.
				break;
			default:
				alert("An unknown error occurred marking a new card as the primary card.  Please refresh your page and try again.")
				break;
		}
	}
	else {
		// return new BasicException(response.status);
	}
}

export const updateShippingAddress = (address: ShippingAddressUpdatePayload): UserUpdateShippingAddressAction => {
	return {
		type: USER_SHIPPING_UPDATE,
		payload: address
	}
}

export const addShippingAddressToStore = (address: Account.Addresses.Address): UserAddShippingAddressToStore => {
	return {
		type: USER_SHIPPING_ADD_TO_STORE,
		payload: address
	}
}
export const addShippingAddress = (name: string, companyName: string, city: string, region: string, postal: string, country: string, street1: string, street2: string|null, phone: string, extension: string, commercial: boolean, primary: boolean, validated: boolean, successCallback: () => void): AppThunk => async dispatch => {
		const newShippingAddress: Account.Addresses.NewAddress = {
			IsValidated: validated,
			Type: "S",
			Name: name.trim().substring(0,FieldMaxLengths.NAME_LENGTH),
			CompanyName: companyName.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH),
			City: city.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH),
			RegionCD: region.trim(),
			PostalCD: postal.trim(),
			CountryCD: country.trim(),
			Streets: [street1.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH)],
			PhoneNumber: (extension) ? phone.trim() + " x " + extension : phone.trim(),
			IsCommercial: commercial,
			IsPrimary: primary
		}
		if (street2)
			newShippingAddress.Streets.push(street2.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH))
		const response = await fetch("/rest/user/addShippingAddress", {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				// "Content-Type": "application/x-www-form-urlencoded",
			},
			redirect: "follow", // manual, *follow, error
			body: JSON.stringify(newShippingAddress), // body data type must match "Content-Type" header
		})
		if (response.ok === true) {
			console.log("RESPONSE IS OK");
			const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket<Account.Addresses.Address[]>;
			switch (responsePayload.CallHeader.StatusCode) {
				case "SC_OK":
					console.log("Response Payload: ", responsePayload);
					dispatch(addShippingAddressToStore(responsePayload.Payload[0]));
					successCallback();

					if(primary)
						setLoggedInUserPrimaryShippingCityZipCookie(postal, city);

					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				default:
					console.log("Something happened");
			}
		}
		else {
			return [];
		}
	
	return [];
}
export const editShippingAddress = (id: string|number, name: string, companyName: string, city: string, region: string, postal: string, country: string, street1: string, street2: string|null, phone: string, extension: string, commercial: boolean, primary: boolean, validated: boolean, successCallback: () => void): AppThunk => async dispatch => {
		const editedShippingAddress: Account.Addresses.Address = {
			Id: id.toString(),
			IsValidated: validated,
			Type: "S",
			Name: name.trim().substring(0,FieldMaxLengths.NAME_LENGTH),
			CompanyName: companyName.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH),
			City: city.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH),
			RegionCD: region.trim(),
			PostalCD: postal.trim(),
			CountryCD: country.trim(),
			Streets: [street1.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH)],
			PhoneNumber: (extension) ? phone.trim() + " x " + extension : phone.trim(),
			IsCommercial: commercial,
			IsPrimary: primary
		}
		if (street2)
			editedShippingAddress.Streets.push(street2.trim().substring(0,FieldMaxLengths.ADDRESS_LENGTH))
		const response = await fetch("/rest/user/editShippingAddress", {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				// "Content-Type": "application/x-www-form-urlencoded",
			},
			redirect: "follow", // manual, *follow, error
			body: JSON.stringify(editedShippingAddress), // body data type must match "Content-Type" header
		})
		if (response.ok === true) {
			console.log("RESPONSE IS OK");
			const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket<Array<Account.Billing.CreditCard>>;
			switch (responsePayload.CallHeader.StatusCode) {
				case "SC_OK":
					console.log("edited shipping adddress", editShippingAddress);
					dispatch(updateShippingAddress(editedShippingAddress));
					successCallback();

					if(primary)
						setLoggedInUserPrimaryShippingCityZipCookie(postal, city);

					break;
				case 'SC_ERROR_INVALID_USER':
					console.log("Currently logged out!  Redirecting to login");
                    window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
					break;
				default:
					console.log("Something happened");
			}
		}
		else {
			return [];
		}
	
	return [];
}

export function setLoggedInUserPrimaryShippingCityZipCookie(zipCode: string | null, city: string | null) {
	if (zipCode) {
		if (zipCode.length > 5) {
			zipCode = zipCode.slice(0, 5);
		}
		Cookies.set('n32UserPrimaryZip', zipCode, { expires: 1 });
	}

	if (city) {
		Cookies.set('n32UserPrimaryCity', city, { expires: 1 });	
	}
}

export function getLoggedInUserPrimaryShippingZipCookie(): string | undefined{
	let cookieVal = Cookies.get('n32UserPrimaryZip');
	if(cookieVal && cookieVal.length > 0)
		return cookieVal;
	else
		return undefined;
}

export function getLoggedInUserPrimaryShippingCity(): string{
	let cookieVal = Cookies.get('n32UserPrimaryCity');
	if(cookieVal && cookieVal.length > 0)
		return cookieVal;
	else
		return "";
}

export function getLoggedInUserPrimaryCityZipCookie(): DeliveryToUser {
	let deliveryToUser: DeliveryToUser = {};
	
	if (Cookies.get('n32UserPrimaryZip') !== undefined) {
		deliveryToUser.zipCode = Cookies.get('n32UserPrimaryZip');
	}

	if (Cookies.get('n32UserPrimaryCity') !== undefined) {
		deliveryToUser.city = Cookies.get('n32UserPrimaryCity');
	}
	
	return deliveryToUser;
}

export const removeShippingAddress = (addressId: string): AppThunk => async dispatch => {
	dispatch(updateShippingAddress({
		Id: addressId,
		removalStatus: "processing"
	}))
	const response = await fetch("/rest/user/deleteShippingAddress", {
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/x-www-form-urlencoded",
		},
		redirect: "follow", // manual, *follow, error
		body: ('id='+addressId) , // body data type must match "Content-Type" header
	})
	if (response.ok === true) {
		const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket & {Errors?: Array<string>};
		if (responsePayload.CallHeader.StatusCode == "SC_OK") {
			dispatch(updateShippingAddress({
				Id: addressId,
				removalStatus: "removed"
			}))
		} else {
			dispatch(updateShippingAddress({
				Id: addressId,
				removalStatus: "failed",
				removalFailureReason: [responsePayload.CallHeader.StatusCode]
			}))
		}
	}
	else {
		dispatch(updateShippingAddress({
			Id: addressId,
			removalStatus: "failed",
			removalFailureReason: ["SC_ERROR_UNKNOWN_ERROR"]
		}))
	}
}

export const markShippingAddressAsPrimary = (addressId: string): UserMarkShippingAddressAsPrimary => {
	return {
		type: USER_SHIPPING_MARK_AS_PRIMARY,
		payload: {
			Id: addressId
		}
	}
}

export const setShippingAddressAsPrimary = (addressId: string, zipCode: string, city: string): AppThunk => async dispatch => {
	dispatch(markShippingAddressAsPrimary(addressId));
	const response = await fetch("/rest/user/makeAddressPrimary", {
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/x-www-form-urlencoded",
		},
		redirect: "follow", // manual, *follow, error
		body: ('id=' + addressId), // body data type must match "Content-Type" header
	})
	if (response.ok === true) {
		const responsePayload = await response.json() as WindfallRestfulResponse.ResponsePacket;
		// return payload;
		switch (responsePayload.CallHeader.StatusCode) {
			case "SC_OK":
				// Do nothing, as the store has already been updated.

				setLoggedInUserPrimaryShippingCityZipCookie(zipCode, city);
				break;
			default:
				alert("An unknown error occurred marking a new address as the primary address.  Please refresh your page and try again.")
				break;
		}
	}
	else {
		// return new BasicException(response.status);
	}
}

export function clearCityAndZipCookies() {
    Cookies.remove("n32UserPrimaryZip");
    Cookies.remove("n32UserPrimaryCity");
}

interface subscribeUserProps {
    emailAddress: string;
    subscriptionType: string;
    source: string;
}

interface DistributionListCCEmail
{
	dleId: string | number;
	emailAddress: string;
}
interface DistributionListFromAddCCEmailPayload {
	dltId: string | number;
	dlName: string;
	dltActiveCd: string | number;
	emailList: Array<DistributionListCCEmail>
}
// interface DistributionListFromAddCCEmailPayload {
// 	DistributionLists: Array<DistributionListFromAddCCEmail>
// }
// interface DistributionListFromAddCCEmailPayload {
// 	DistributionList: DistributionListsFromAddCCEmail
// }

interface MyAccountInfoUser extends WindfallRestfulResponse.ResponsePieces.CurrentUser
{
    Addresses: Array<Account.Addresses.Address>;
	PaymentCreditCards: Array<Account.Billing.CreditCard>;
	Licenses: Array<Account.Licenses.Licence>;
	LicenseOptions: Array<Account.Licenses.LicenseOptions>;
	BusinessTypeParentId?: number;
	BusinessTypeParentName?: string;
	BusinessTypeId?: number;
	BusinessTypeName?: string;
	BusinessOtherText?: string;
}
interface MyAccountInfoUserPayload {
	User: MyAccountInfoUser;
	DistributionLists: Array<Account.DistributionLists.DistributionList>;
	NamePrefixes: Account.NamePrefixes[];
	Regions: Account.Addresses.Regions.List[]
	LicenseOptions: Account.Licenses.LicenseOptions[]
	MultilocationSw?: boolean
}

export interface DeliveryToUser
{
	city?: string | null;
	zipCode?: string | null;
}
