import { ReorderActionTypes, REORDER_MODIFY_PRODUCT_LIST, SET_REORDER_MODIFY_PRODUCT_LIST_RETRIEVAL_STATUS } from "store/actions/ec/reorderActions";

import { ReorderProduct } from "store/actions/ec/reorderActions";

export enum ModifyReorderProductListRetrievalStatus {
    NOT_RETRIEVED = "NOT_RETRIEVED",
    RETRIEVING = "RETRIEVING",
    RETRIEVED = "RETRIEVED"
}

const initialState: ReorderState = {
    modifyReorderProductListRetrievalStatus: ModifyReorderProductListRetrievalStatus.NOT_RETRIEVED,
    reorderProducts: [],
    totalItems: 0,
    reorderItemsRetrievedFromServer: false
}

export default function (state: ReorderState = initialState, action: ReorderActionTypes) {
    const newState = { ...state };
    switch (action.type) {
        case REORDER_MODIFY_PRODUCT_LIST:
            for (const modifiedReorderProduct of action.payload.reorderProducts) {
                const indexOfExistingReorderProduct = state.reorderProducts.findIndex(existingReorderProduct => {
                    return existingReorderProduct.MpId === modifiedReorderProduct.MpId
                })
                if (indexOfExistingReorderProduct === -1)
                    newState.reorderProducts.push(modifiedReorderProduct);
                else {
                    newState.reorderProducts[indexOfExistingReorderProduct] = {
                        ...newState.reorderProducts[indexOfExistingReorderProduct],
                        ...modifiedReorderProduct
                    }
                }
            }
            return newState;
        case SET_REORDER_MODIFY_PRODUCT_LIST_RETRIEVAL_STATUS:
            return {
                ...state,
                modifyReorderProductListRetrievalStatus: action.payload
            }
        default:
            return state;
    }
}

export interface ReorderState {
    modifyReorderProductListRetrievalStatus: ModifyReorderProductListRetrievalStatus
    reorderProducts: Array<ReorderProduct>
    totalItems: number
    reorderItemsRetrievedFromServer: boolean
}

