const verticalConstants = {
    imageNotFoundURL: '/media/dental/common/misc/not-found.png',
    verticalSuppliesType: {
        /** Returns the vertical supplies type lowered cased, "dental" */
        lowerCase: 'dental',
        /** Returns the vertical supplies type with the first character upper cased, "Dental" */
        firstCharacterUpperCase: 'Dental',
    },
    verticalName: {
        /** Returns the vertical name lower cased, "net32" */
        lowerCase: 'net32',
        /** Returns the vertical name with the first character upper cased, "Net32" */
        firstCharacterUpperCase: 'Net32',
        /** Returns the vertical name upper cased, "NET32" */
        upperCase: 'NET32',
    },
    verticalCustomerTitle: {
        singular: {
            /** Returns the vertical's customer title lower cased, "dentist" */
            lowerCase: 'dentist',
            /** Returns the vertical's customer title with the first character upper cased, "Dentist" */
            firstCharacterUpperCase: 'Dentist',
        },
        plural: {
            /** Returns the vertical's customer title lower cased, "dentists" */
            lowerCase: 'dentists',
            /** Returns the vertical's customer title with the first character upper cased, "Dentists" */
            firstCharacterUpperCase: 'Dentists',
        }
    },
    officeHours: {
        days: {
            full: 'Monday - Friday',
            short: 'Mon - Fri',
            singleLetter: 'M-F',
        },
        hours: '8am-8pm ET'
    },
    businessStats: {
        totalOffices: "70,000",
        totalProductsInStock: "135,000",
        totalPurchaseOptions: "130,000",
        totalManufacturerProductSKUs: "70,000",
        potentialSavings: "50%",
        yearsInBusiness: "25",
        totalReviews: "55,000",
        totalBrands: "5,000",
    },
    strings: {
        emailFooterString: "Join tens of thousands of dental professionals receiving the best deals every week.",
        categoryListString: "Find the dental products you’re looking for in Net32’s unrivaled database of over 90,000 dental supplies, dental equipment and other dental product choices. Net32 is where dental supply company after dental supply company competes for your business, so you know you’re getting a great price. Click on a category below to browse for products or use the search above to find a specific item.",
    },
    termsOfServiceVersion: 1
}
export default verticalConstants;

function upperCaseFirstChar(string: string){
    return string.slice(0,1).toUpperCase() + string.slice(2);
}