import React, { FunctionComponent } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

import "./popoverError.scss"

const PopoverError: FunctionComponent<PopoverErrorProps> = ({ children, show, target, closeCallback, ...rest }) => {
    return (
        <Overlay
            target={target}
            show={show}>
            
            <Popover id="popover-error">
                <Popover.Content>
                    
                    <div className="popover-error-body">
                        {children}  
                    </div>

                    <div className="popover-error-close" onClick={() => closeCallback()}>
                        <div></div>
                    </div>
                </Popover.Content>
            </Popover>
        </Overlay>
    )
}

type PopoverErrorProps = {
    show: boolean
    target:any;
    closeCallback: () => void;
}

export default PopoverError;
