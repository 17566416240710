import { useCallback, useRef, useState } from "react";
import "./algoliaAutoComplete.scss";
import React from "react";
import ScopedSearch, { ScopedSearchOption } from "../../scopedSearch";
import ScopedSearchOptionsOverlay from "../../scopedSearchOptionsOverlay";
import { AlgoliaAutocomplete } from "./algoliaAutocomplete";
import "@algolia/autocomplete-theme-classic";


const AlgoliaSearchField = () => {
  const searchContainerRef = useRef<any>(null);
  const [isScopedSearchOpened, setIsScopedSearchOpened] = useState(false);
  const [scopedSearchOptions, setScopedSearchOptions] = useState<ScopedSearchOption[]>([]);
  const [selectedScopedSearch, setSelectedScopedSearch] = useState<ScopedSearchOption>();

  const changeScopedSearch = useCallback((option: ScopedSearchOption): void => {
    setSelectedScopedSearch(option);
    setIsScopedSearchOpened(false);
    // const search = window.location.search;
    // window.location.href = `${option?.url || "/search"}?${search}`;
  }, []);

  return (
    <div id="search">
      <form id="search-bar" className={`input-group searchForm flex-nowrap`} ref={searchContainerRef}>
        <ScopedSearch
          selectedScopedSearch={selectedScopedSearch}
          isScopedSearchOpened={isScopedSearchOpened}
          scopedSearchOptions={scopedSearchOptions}
          setSelectedScopedSearchCallBack={setSelectedScopedSearch}
          setScopedSearchOpenedCallback={setIsScopedSearchOpened}
          setScopedSearchOptionsCallback={setScopedSearchOptions}
        />
        <div className={`d-flex flex-grow-1 searchContainer`}>
          {
            <AlgoliaAutocomplete
              customSearchURI={selectedScopedSearch?.url}
            />
          }
          <div className="input-group-append">
            <div id="search-button" className="btn btn-secondary">
              Search
            </div>
          </div>
        </div>
      </form>

      <ScopedSearchOptionsOverlay
        isScopedSearchOpened={isScopedSearchOpened}
        scopedSearchOptions={scopedSearchOptions}
        formTarget={searchContainerRef}
        setScopedSearchOpenedCallback={setIsScopedSearchOpened}
        changeScopedSearchCallback={changeScopedSearch}
      />
    </div>
  );
};

export default AlgoliaSearchField;
