import React, { FunctionComponent, useEffect, useState } from 'react';
import VerticalPhoneNumber from "components/verticals/verticalPhoneNumbers"
import verticalConstants from "components/verticals/verticalConstants"
import VerticalName from "components/verticals/verticalName"
import VerticalSuppliesType from "components/verticals/verticalSuppliesType"
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer/ec/rootReducer';
import { createSelector } from 'reselect';
import useEmailSubscriber from 'hooks/useEmailSubscriber';
import BasicException from 'controllers/exceptions/basicException';
import * as EmailValidator from 'email-validator';
import featuresEnabled from 'components/verticals/featuresEnabled';
import Modal from 'react-bootstrap/Modal';
import Preloader from 'components/common/preloader';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EmailInput from 'components/ec/form-elements/emailInput';
import { IsNet32 } from 'components/verticals/isVertical';
import delay from 'delay';

import "./footer.scss";
import GoogleReviewBadge from './googleReviewBadge';
import TrustPilotBadge from './trustPilotBadge';
import useAnalyticsManager from 'hooks/useAnalyticsManager';

const TakenRequestModal = React.lazy(() => import('layouts/ec/full-page/takedownRequestModalContent'));

const FullPageFooter: FunctionComponent = () => {
    const { dispatchEmailSubscriptionEvent } = useAnalyticsManager();
    const { user } = useSelector(createSelector(
        (state: RootState) => state.user,
        (user) => ({
            user
        })
    ));
    const [email, setEmail] = useState('');
    const [sendingSubscriptionRequest, setSendingSubscriptionRequest] = useState(false);
    const [userSubscribedToEmail, setUserSubscribedToEmail] = useState<null | boolean>(null);
    const [userSubscribeFailureMessage, setUserSubscribeFailureMessage] = useState('');
    const [showTakeDownModal, setShowTakeDownModal] = useState(false);
    const subscribeToEmailLists = useEmailSubscriber();
    const [currentYear] = useState<string>(new Date().getFullYear().toString())

    const location = useLocation();
    const createAccountURL = '/create-account?origin=' + encodeURIComponent(location.pathname);

    useEffect(() => {
        addBadgeScripts();
    }, []);

    const addBadgeScripts = async () => {
        delay(500); // give the html elements time to render...
        const trustPilotScript = document.createElement('script');
        trustPilotScript.type = 'text/javascript';
        trustPilotScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.head.appendChild(trustPilotScript);
    };

    async function subscribe(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        try {
            if (EmailValidator.validate(email) === false) {
                setUserSubscribedToEmail(false);
                setUserSubscribeFailureMessage("You have entered an invalid email.");
            }
            else {
                setUserSubscribedToEmail(null);
                setUserSubscribeFailureMessage('');
            }
            setSendingSubscriptionRequest(true);
            const response = await subscribeToEmailLists({
                emailAddress: email,
                subscriptionType: 'Weekly Email;Replenish Email;Shopping Cart Reminder',
                source: "popup",
                isLoggedInUser: user.IsGuestUser === true ? 0 : 1
            })
            if (response instanceof BasicException) {
                throw "Basic Exception thrown from fetch: " + response.httpErrorCode;
            }
            else {
                if (response.CallHeader.StatusCode == "SC_OK") {
                    setUserSubscribedToEmail(true);
                    dispatchEmailSubscriptionEvent(email);
                }
                else if (response.CallHeader.StatusCode == "SC_WARNING_ALREADY_EXIST") {
                    setUserSubscribedToEmail(false);
                    setUserSubscribeFailureMessage("You are already subscribed for Weekly Specials.");
                }
                else {
                    throw "Unhandled status code: " + response.CallHeader.StatusCode;
                }
            }
        } catch (error) {
            console.error("Subscription error: ", error);
            setUserSubscribedToEmail(false);
            setUserSubscribeFailureMessage("We can't subscribe you at this time, please contact customer service.");
        }
    }

    const socialMediaLinks = featuresEnabled.socialMediaLinks && (
        <div className="social-media-footer-icons">
            <a className="facebook-link" href="https://www.facebook.com/pages/Net32/497923145296">Facebook</a>
            <a className="twitter-link" href="https://twitter.com/Net32Inc">Twitter</a>
            <a className="linkedin-link" href="https://www.linkedin.com/company/net32-inc">LinkedIn</a>
            <a className="youtube-link" href="https://www.youtube.com/user/Net32Inc">Youtube</a>
            <a className="pinterest-link" href="https://www.pinterest.com/net32inc">Pinterest</a>
            <a className="reddit-link" href="https://www.reddit.com/user/Net32DentalSupplies">Reddit</a>
        </div>
    )

    return (
        <footer>
            {user.userHasBeenRetrieved && user.IsGuestUser === true &&
                <div id="footer-ready-to-get-started-row">
                    <div id="footer-ready-to-get-started-banner">
                        <div className="get-started-text">
                            <span className="ready-header-text"><h2>Ready to get started?</h2></span>
                            <span className="ready-description-text">Sign up (it's free) in under 30 seconds.</span>
                        </div>
                        <div className="get-started-cta">
                            <a href={createAccountURL} className="btn btn-lg btn-secondary btn-pillbox">
                                Get started {String.fromCharCode(187)}
                            </a>
                        </div>
                    </div>
                </div>
            }
            <div id="footer-other-rows">
                <section id="subscribe-to-email">
                    <strong className="h6">Never miss another deal</strong>
                    <p className="light">{verticalConstants.strings.emailFooterString}</p>
                    <form className="form-group" id="email-subscribe-form-footer" onSubmit={subscribe}>
                        <EmailInput size="lg" placeholder="Enter email" required
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value);
                            }}
                        />
                        <div id="email-subscribe-form-footer-button-group">
                            <div className="subscribe-button-container">
                                <input id="submit-email-subscription-footer" type="submit" value="Submit" className="btn btn-secondary btn-pillbox" />
                            </div>
                            <div className="subscribe-messages sm text-danger">
                                {userSubscribeFailureMessage}
                            </div>
                        </div>
                    </form>
                    <div id="subscribe-to-email-success" data-show-success={userSubscribedToEmail === true ? "true" : "false"}>
                        <img src="/media/shared/common/icons/confirm.png" />
                        <span>Thanks for subscribing{featuresEnabled.socialMediaLinks && ", Follow us on"}</span>
                        {socialMediaLinks}
                    </div>
                </section>
                <aside id="customer-service-callout" className="light lg">Need help? Call <VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" /> Extended hours {verticalConstants.officeHours.days.singleLetter} {verticalConstants.officeHours.hours} </aside>
                <ul id="vertical-links">
                    <li>
                        <strong>
                            <VerticalName upperCaseFirstChar={true} />
                        </strong>
                    </li>
                    <li>
                        <a href="/">
                            Home
                        </a>
                    </li>
                    <li>
                        <a href={"/ec/" + verticalConstants.verticalSuppliesType.lowerCase + "-supplies"}>
                            <VerticalSuppliesType upperCaseFirstChar={true} />  Supplies
                        </a>
                    </li>
                    <li>
                        <a className="weekly-specials-link" href="/weekly-specials">
                            Weekly Specials
                        </a>
                    </li>
                    <li>
                        <a className="new-arrivals" href="/new-arrivals">
                            New Arrivals
                        </a>
                    </li>
                    <li>
                        <a href="/mfr-promotions">
                            Promotions
                        </a>
                    </li>
                    <li>
                        <a href="/vendor/become-a-vendor">
                            Become a Vendor
                        </a>
                    </li>
                    <li>
                        <a href="/vendor">
                            Vendor List
                        </a>
                    </li>
                    <li>
                        <a href="/site-map">
                            Site Map
                        </a>
                    </li>
                </ul>
                <ul id="corporate-info">
                    <li>
                        <strong>
                            Corporate Info
                        </strong>
                    </li>
                    <li>
                        <a href="/about-us">
                            About Us
                        </a>
                    </li>
                    <li>
                        <a href={"/why-shop-" + verticalConstants.verticalName.lowerCase}>
                            Why Shop <VerticalName upperCaseFirstChar={true} />?
                        </a>
                    </li>
                    <li>
                        <a href="/about-us/reviews">
                            Customer Reviews
                        </a>
                    </li>
                    <li>
                        <a href="/careers">
                            Careers
                        </a>
                    </li>
                    <li>
                        <a href="https://www.net32.com/modern-practice" target="_blank" rel="noreferrer">
                            Blog
                        </a>
                    </li>
                    <li>
                        <a href="/terms-of-service">
                            Terms of Service
                        </a>
                    </li>
                    <li>
                        <a href="/privacy-security">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="/user-privacy-settings">
                            Privacy Preferences
                        </a>
                    </li>
                    <li>
                        <a href="https://www.net32.com/privacy-security#12" target="_blank" rel="noreferrer">
                            Do Not Sell or Share My Personal Information
                        </a>
                    </li>
                </ul>
                <ul id="my-account-footer-links">
                    <li>
                        <strong>
                            My Account
                        </strong>
                    </li>
                    <li>
                        <a className="my-account-link" href="/account">
                            My Account
                        </a>
                    </li>
                    <li>
                        <a href="/shopping-cart">
                            Shopping Cart
                        </a>
                    </li>
                    <li>
                        <a className="checkout-tracking-link" href="/checkout?isGuest=false">
                            Checkout
                        </a>
                    </li>
                    <li>
                        <a href="/shopping-list">
                            Shopping List
                        </a>
                    </li>
                    <li>
                        <Link to="/account/orders">
                            My Orders
                        </Link>
                    </li>
                    <li>
                        <Link to="/account/orders/auto-order">
                            Auto Order
                        </Link>
                    </li>
                    <li>
                        <a href="/reorder" className="easy-reorder-link">
                            Reorder
                        </a>
                    </li>
                </ul>
                <ul id="let-us-help-links">
                    <li>
                        <strong>
                            Let Us Help You
                        </strong>
                    </li>
                    <li>
                        <a href="/contact-us">
                            Contact Us
                        </a>
                    </li>
                    <li>
                        <a href="/why-shop-net32#top-faqs-section">
                            FAQ's
                        </a>
                    </li>
                    <li>
                        <a href="/account/orders/tracking">
                            Order Tracking
                        </a>
                    </li>
                    <li>
                        <a href="/guarantee">
                            30 Day Money Back Guarantee
                        </a>
                    </li>
                    <IsNet32>
                        <li>
                            <a href="/giveaways/purchase">
                                Purchase Giveaway Rules
                            </a>
                        </li>
                        <li>
                            <a href="/giveaways/ratings-and-reviews">
                                Review Giveaway Rules
                            </a>
                        </li>
                    </IsNet32>
                    <li>
                        {featuresEnabled.takeDownRequest &&
                            <a href="#" id="take-down-request-link" rel="nofollow" onClick={(event) => {
                                event.preventDefault();
                                setShowTakeDownModal(true);
                            }}>
                                Take Down Request
                            </a>
                        }
                    </li>
                </ul>
                <ul id="mobile-footer-links">
                    <li className="mobile-customer-service-callout">
                        <div className="questions-please-contact">Questions?  Please call {verticalConstants.officeHours.days.singleLetter}, {verticalConstants.officeHours.hours}</div>
                        <div className="mobile-contact-bar"><VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" />&nbsp;&middot;&nbsp;<a href={"mailto:support@" + verticalConstants.verticalName.lowerCase + ".com"}>support@<VerticalName />com</a></div>
                    </li>
                    <li className="mobile-my-account-footer-link">
                        <a className="my-account-link" href="/account">
                            My Account
                        </a>
                    </li>
                    <li className="mobile-order-history-footer-link">
                        <Link to="/account/orders">
                            My Orders
                        </Link>
                    </li>
                    <li className="mobile-auto-order-footer-link">
                        <Link to="/account/orders/auto-order">
                            Auto Order
                        </Link>
                    </li>
                    <li className="mobile-reorder-footer-link">
                        <a href="/reorder">
                            Reorder
                        </a>
                    </li>
                    <li className="mobile-vendor-footer-link">
                        <a href="/vendor">
                            Vendors
                        </a>
                    </li>
                    <li className="mobile-contact-us-footer-link">
                        <a href="/contact-us">
                            Contact Us
                        </a>
                    </li>
                    <li className="mobile-top-sellers-footer-link">
                        <a href="/top-sellers">
                            Top Sellers
                        </a>
                    </li>
                    <li className="mobile-terms-footer-link">
                        <a href="/terms-of-service">
                            Terms of Service
                        </a>
                    </li>
                    <li className="mobile-privacy-footer-link">
                        <Link to="/privacy-security">
                            Privacy Policy
                        </Link>
                    </li>
                    <li className="mobile-privacy-footer-link">
                        <Link to="/user-privacy-settings">
                            Privacy Preferences
                        </Link>
                    </li>
                    <li className="mobile-dont-sell-info-footer-link">
                        <Link to="/privacy-security#12" target="_blank" rel="noreferrer">
                            Do Not Sell or Share My Personal Information
                        </Link>
                    </li>
                </ul>
                <section id="trust-badges" className="d-flex justify-content-center align-items-center flex-wrap">
                    <IsNet32>
                        <TrustPilotBadge />
                        <GoogleReviewBadge />
                        <div className="bbb-badge" dangerouslySetInnerHTML={{
                            __html: `<a id="bbblink" class="rbhzbum" href="https://www.bbb.org/us/nc/cary/profile/dental-supplies/net32-inc-0593-90055367#bbbseal" title="Net32, Inc., Dental Supplies, Cary, NC" style="display: block;position: relative;overflow: hidden; width: 150px; height: 57px; margin: 0px; padding: 0px;"><img style="padding: 0px; border: none;" id="bbblinkimg" src="https://seal-easternnc.bbb.org/logo/rbhzbum/net32-90055367.png" width="300" height="57" alt="Net32, Inc., Dental Supplies, Cary, NC" /></a><script type="text/javascript">var bbbprotocol = ( ("https:" == document.location.protocol) ? "https://" : "http://" ); (function(){var s=document.createElement('script');s.src=bbbprotocol + 'seal-easternnc.bbb.org' + unescape('%2Flogo%2Fnet32-90055367.js');s.type='text/javascript';s.async=true;var st=document.getElementsByTagName('script');st=st[st.length-1];var pt=st.parentNode;pt.insertBefore(s,pt.nextSibling);})();</script>`
                        }} />

                        <div className="hq-in-nc-badge">
                            <img className="hq-in-nc-badge-img"
                                src="/media/dental/common/icons/hq-in-nc-icon.png"
                                srcSet="/media/dental/common/icons/hq-in-nc-icon.png, /media/dental/common/icons/hq-in-nc-icon2x.png 2x" />
                        </div>
                    </IsNet32>
                </section>
                <section id="copyright-info">
                    <p>Copyright © {currentYear} <VerticalName upperCaseFirstChar={true} /> Inc. All rights reserved. <VerticalName upperCaseFirstChar={true} />'s proprietary comparison shopping software is protected by U.S. Patent #7107226.</p>
                    <p><VerticalName upperCaseFirstChar={true} /> is the leading <VerticalSuppliesType /> supply online marketplace.</p>
                    <p>Pricing shown for <VerticalName upperCaseFirstChar={true} /> is the current best pricing offered by vendors at <a href={"https://www." + verticalConstants.verticalName.lowerCase + ".com/"}>www.<VerticalName upperCaseFirstChar={false} />.com</a> and updated regularly. Pricing subject to change.</p>
                    <IsNet32>
                        <p>Neither Net32 nor SSWhite are affiliated with Dentsply Sirona. Price savings comparisons were provided by SSWhite and are based on published prices; actual prices may vary. Net32 assumes no responsibility for the accuracy of claims or statements made by third parties.</p>
                    </IsNet32>
                </section>
                <aside id="social-media-links">
                    {socialMediaLinks}
                </aside>
            </div>
            {featuresEnabled.takeDownRequest &&
                <Modal
                    show={showTakeDownModal}
                    centered
                    onHide={() => setShowTakeDownModal(false)}
                    backdrop={true}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <React.Suspense fallback={
                            <Preloader textUnderAnimation="Loading" />
                        }>
                            <TakenRequestModal />
                        </React.Suspense>
                    </Modal.Body>
                </Modal>
            }
        </footer>
    )
}

export default FullPageFooter;