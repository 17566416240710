import React, { useState, FunctionComponent } from 'react';
import "./deliverToShippingAddress.scss"

import ChooseShippingAddressModal from './chooseShippingAddressModal';

type Props = {
    primaryShippingAddress: Account.Addresses.Address | undefined;
    refreshPrimaryAddressCallBack: () => void
}

const DeliverToShippingAddress: FunctionComponent<Props> = ({
        primaryShippingAddress,
        refreshPrimaryAddressCallBack,
        ...rest 
    }) => {

    const [showChooseShippingDeliveryAddressModal, setShowChooseShippingDeliveryAddressModal] = useState(false);

    const refreshPrimaryShippingAddressAndCloseModal = (refresh: boolean) => {
        setShowChooseShippingDeliveryAddressModal(false);
        
        if (refresh === true) {
            refreshPrimaryAddressCallBack();
        }
    }

    if (primaryShippingAddress && primaryShippingAddress.Id !== null) {
        return (
            <div id="deliver-to" >
                <img className="deliver-to-pin" src="/media/dental/common/icons/map-pin.png" onClick={() => setShowChooseShippingDeliveryAddressModal(true)}></img>
                <div className="deliver-to-info" onClick={() => setShowChooseShippingDeliveryAddressModal(true)}>
                    <span className="sm d-block">Deliver to</span>
                    <span className="deliver-content">{primaryShippingAddress.City} {primaryShippingAddress.PostalCD}</span>
                </div>
    
                {showChooseShippingDeliveryAddressModal &&
                    <ChooseShippingAddressModal showModal={showChooseShippingDeliveryAddressModal}
                        chooseShippingAddressModalCallBack={refreshPrimaryShippingAddressAndCloseModal} />
                }
                
            </div>  
        );
    }
    else {
        return null;
    }

}

export default DeliverToShippingAddress;