import NumberOptionList from 'components/ec/form-elements/numberOptionList';
import PopoverError from 'components/ec/form-elements/popoverError';
import React, { useState, FunctionComponent, useRef, useEffect, useCallback } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { AddToAutoOrderProps, AutoOrderFrequencyLevels } from 'store/actions/ec/autoOrderActions';
import AutoOrderMessageBox from './auto-order/autoOrderMessageBox';
import AutoOrderPopover from './auto-order/autoOrderPopover';
import checkAddToCart, { AddToCartStatus } from './checkAddToCart';
import { VendorOptionState, SubscriptionFutureDatesPayloadFromServer } from './productDetailPage';
import SellThisNow from './sellThisNow';

/**
 * Generates the call to action component on the PDP.  Includes logic for adding to auto order.
 * @param quantity The quantity selected by the user.
 * @param setQuantity A setState function tied to quantity that sets it to the new chosen quantity.
 */
const CallToAction: FunctionComponent<CallToActionProps> = ({ 
	quantity, 
	setQuantity, 
	maxInventoryAvailable,
	isAutoOrderEnabled, 
	vendorOptions, 
	subscriptionFutureDates, 
	addToCartCallback, 
	addToAutoOrderCallback,
	productBeingAddedToCart, 
	vendorProductBeingAddedToCart, 
	chosenAutoOrderLevel,
	setChosenAutoOrderLevel, 
	chosenVendorKey,
	showExternalAddToCartError,
	disableExternalAddToCartErrorCallBack,
	...rest
}) => {
	const [showAddToCartError, setShowAddToCartError] = useState(false);
	const [subscribingToAutoOrder, setSubscribingToAutoOrder] = useState(false);
	const ref = useRef(null);

	const [showAutoOrderDropdown, setShowAutoOrderDropdown] = useState<boolean>(false);

	useEffect(() => {
        window.addEventListener('resize', onResize);
        
        // The following cleans up the resize attachment
        return () => {
            window.removeEventListener('resize', onResize);
        }
    });

    const onResize = () => {
        setShowAutoOrderDropdown(false);
    }


	useEffect(() => {
		if (showAddToCartError === true) {
			setShowAddToCartError(false);
		}
	}, [quantity, chosenVendorKey]);

	useEffect(() => {
		if (showExternalAddToCartError === true) {
			setShowAddToCartError(true);
			disableExternalAddToCartErrorCallBack(false);
			
		}
	}, [showExternalAddToCartError]);

	const elementIdentifier = "add-to-cart-cta "
	const hideAutoOrderOptions = () => {
		return !isAutoOrderEnabled || (subscriptionFutureDates.length == 1 && subscriptionFutureDates[0].SubscriptionId !== null);
	}

	const handleSubmission = useCallback(async () => {
		if (productBeingAddedToCart === false) {
			if (chosenAutoOrderLevel === AutoOrderType.ONE_TIME.index) {
				
				if (checkAddToCart(quantity, vendorOptions[chosenVendorKey].minimumQuantityPurchasable, vendorOptions[chosenVendorKey].inventory) === AddToCartStatus.OK) {
					addToCartCallback(vendorOptions[chosenVendorKey]);
				}
				else {
					setShowAddToCartError(true);
				}
			}
			else {
				setSubscribingToAutoOrder(true);
				const autoOrderLevel: AutoOrderFrequencyLevels = getSelectedAutoOrderType(chosenAutoOrderLevel).frequenceId as AutoOrderFrequencyLevels;
				await addToAutoOrderCallback({
					frequencyId: autoOrderLevel,
					quantity: quantity,
				}, "Call to Action")
				setSubscribingToAutoOrder(false);
			}
		}
	}, [productBeingAddedToCart, chosenAutoOrderLevel, quantity, vendorOptions, chosenVendorKey, 
		checkAddToCart, setShowAddToCartError, setSubscribingToAutoOrder, addToAutoOrderCallback, getSelectedAutoOrderType]);

	const onSubmitHandler = useCallback((e: React.FormEvent<HTMLElement>) => {
		e.preventDefault();
		
		handleSubmission();
	}, [quantity, handleSubmission])

	const onChangeCallback = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => { 
		console.log("quantity | onChange | " + event.target.value);
		setQuantity(parseInt(event.target.value))
	}, [setQuantity])

	
	const chosenProductBeingAddedToCart = vendorProductBeingAddedToCart === vendorOptions[chosenVendorKey].vendorProductId;
	


	if (vendorOptions.length === 0) {
		return (<div className={elementIdentifier} />)
	}

	return (
		<Form 
			className={`${elementIdentifier} pb-2 ${hideAutoOrderOptions() ? "" : "auto-order-enabled"}`}
			onSubmit={onSubmitHandler}
		>
			<Form.Control
				as="select" 
				className="quantity-select pt-0 pb-0"
				value={quantity}
				onChange={onChangeCallback}
			>
				<NumberOptionList key="foo" start={1} end={maxInventoryAvailable} />
			</Form.Control>
			<Button 
				// as="input" 
				type="submit" 
				variant="secondary" 
				disabled={chosenProductBeingAddedToCart || subscribingToAutoOrder}
				className={`add-to-cart-button pt-0 pb-0 ${(chosenProductBeingAddedToCart || subscribingToAutoOrder) ? "loading-complex" : ""}`}
				ref={ref}
			>
				{chosenAutoOrderLevel == AutoOrderType.ONE_TIME.index ? "Add to Cart" : "Add to Auto Order"}
			</Button>

			<PopoverError target={ref.current} show={showAddToCartError} closeCallback={() => setShowAddToCartError(false)}>
				{checkAddToCart(quantity, vendorOptions[chosenVendorKey].minimumQuantityPurchasable, vendorOptions[chosenVendorKey].inventory) === AddToCartStatus.MINIMUM_QUANTITY &&
					<>Minimum Order Quantity for this vendor is {vendorOptions[chosenVendorKey].minimumQuantityPurchasable}. Enter a larger quantity or select a different vendor.</>
				}
						
				{checkAddToCart(quantity, vendorOptions[chosenVendorKey].minimumQuantityPurchasable, vendorOptions[chosenVendorKey].inventory) === AddToCartStatus.INSUFFICIENT_IVENTORY &&
					<>Quantity exceeds {vendorOptions[chosenVendorKey].vendorName}'s stock of {vendorOptions[chosenVendorKey].inventory} item(s). Enter a smaller quantity or select a different vendor.</>
				}
			</PopoverError>
			

			<Dropdown 
				className="auto-order-frequency-dropdown"
				show={showAutoOrderDropdown}
				onToggle={(nextShow) => {
					setShowAutoOrderDropdown(nextShow);
				}}
			>
				<Dropdown.Toggle className="w-100" variant="outline-light" >
					{autoOrderLevels[chosenAutoOrderLevel]}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{autoOrderLevels.map((autoOrderLevel, index) => {
						return (
							<Dropdown.Item key={index} className={(index == chosenAutoOrderLevel ? "chosen" : undefined)} onClick={(event) => { setChosenAutoOrderLevel(index) }}>
								{autoOrderLevel}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			{subscriptionFutureDates.length > 1 && 
				<div className="auto-order-message-box">
					<AutoOrderMessageBox 
						selectedAutoOrder={chosenAutoOrderLevel} subscriptionFutureDates={subscriptionFutureDates}
						autoOrderPopover={<AutoOrderPopover forceShow={showAutoOrderDropdown} setForceShow={setShowAutoOrderDropdown} />}
					/>
				</div>
			}
		</Form>
	)
}

type CallToActionProps = {
	quantity: number;
	setQuantity: React.Dispatch<React.SetStateAction<number>>;
    maxInventoryAvailable: number
	isAutoOrderEnabled: boolean;
	vendorOptions: VendorOptionState[];
	subscriptionFutureDates: SubscriptionFutureDatesPayloadFromServer[];
	addToCartCallback: (vendorProductToAdd: VendorOptionState) => void;
	addToAutoOrderCallback: (productToSubscribe: Pick<AddToAutoOrderProps, "frequencyId" | "quantity">, source: string) => Promise<void>;
	productBeingAddedToCart: boolean;
	vendorProductBeingAddedToCart: null | number;
	chosenVendorKey: number;
	chosenAutoOrderLevel:number;
	setChosenAutoOrderLevel:React.Dispatch<React.SetStateAction<number>>;
	showExternalAddToCartError:boolean;
	disableExternalAddToCartErrorCallBack: React.Dispatch<React.SetStateAction<boolean>>;
}

export default React.memo(CallToAction);

export const autoOrderLevels = [
	'One-time purchase',
	'Order every 2 weeks',
	'Order every month',
	'Order every 2 months'
]

export const AutoOrderType = {
    ONE_TIME: {
		index: 0,
		indexServer: -1,
		description: "One time",
		frequenceId: "one-time",
		frequencyText: ""
	},
	EVERY_TWO_WEEKS: {
		index: 1,
		indexServer: 0,
		frequenceId: "weeks-2",
		description: "2 weeks",
		frequencyText: "on the 1st and 15th"
	},
	EVERY_MONTH: {
		index: 2,
		indexServer: 1,
		frequenceId: "months-1",
		description: "month",
		frequencyText: "on the 1st"
	},
	EVERY_TWO_MONTS: {
		index: 3,
		indexServer: 2,
		frequenceId: "months-2",
		description: "2 months",
		frequencyText: "on the 1st"
	}
}

export const getSelectedAutoOrderType = (selectedAutoOrder:number) => {
	switch(selectedAutoOrder) {
		case AutoOrderType.ONE_TIME.index: {
			return AutoOrderType.ONE_TIME;
		}
		case AutoOrderType.EVERY_TWO_WEEKS.index: {
			return AutoOrderType.EVERY_TWO_WEEKS;
		}
		case AutoOrderType.EVERY_MONTH.index: {
			return AutoOrderType.EVERY_MONTH;
		}
		case AutoOrderType.EVERY_TWO_MONTS.index: {
			return AutoOrderType.EVERY_TWO_MONTS;
		}
		default: {
			return AutoOrderType.ONE_TIME;
		} 
	}
};

export const getAutoOrderTypeByFrequenceId = (frequenceId:string) => {
	switch(frequenceId) {
		case AutoOrderType.ONE_TIME.frequenceId: {
			return AutoOrderType.ONE_TIME;
		}
		case AutoOrderType.EVERY_TWO_WEEKS.frequenceId: {
			return AutoOrderType.EVERY_TWO_WEEKS;
		}
		case AutoOrderType.EVERY_MONTH.frequenceId: {
			return AutoOrderType.EVERY_MONTH;
		}
		case AutoOrderType.EVERY_TWO_MONTS.frequenceId: {
			return AutoOrderType.EVERY_TWO_MONTS;
		}
		default: {
			return AutoOrderType.ONE_TIME;
		} 
	}
};
