import { CategoryActionTypes, CATEGORIES_TOP_CAT_UPDATE } from "store/actions/ec/categoryActions";

const initialState: CategoriesState= {
    topCategories: []
}

export default function(state = initialState, action: CategoryActionTypes) {
    switch (action.type) {
        case CATEGORIES_TOP_CAT_UPDATE:
            return {
                ...state,
                topCategories: action.payload
            };

        default:
            return state;
    }
}

export interface CategoriesState {
    topCategories: Array<WindfallRestfulResponse.ResponsePieces.TopCategory>
}