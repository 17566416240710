// The keys in this file are sourced from keys.yaml
export default {
    stripe: 'pk_live_fjeKCxfohCSaSKlWX9qAtEvo',
    googleRecaptcha: '6Ld_1QsTAAAAAHCluc4gnWBQUN-AEmDqj8RV6OIB',
    googleRecatpchaV3: '6Lf8mpQUAAAAABTGUa0ai30vNp5Q_1KpAeG99u-l',
    googleTagManager: 'GTM-TXSFSFH',
    googleTagManagerAuth: 'p87h0R1TmpPfbTzSA4ev6w',
    googleTagManagerEnv: 'env-269',
    searchSpring: '6ym7l5',
    /** Will be equal to the string "null" if not available due to environment */
    klaviyo: 'JNdBvM',
}