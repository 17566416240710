import React, { FunctionComponent, useEffect } from 'react';
import './squeezePage.scss';
import VerticalName from 'components/verticals/verticalName';
import verticalConstants from 'components/verticals/verticalConstants';
import VerticalPhoneNumber from 'components/verticals/verticalPhoneNumbers';
import { Link, Route, RouteProps } from 'react-router-dom';
import Preloader from 'components/common/preloader';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useAnalyticsManager, { AnalyticsUserData } from 'hooks/useAnalyticsManager';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from 'store/reducer/ec/rootReducer';
import { ShoppingCartRetrievalStatus } from 'store/reducer/ec/shoppingCartReducer';


// export default class SqueezePage extends Component {
const SqueezePage: FunctionComponent<SqueezePageProps> = ({children, pageName, showSecureTransactionInFooter}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    try {
        if (executeRecaptcha)
            executeRecaptcha(pageName);
    } catch (error) {
        console.log("Recaptcha error:", error);
    }
    const { dispatchAnalyticsEvent, getShoppingCartSummary } = useAnalyticsManager();
    const { app, user, shoppingCart } = useSelector(createSelector(
        (state: RootState) => state.app,
        (state: RootState) => state.user,
        (state: RootState) => state.shoppingCart,
        (app, user, shoppingCart) => ({
            app, user, shoppingCart
        })
    ));
    useEffect(() => {
        if (user.userHasBeenRetrieved === true && shoppingCart.shoppingCartRetrievalStatus === ShoppingCartRetrievalStatus.RETRIEVED) {
            const userData: AnalyticsUserData | undefined = user.IsGuestUser === false ? { userId: user.UserId }: undefined
            dispatchAnalyticsEvent({
                eventName: "everyPageEvent",
                userData: userData,
                shoppingCartSummary: getShoppingCartSummary()
            })
        }
        else if (user.userHasBeenRetrieved === true && shoppingCart.shoppingCartRetrievalStatus !== ShoppingCartRetrievalStatus.RETRIEVING) {
            // As of right now, not all pages retrieve the shopping cart.  This is for that case.
            // This should be removed once use of userAndCartSummary/get is deprecated.
            const userData: AnalyticsUserData | undefined = user.IsGuestUser === false ? { userId: user.UserId }: undefined
            const shoppingCatSummary = () => {
                return {
                    totalQuantity: shoppingCart.totalQuantity,
                    subTotal: shoppingCart.subTotal,
                    products: shoppingCart.vendorOrders.flatMap(vo => vo.products).map(product => {
                        return {
                            productId: product.mpId,
                            name: product.productTitle,
                            quantity: product.quantity,
                            price: product.unitPrice,
                            // subTotal: product.,
                            // category: product.categoryName,
                        }
                    })
                }
            }
            dispatchAnalyticsEvent({
                eventName: "everyPageEvent",
                userData: userData,
                shoppingCartSummary: shoppingCatSummary()
            })
        }
    }, [user.IsGuestUser, user.userHasBeenRetrieved, shoppingCart.shoppingCartRetrievalStatus])

   
    const date = new Date();
    return (
        <div id="squeeze-page-container">
            <div id="squeeze-page">
                <header>
                    <a id="squeeze-logo" href="/"><VerticalName upperCaseFirstChar={true} /></a>
                    <aside>
                        <div>Need help? Call <strong><VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" /></strong></div>
                        <div className="sm"><span className="hide-on-mobile">Extended hours </span>{verticalConstants.officeHours.days.short}, {verticalConstants.officeHours.hours}</div>
                    </aside>
                </header>
                <section className={"squeeze-body-container " + pageName + "-squeeze-body-container"}>
                    {children}
                </section>
                <footer>
                    <div className="footer-column-one">
                        <span className="sm medium"><VerticalName upperCaseFirstChar={true} /> Inc. {String.fromCharCode(169)} {date.getFullYear()}</span>
                        <a className="sm light black-text-link" href="/">Home</a>
                        <Link className="sm light black-text-link" to="/shopping-cart">My Cart</Link>
                        <a className="sm light black-text-link" href="/privacy-security">Privacy Policy</a>
                    </div>
                    <div className="footer-column-two">
                        <span className="sm medium">Stay In Touch</span>
                        <a className="sm light black-text-link" href="/contact-us">Contact Us</a>
                        <span className="sm light"> <VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" /></span>
                    </div>
                    {showSecureTransactionInFooter && (
                        <div className="footer-column-three">
                            <div className="footer-security-logo-container desktop-only">
                                <a href="https://sealsplash.geotrust.com/splash?&amp;dn=site2.sheridan.net32.net" id="footer-squeeze-geotrust-logo-anchor" target="_blank" rel="noreferrer">
                                    <img src="/media/shared/common/icons/geotrust-logo.png" width="150" height="74" alt="GeoTrust secured" />
                                </a>
                            </div>
                            <div className="footer-security-text-container desktop-only">
                                <strong>Secure Transaction:</strong> For your protection, this website is secured with the highest level of SSL Certificate encryption.
                            </div>
                            <div className="footer-security-text-container mobile-and-tablet">
                                <strong>Secure Shopping Guaranteed.</strong> 
                                Your shopping experience is always <span>safe and secure.</span>
                            </div>

                        </div>
                    )}
                </footer>
            </div>
        </div>
    )
}
export default SqueezePage;

export interface SqueezePageProps {
    children?: any;
    showSecureTransactionInFooter: boolean;
    /**
     * A camel-notation version of the page's name, registration, productDetail, etc.
     */
    pageName: string;
}
// =========================================================================================================
export const SqueezePageRoute: FunctionComponent<SqueesePageRouteProps> = ({ component: Component, showSecureTransactionInFooter, pageName, ...rest }) => {
    return (
        <Route {...rest} >
            <SqueezePage pageName={pageName} showSecureTransactionInFooter={showSecureTransactionInFooter}>
                <React.Suspense fallback={
                    <section><Preloader textUnderAnimation="Loading" /></section>
                }>
                    {/*
                    // @ts-ignore */}
                    <Component
                    />
                </React.Suspense>
            </SqueezePage>
        </Route>
    )
}
type SqueesePageRouteProps = RouteProps & SqueezePageProps
