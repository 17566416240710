import { useState, useEffect } from "react";
import BasicException from "controllers/exceptions/basicException";

export default () => {
    const subscribe = async ({emailAddress, subscriptionType, source, isLoggedInUser}: subscribeProps): Promise<WindfallRestfulResponse.ResponsePacket | BasicException> => {
        try {
            const response = await fetch("/rest/subscriptionService/subscribe", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify({
                    emailAddress: emailAddress,
                    subscriptionType: subscriptionType,
                    source: source
                })
            })
            if (response.ok === true) {
                const payload = await response.json() as WindfallRestfulResponse.ResponsePacket;
                console.log(payload);
                return payload;
            }
            else {
                return new BasicException(response.status);
            }
        } catch (error) {
            return new BasicException(500);
        }
        
    }
    return subscribe;
}
interface subscribeProps {
    emailAddress: string
    subscriptionType: string
    source: string
    isLoggedInUser: 0 | 1
}