import React, { FunctionComponent, useMemo } from 'react';

/**
 * 
 * Create options for select
 * @start initial value range
 * @end initial value range
 */
const NumberOptionList: FunctionComponent<NumberOptionListProps> = ({ start, end }) => {
    
    const memoizedNumberList = useMemo(() => {
        const numberList = [];
        for (let index = start; index <= end; index++) {
            numberList.push(index)
        }
        return numberList;
    }, [start, end]);

    return (
        <React.Fragment>
            {memoizedNumberList.map((num) => {
                return (<option key={num} value={num}>{num}</option>)
            })}
        </React.Fragment>
    )
}

export default React.memo(NumberOptionList);

type NumberOptionListProps = {
    start: number,
    end: number;
}