import { ShoppingCartState } from "store/reducer/ec/shoppingCartReducer";
import { IncomingAnalyticsProduct, AnalyticsReportProductV2 } from "./analyticsEventSender";

export function convertIncomingAnalyticsProductToAnalyticsReportProductV2(incomingProduct: IncomingAnalyticsProduct[], incrementQuantity = false):AnalyticsReportProductV2[] {
    return incomingProduct.map((p): AnalyticsReportProductV2 => {
        const convertedProduct: AnalyticsReportProductV2 =  {
            item_id: p.mpId.toString(),
            item_name: p.productTitle ? p.productTitle : undefined,
            price: p.unitPrice,
            quantity: incrementQuantity ? p.quantity : p.quantity - p.originalQuantity,
            item_brand: p.brandName ? p.brandName : undefined ,
            item_category:  p.categoryList && p.categoryList[0] ? p.categoryList[0] : undefined,
            item_category2: p.categoryList && p.categoryList[1] ? p.categoryList[1] : undefined,
            item_category3: p.categoryList && p.categoryList[2] ? p.categoryList[2] : undefined,
            item_category4: p.categoryList && p.categoryList[3] ? p.categoryList[3] : undefined,
            item_category5: p.categoryList && p.categoryList[4] ? p.categoryList[4] : undefined,
        };
        (Object.keys(convertedProduct) as Array<keyof AnalyticsReportProductV2>).forEach(key => typeof convertedProduct[key] === "undefined" && delete convertedProduct[key]);
        return convertedProduct;
    })
}

export function convertShoppingCatProductToAnalyticsReportProductV2(
  shoppingCart?: ShoppingCartState,
): AnalyticsReportProductV2[] {
  if (!shoppingCart) {
    return [];
  }
  return shoppingCart.vendorOrders
    .flatMap((vo) => vo.products)
    .map((p) => {
      const convertedProduct: AnalyticsReportProductV2 = {
        item_id: p.mpId.toString(),
        item_name: p.productTitle ? p.productTitle : undefined,
        price: p.unitPrice,
        quantity: p.quantity,
        // item_brand: p. ? p.brandName : undefined,
        // item_category: p.categoryList && p.categoryList[0] ? p.categoryList[0] : undefined,
        // item_category2: p.categoryList && p.categoryList[1] ? p.categoryList[1] : undefined,
        // item_category3: p.categoryList && p.categoryList[2] ? p.categoryList[2] : undefined,
        // item_category4: p.categoryList && p.categoryList[3] ? p.categoryList[3] : undefined,
        // item_category5: p.categoryList && p.categoryList[4] ? p.categoryList[4] : undefined,
      };
      (Object.keys(convertedProduct) as Array<keyof AnalyticsReportProductV2>).forEach(
        (key) => typeof convertedProduct[key] === "undefined" && delete convertedProduct[key],
      );
      return convertedProduct;
    });
}