import { getAutoOrderTypeByFrequenceId } from "components/ec/catalog/product-detail/callToAction";
import { UserState } from "store/reducer/userReducer";
import { AdditionalAnalyticsProductInfo, AnalyticsReportProductV2, convertUserStateToUserDataForAnalytics, dispatchAnalyticsEvent, dispatchNullECommerceEvent, IncomingAnalyticsProduct, modifyCartAnalyticsEvent } from "utilities/analytics/analyticsEventSender";
import computeSubtotalOfAnalyticsProduct from "utilities/analytics/computeSubtotalOfAnalyticsProduct";
import getProductDetailsForReport from "utilities/analytics/getProductDetailsForReport";
import { PageTypes } from "./useProductRecommendations";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "store/reducer/ec/rootReducer";
import { ShoppingCartState } from "store/reducer/ec/shoppingCartReducer";
import convertShoppingCartForAnalyticsReport from "utilities/analytics/convertShoppingCartForAnalyticsReport";

export default () => {
    window.dataLayer = (window.dataLayer || []);
    const { user, shoppingCart } = useSelector(createSelector(
        (state: RootState) => state.user,
        (state: RootState) => state.shoppingCart,
        (user, shoppingCart) => ({
            user, shoppingCart
        })
    ));

    const getShoppingCartSummary = useCallback((): AnalyticsCartSummary => {
        const vendorOrderProducts: AnalyticsSummaryCartProduct[] = shoppingCart.vendorOrders.flatMap(vendorOrder => {
            return vendorOrder.products.map(product => {
                return {
                    productId: product.mpId,
                    name: product.productTitle,
                    quantity: product.quantity,
                    price: product.unitPrice,
                    subTotal: product.subtotal,
                    category: product.categoryName,
                }
            })
        })
        const totalQuantity: number = vendorOrderProducts.length > 0 ? vendorOrderProducts.map(vp => vp.quantity).reduce(((previousValue, quantity) => previousValue + quantity), 0) : 0;
        return {
            totalQuantity: totalQuantity,
            subTotal: shoppingCart.subTotal,
            totalShipping: shoppingCart.standardShipping,
            products: vendorOrderProducts,
        }
    }, [shoppingCart.standardShipping, shoppingCart.subTotal, shoppingCart.vendorOrders]);
    
    const dispatchAnalyticsEventWithShoppintCartAndUserData = useCallback((eventName: string) => {
        dispatchAnalyticsEvent({
            eventName: eventName,
            shoppingCartSummary: getShoppingCartSummary(),
            userData: user.IsGuestUser === false ? { userId: user.UserId } : undefined
        });
    }, [getShoppingCartSummary, user]);
    

    
    const convertImpressionProductForReport = useCallback((products: Array<ImpressionProduct>, source: string): Impression[] => {
        const impressions: Impression[] = [];
        for (const product of products) {
            if (!product) continue;
            const impression: Impression = {
                id: product.MpId,
                name: product.MpName,
                list: source,
                // list_position: // TODO
            }
            
            if (product.BrandName)
                impression['brand'] = product.BrandName;
            // if (product.CategoryName)
                // impression['category'] = product.CategoryName;
            if (product.MinQty)
                impression['quantity'] = product.MinQty;
            if (product.BestPrice)
                impression['price'] = product.BestPrice;
            if (product.schema)
                impression['schema'] = product.schema;
            impressions.push(impression)
        }
        return impressions;
    }, [])
    const dispatchProductImpression = useCallback((products: Array<ImpressionProduct>, source: string, user?: UserState, certonaPageId?: string) => {
        const impressions: Impression[] = convertImpressionProductForReport(products,source);
        const event: DispatchAnalyticsEventProps = {
            eventName: 'impressionPush',
            ecommerce: {
                impressions: impressions
            }
        }
        if (certonaPageId)
            event.certonaPageId = certonaPageId;
        if (user && user.IsGuestUser === false)
        {
            event.userData = {
                userName: user.UserName,
                userId: user.UserId,
                userEmailAddress: user.EmailAddress,
                firstName: user.FirstName,
                lastName: user.LastName,
                isDentalCustomer: user.IsProfessionalOffice != undefined ? user.IsProfessionalOffice : false
            }
        }
        dispatchAnalyticsEvent(event);
    }, [convertImpressionProductForReport]);
    const dispatchAggregateProductImpressions = useCallback((productGroups: {[key: string]: ImpressionProduct[]}, source: string, pageType: PageTypes, context?: string, certonaPageId?: string) => {
        let impressions: Impression[] = []
        for (const key in productGroups) {
            const convertedImpressions = convertImpressionProductForReport(productGroups[key], source);
            impressions = impressions.concat(convertedImpressions);
        }
        const event: DispatchAnalyticsEventProps = {
            eventName: 'impressionPush',
            ecommerce: {
                impressions: impressions
            },
            context: context,
            pageType: pageType,
            certonaPageId: certonaPageId,
        }
        if (user && user.IsGuestUser === false)
        {
            event.userData = {
                userName: user.UserName,
                userId: user.UserId,
                userEmailAddress: user.EmailAddress,
                firstName: user.FirstName,
                lastName: user.LastName,
                isDentalCustomer: user.IsProfessionalOffice != undefined ? user.IsProfessionalOffice : false
            }
        }
        dispatchAnalyticsEvent(event);
    }, [convertImpressionProductForReport, user]);
    const dispatchModifyCartEvent = useCallback((products: Array<IncomingAnalyticsProduct>, source: AnalyticsSource) => {
        modifyCartAnalyticsEvent(products, user, source, false, shoppingCart);
    }, [user, shoppingCart]);
    const dispatchRegistrationEvent = useCallback(({
        isCheckout,
        userId,
        userIdHash,
        emailAddress,
        firstName,
        lastName,
        isDentalOffice,
        subscribed,
        onBehalfOfBusiness,
        businessType,
        officeType,
        whatBestDescribesYou,
        officeSpecialty,
        officeName,
        officeContactFirstName,
        officeContactLastName,
        officeContactJobTitle,
        dentalPracticeStructure,
        dentalPracticeNumberOfLocations,
        howYouHeardAboutUs,
    }: DispatchRegistrationEventProps, eventCallback?: () => void) => {
        dispatchAnalyticsEvent({
            eventName: "registrationSuccess",
            registrationSource: isCheckout ? 'Registered via Checkout Login page' : 'Registered via Login page',
            registrationData: {
                userId: userId,
                emailAddress: emailAddress,
                firstName: firstName,
                lastName: lastName,
                subscribed: subscribed ? "true" : "false",
                isProfessionalOffice: isDentalOffice ? 'true' : 'false',
                isDentist: isDentalOffice ? 'true' : 'false',
                isBusiness: onBehalfOfBusiness? "true" : "false",
                businessType: businessType,
                registrationSource: isCheckout === true ? 'checkout' : 'registration page',
                userHash: userIdHash,
                officeSpecialty: officeSpecialty,
                officeType: officeType,
                officeName: officeName,
                officeContactFirstName: officeContactFirstName,
                officeContactLastName: officeContactLastName,
                officeContactJobTitle: officeContactJobTitle,
                dentalPracticeStructure: dentalPracticeStructure,
                dentalPracticeNumberOfLocations: dentalPracticeNumberOfLocations,
                howYouHeardAboutUs: howYouHeardAboutUs,
                whatBestDescribesYou: whatBestDescribesYou,
                // userName: emailAddress,
            },
            eventCallback: eventCallback,
            eventTimeout:  eventCallback ? 2000 : undefined,
        })
    }, []);

    const dispatchProductDetailPageViewEvent = useCallback((productDetails: AnalyticsReportProduct, similarProducts: number[]) => {
        // NOTE: this event is deprecated and should not be added to any longer. Add to the "view_item" event in productDetailPage instead.
        const event: DispatchAnalyticsEventProps = {
                eventName: 'productDetailView',
                ecommerce: {
                    detail: {
                        products: [
                            productDetails
                        ]
                    }
                },
                shoppingCartSummary: getShoppingCartSummary(),
                similarProducts: similarProducts,
            }

            if (user && user.IsGuestUser === false) {
                event.userData = {
                    userName: user.UserName,
                    userId: user.UserId,
                    userEmailAddress: user.EmailAddress,
                    firstName: user.FirstName,
                    lastName: user.LastName,
                    isDentalCustomer: user.IsProfessionalOffice != undefined ? user.IsProfessionalOffice : false,
                }
            }

            dispatchAnalyticsEvent(event);
        }, [getShoppingCartSummary, user]);
    const dispatchShoppingCartViewEvent = useCallback(() => {
        // This was for GA3 & Intellisuggest/Search Spring
        dispatchAnalyticsEventWithShoppintCartAndUserData('shoppingCartView')
        
        // The following is for GA4
        const { subtotal, cartItems } = convertShoppingCartForAnalyticsReport(shoppingCart);
        dispatchNullECommerceEvent();
        dispatchAnalyticsEvent({
            eventName: "view_cart",
            ecommerce: {
                currency: "USD",
                value: subtotal,
                items: cartItems,
            }
        });
        // We're going to store the above info and a hash into sessionStorage for use in the checkout process
        try {
            if (window.sessionStorage) {
                sessionStorage.setItem('analyticsCart', JSON.stringify({
                    hash: cartItems.map(p => `${p.item_id};${p.quantity};${p.price}`).join('|'),
                    subtotal: subtotal,
                    cartItems: cartItems,
                }))
            }
        } catch (error) {
            console.error("analyticsCart session storage error:", error);
        }
    }, [dispatchAnalyticsEventWithShoppintCartAndUserData, shoppingCart]);
    type CheckoutStepNames = "begin_checkout" | "add_shipping_info" | "add_payment_info";
    const dispatchAddEvent = useCallback((eventName: CheckoutStepNames, event_id?: string) => {
        const { subtotal, cartItems } = convertShoppingCartForAnalyticsReport(shoppingCart);
        const payload = {
            currency: 'USD',
            value: subtotal,
            items: cartItems
        }
        dispatchAnalyticsEvent({
            event_id,
            eventName,
            ecommerce: payload
        });
        // We're going to store the above info and a hash into sessionStorage for use in the checkout process
        try {
            if (window.sessionStorage) {
                sessionStorage.setItem('analyticsCart', JSON.stringify({
                    hash: cartItems.map(p => `${p.item_id};${p.quantity};${p.price}`).join('|'),
                    subtotal: subtotal,
                    cartItems: cartItems,
                }))
            }
        } catch (error) {
            console.error("analyticsCart session storage error:", error);
        }
        return payload;
    }, [shoppingCart])
    const dispatchAutoOrderViewEvent = useCallback(() => {
        dispatchAnalyticsEventWithShoppintCartAndUserData('autoOrderView')
    }, [dispatchAnalyticsEventWithShoppintCartAndUserData]);
    const dispatchUserHasLoggedInEvent = useCallback((analyticsUserData: AnalyticsUserData) => {
        dispatchAnalyticsEvent({
            eventName: 'userHasLoggedIn',
            userData: {
                userId: analyticsUserData.userId,
                userName: analyticsUserData.userName,
                userEmailAddress: analyticsUserData.userEmailAddress,
                firstName: analyticsUserData.firstName,
                lastName: analyticsUserData.lastName,
                isDentalCustomer: analyticsUserData.isDentalCustomer != undefined ? analyticsUserData.isDentalCustomer : false
            }
        });
    }, []);
    const dispatchAddToAutoOrderEvent = useCallback((mpId: number, frequencyId: string, quantity: number, source: string) => {
        const frequencyInfo = getAutoOrderTypeByFrequenceId(frequencyId);
        const frequencyText = "Every " + frequencyInfo.description;
        dispatchAnalyticsEvent({
            eventName: "autoOrderAdd",
            mpId: mpId,
            frequency: frequencyText,
            quantity: quantity,
            source: source
        })
    }, []);
    const dispatchPageView = useCallback(({userState: incomingUserState, shoppingCartState: incomingShoppingCart, product}: DispatchPageViewProps) => {
        // NOTE: If you change anything here name wise, make sure you update the version in n32-ec-functions.js
        const eventName = "pageViewWithAdditionalInfo";
        const userData: AnalyticsUserData | undefined = convertUserStateToUserDataForAnalytics(incomingUserState)
        const eCommerceInfo = !product ? undefined : {
            detail: {
                products: [
                    {
                        id: product.mpId,
                        category: product.category
                    }
                ]
            }
        };
        dispatchAnalyticsEvent({
            eventName: eventName,
            userData: userData,
            shoppingCartSummary: getShoppingCartSummary(),
            ecommerce: eCommerceInfo
        })
    }, [getShoppingCartSummary]);
    
    const dispatchProductDetailPageView = useCallback(async (productViewed: AnalyticsReportProductV2, additionalProductInfo: AdditionalAnalyticsProductInfo = {}) => {
        const productsToReport = await getProductDetailsForReport([productViewed])
        
        dispatchNullECommerceEvent();
        dispatchAnalyticsEvent({
            eventName: "view_item",
            ecommerce:{
                currency: "USD",
                value: computeSubtotalOfAnalyticsProduct(productsToReport).toNumber(),
                items: productsToReport
            },
            ...additionalProductInfo
        })
    }, []);

    const dispatchEmailSubscriptionEvent = useCallback(async (email?: string, phone?: string) => {
        dispatchAnalyticsEvent({
            eventName: "emailSubscriptionSiteFooterSuccess",
            subscribedEmail: email
        })
    }, []);


    return {
        dispatchAnalyticsEvent: dispatchAnalyticsEvent,
        dispatchProductImpression: dispatchProductImpression,
        dispatchAggregateProductImpressions: dispatchAggregateProductImpressions,
        /**
         * Dispatches an add or removed from cart event for the products passed to it. 
         * It determines which is which by the quantity.
         * @param products 
         * @param source 
         */
        dispatchModifyCartEvent: dispatchModifyCartEvent,
        dispatchRegistrationEvent: dispatchRegistrationEvent,
        dispatchProductDetailPageViewEvent: dispatchProductDetailPageViewEvent,
        getShoppingCartSummary: getShoppingCartSummary,
        dispatchShoppingCartViewEvent: dispatchShoppingCartViewEvent,
        dispatchAddEvent: dispatchAddEvent,
        dispatchAutoOrderViewEvent: dispatchAutoOrderViewEvent,
        dispatchUserHasLoggedInEvent: dispatchUserHasLoggedInEvent,
        dispatchAddToAutoOrderEvent: dispatchAddToAutoOrderEvent,
        /**
         * Provides a custom page view event when the tag needs additional data, like current user or other product info.
         * This was initially for use with a Pinterest tag, but could be added elsewhere.
         * @param {object} product optional, any product details if viewed on the PDP.
         */
        dispatchPageView: dispatchPageView,
        /** Dispatches a `view_item` event for a singular product, such as a PDP. 
         */
        dispatchProductDetailPageView: dispatchProductDetailPageView,
        dispatchEmailSubscriptionEvent: dispatchEmailSubscriptionEvent,
    }
}

export interface DispatchAnalyticsEventProps {
    eventName: string,
    eventCallback?: () => void,
    eventTimeout?: number,
    userData?: AnalyticsUserData
    shoppingCartSummary?: AnalyticsCartSummary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [keys: string]: any
}

interface Impression {
    id: string | number;
    name: string;
    list: string;
    // list_position: number;
    brand?: string;
    category?: string;
    quantity?: number;
    price?: string | number;
    // variant: string;
    /** A Certona Slider schema */
    schema?: string
}
export type ImpressionProduct = 
    (Pick<Products.ManufacturerProduct,"MpName"> & {MpId: number} & Partial<Products.ManufacturerProduct>
    | Products.Recommendations.ProductRecommendation & {BrandName?: string, MinQty?: number}
    | undefined) & { 
        /** A Certona Slider schema */
        schema?: string
    }

export type AnalyticsReportProduct = {
    id: number,
    name?: string,
    quantity?: number
    price?: number
    brand?: string,
    category?: string,
    variant?: string,
    coupon?: string
    image_url? : string
    product_detail_page_url? : string
    retail_price? : string | number
}

export type AnalyticsCartSummary = {
    totalQuantity: number;
    subTotal: number;
    totalShipping?: number;
    products: Array<AnalyticsSummaryCartProduct>;
}

export type AnalyticsSummaryCartProduct = {
    productId: number;
    name: string;
    quantity: number;
    price: number;
    subTotal?: number;
    category?: string;
}

export type AnalyticsUserData = {
    userId: number | string;
    userName?: string;
    userEmailAddress?: string;
    firstName?: string;
    lastName?: string;
    totalOrders?: number;
    isDentalCustomer?: boolean;
    hash?: string;
}

export type AnalyticsSource = 
    'Shopping Cart - Free Shipping Suggestion' |
    'Shopping Cart - Replenishment' |
    'Shopping Cart - Save For Later' |
    'Shopping Cart' |
    'Shopping Cart Acknowledgement' |
    'Product Detail Page' |
    'Product Detail Page - Vendor Rows' |
    'Product Detail Page - CTA' |
    'Product Detail - Reviews Page' |
    'Product Detail - Side Shopping Cart' |
    'Category Page' |
    'Search Page' |
    'Search Autocomplete' |
    'Weekly Specials' |
    'Top Sellers' |
    'New Arrivals' |
    'Dashboard' |
    'Easy Reorder' |
    'Vendor Detail' |
    'Product Reviews Page' |
    'Vendor Reviews Page' |
    'Shopping List' |
    'Order History Page' |
    'Order Detail Page' |
    'Certona' |
    'Buy Get Page' |
    'Unknown';

export type DispatchPageViewProps = {
    userState: UserState
    shoppingCartState: ShoppingCartState, 
    product?: {
        mpId: number, 
        category?: string
    }
}

type DispatchRegistrationEventProps = {
    isCheckout: boolean
    userId: number
    /** This is for Intercom user verification */
    userIdHash: string
    emailAddress: string
    firstName: string
    lastName: string
    isDentalOffice: boolean
    subscribed: boolean
    onBehalfOfBusiness?: boolean
    businessType?: string
    officeType?: string,
    whatBestDescribesYou?: string
    officeSpecialty?: string
    officeName?: string
    officeContactFirstName?: string
    officeContactLastName?: string
    officeContactJobTitle?: string
    dentalPracticeStructure?: string
    dentalPracticeNumberOfLocations?: string
    howYouHeardAboutUs?: string
}


export type VendorAnalyticsInfo = {
    allVendorsCount: number;
    vendorLowestPrice: number;
    vendorLowestDeliveryDays: number;
    allVendorsAuthorizedCount: number;    
}

export type UserShippingAnalyticsInfo = {
    shippingState: string;
    shippingSubRegion: string;
}
