import React, { RefObject, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Overlay } from "react-bootstrap";
import { RootState } from "store/reducer/ec/rootReducer";
import RetrievalStatus from "enums/retrievalStatus";
import CurrencyDisplay from "components/common/currencyDisplay";
import ProductImage from "components/common/productImage";
import { getBuyItAgainSuggestions } from "store/actions/ec/orderHistoryActions";
import { createSelector } from "reselect";
import Big from "big.js";

const getPurchasedDateDescription = (customerOrderDate: Date) => {
  const tempCustomerOrderDate = new Date(customerOrderDate);
  const custmerOrderDateWithoutTime = new Date(
    tempCustomerOrderDate.getFullYear(),
    tempCustomerOrderDate.getMonth(),
    tempCustomerOrderDate.getDate()
  );

  const nowDate = new Date();
  const nowDateWihoutTime = new Date(
    nowDate.getFullYear(),
    nowDate.getMonth(),
    nowDate.getDate()
  );
  const differenceInDays = Math.trunc(
    (nowDateWihoutTime.getTime() - custmerOrderDateWithoutTime.getTime()) /
      (1000 * 3600 * 24)
  );
  if (differenceInDays === 0) {
    return "Purchased Today";
  } else if (differenceInDays === 1) {
    return "Purchased 1 day ago";
  } else {
    return `Purchased ${differenceInDays} days ago`;
  }
};

type Props = {
  buyItAgainSuggestionVisibility: string;
  searchContainerRef: RefObject<HTMLFormElement | HTMLDivElement>;
};

const BuyItAgainSuggestions = ({
  buyItAgainSuggestionVisibility,
  searchContainerRef,
}: Props) => {
  const {
    user,
    orderHistory,
  }: {
    user: any;
    orderHistory: {
      buyItAgainRetrievalState: string;
      buyItAgainSuggestions: any[];
    };
  } = useSelector(
    createSelector(
      (state: RootState) => state.user,
      (state: RootState) => state.orderHistory,
      (user, orderHistory) => ({
        user,
        orderHistory,
      })
    )
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user.IsGuestUser === false &&
      orderHistory.buyItAgainRetrievalState === RetrievalStatus.NOT_RETRIEVED
    ) {
      dispatch(getBuyItAgainSuggestions());
    }
  }, [dispatch, orderHistory.buyItAgainRetrievalState, user.IsGuestUser]);

  if (
    !(
      orderHistory.buyItAgainSuggestions.length > 0 &&
      buyItAgainSuggestionVisibility !== "permanently-hidden"
    )
  )
    return null;

  /* ~~NOTE~~ The <> below needs to be here to intercept arrowProps being passed from Overlay. 
  Otherwise React complains as it should not be passed to a DOM element. THis comment can't be closer to it for similar reasons..... -Michael 12/2/2021 */
  return (
    <Overlay
      show={buyItAgainSuggestionVisibility === "shown"}
      target={searchContainerRef.current}
      placement="bottom"
      container={searchContainerRef.current}
    >
      <>
        <div id="buy-it-again-suggestions-container">
          <div className="d-flex align-items-center pb-3">
            <span className="buy-it-again-title d-block pr-3">
              Buy it Again
            </span>
            <Button
              variant="outline-secondary"
              size="lg"
              className="btn-pillbox"
              href="/reorder"
            >
              View all previously purchased &raquo;
            </Button>
          </div>
          <div className="d-flex align-items-center pb-3 buy-it-again-suggestion-list">
            {orderHistory.buyItAgainSuggestions.map((suggestion, index) => {
              return (
                <Link
                  key={index}
                  to={"/" + suggestion.url}
                  className="buy-it-again-suggestion"
                >
                  <div className="bia-image">
                    <Link to={"/" + suggestion.url}>
                      <ProductImage
                        alt={suggestion.title}
                        title={suggestion.title}
                        src={suggestion.smallPhotoPath}
                      ></ProductImage>
                    </Link>
                  </div>

                  <div className="bia-purchase-date">
                    {getPurchasedDateDescription(suggestion.coTime)}
                  </div>

                  <div className="bia-title">{suggestion.title}</div>

                  <div className="bia-price text-secondary">
                    <CurrencyDisplay
                      value={
                        suggestion.bestPrice
                          ? suggestion.bestPrice.toFixed(2)
                          : new Big(0)
                      }
                    />
                  </div>

                  <div className="bia-retail-price">
                    {suggestion.retailPrice > suggestion.bestPrice && (
                      <CurrencyDisplay
                        value={
                          suggestion.retailPrice
                            ? suggestion.retailPrice.toFixed(2)
                            : 0
                        }
                      />
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </>
    </Overlay>
  );
};
export default BuyItAgainSuggestions;
